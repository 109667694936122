import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormControl, FormGroup
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {SystemService} from '../../../System/Service/system.service';

@Component({
    selector: 'app-user-group-rights',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './user.group.rights.component.html',
    styleUrls: ['../../../System/system.component.css']
})
export class UserGroupRightsComponent implements OnInit {
    @Input() model: any;
    public form: FormGroup;
    @Output() codeChange = new EventEmitter<any>();

    constructor(
        private fb: FormBuilder,
        private cd: ChangeDetectorRef,
        private systemService: SystemService,
        private controlContainer: ControlContainer,
        public translate: TranslateService
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.createForm();
    }

    createForm() {
    }

    ngOnInit() {
        this.form = <FormGroup>this.controlContainer.control;
        let i = 0;

        for (i; i <= this.model.zones.length - 1; i++) {
            this.form.addControl(
              'selectAllItems-' + this.model.id + '-' + this.model.zones[i].name, new FormControl('')
            );
            let j = 0;

            for (j; j <= this.model.zones[i].setting.length - 1; j++) {
              this.form.addControl(
                'setting-' + this.model.zones[i].setting[j].setting.name + '-active', new FormControl('')
              );
            }
        }
    }

    switchUserGroupZone(zone: any): void {
        let i = 0,
          j = 0;

        for (j; j <= this.model.zones.length -1; j++) {
            if (this.model.zones[j].id === zone.id) {
                for (i; i <= this.model.zones[j].setting.length - 1; i++) {
                    this.model.zones[j].setting[i].active = !this.model.zones[j].setting[i].active;

                    if (i === this.model.zones[j].setting.length - 1) {
                        this.cd.detectChanges();
                    }
                }
            }
        }
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }

    changeValue(value: any) {
        this.codeChange.emit(value);
    }
}
