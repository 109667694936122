<div class="description--text">
    <p>
        {{ data.message }}
    </p>
</div>

<mat-accordion *ngIf="showTrace === true">
    <mat-expansion-panel *ngFor="let trace of completeTrace">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <span class="pl-md">
                    {{trace.file}}
                </span>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="accordion--content">
            <div class="row list--row">
                <div class="col-md-6">
                    <h3 class="description--headline">{{ 'DETAILS' | translate }}</h3>
                    <p>
                        <span class="error--details-label">Class:</span>
                        <span class="error--details-value">{{trace.class}}</span>
                    </p>
                    <p>
                        <span class="error--details-label">File:</span>
                        <span class="error--details-value">{{trace.file}}</span>
                    </p>
                    <p>
                        <span class="error--details-label">Function:</span>
                        <span class="error--details-value">{{trace.function}}</span>
                    </p>
                    <p>
                        <span class="error--details-label">Line:</span>
                        <span class="error--details-value">{{trace.line}}</span>
                    </p>
                    <p>
                        <span class="error--details-label">Namespace:</span>
                        <span class="error--details-value">{{trace.namespace}}</span>
                    </p>
                </div>
                <div class="col-md-6">
                    <h3 class="description--headline">{{ 'ARGUMENTS' | translate }}</h3>
                    <pre class="error--arguments" [innerHTML]="trace.args | json"></pre>
                </div>
            </div>
        </div>

    </mat-expansion-panel>
</mat-accordion>
