import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-loader-component',
    templateUrl: './loader.dialog.html',
    styleUrls: ['../../System/system.component.css']
})
export class LoaderComponent {
    @Input() loadingText: string;
}
