<form (keydown.enter)="handleEnterKeyPress($event)" [formGroup]="newCommentForm">
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <mat-checkbox
                        formControlName="isImportant"
                        [(ngModel)]="newComment.isImportant">
                    {{ 'IMPORTANT_INFORMATION' | translate }}
                </mat-checkbox>
            </div>

            <mat-form-field *ngIf="undefined !== companies && companies.length > 0">
                <mat-select
                            [compareWith]="compareObjects"
                            placeholder="{{ 'COMPANY' | translate }}"
                            [(ngModel)]="newComment.company"
                            formControlName="company">
                    <mat-option class="default--select-option" [value]="''">{{ 'PLEASE_SELECT' | translate }}</mat-option>
                    <mat-option class="select--option" *ngFor="let selection of companies" [value]="selection">
                        <span>{{selection.name}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <h2 class="description--headline">{{ 'COMMENT' | translate }}</h2>
            <app-editor
                [mention]="true"
                [model]="newComment.message"
                [formGroup]="newCommentForm"
                [controlName]="'message'"
            ></app-editor>
        </div>

        <div class="col-md-6">
            <app-file-upload
                [config]="uploadConfig"
                (onUploadFinished)="onFinishUpload($event)"
                [fileArray]="attachments">
            </app-file-upload>
        </div>
    </div>

    <div class="button-row no--icon-margin">
        <div (click)="noClicked()" class="mat-raised-button">
            <i class="fas fa-arrow-left"></i> {{ 'BACK' | translate }}
        </div>
        <button type="submit" (click)="addClicked()" mat-raised-button color="primary">
            <i class="fas fa-save"></i> {{ 'SAVE' | translate }}
        </button>
    </div>
</form>
