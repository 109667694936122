import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class DataChangeService {
    public values: any[] = [];

    constructor() {}

    setValue(valueName: string, value: any) {
        this.values[valueName] = new BehaviorSubject(value);
        this.values[valueName].asObservable();
    }

    setSubValue(valueName: string, index: number, property: string, value: any) {
        if (
            typeof this.values[valueName] !== 'undefined' ||
            this.values[valueName] instanceof BehaviorSubject ||
            typeof this.values[valueName].getValue() !== 'undefined'
        ) {
            const model = this.values[valueName].getValue();
            model[index][property] = value;

            this.values[valueName].next(model);
        }
    }

    getValue(valueName: string, fallback: any = '') {
        if (
            typeof this.values[valueName] === 'undefined' ||
            !(this.values[valueName] instanceof BehaviorSubject) ||
            typeof this.values[valueName].getValue() === 'undefined'
        ) {
            return fallback;
        }
        return this.values[valueName].getValue();
    }

    changeValue(valueName: string, data: any, returnValue: boolean = false) {
        if (typeof this.values[valueName] === 'undefined') {
            this.values[valueName] = new BehaviorSubject(data);
            this.values[valueName].asObservable();
            if (returnValue === true) {
                return this.getValue(valueName, '');
            }
        } else {
            if (typeof this.values[valueName].next === 'function') {
                this.values[valueName].next(data);
                if (returnValue === true) {
                    return this.getValue(valueName, '');
                }
            }
        }
    }

    changeAll(data: any) {
        const self = this;
        Object.keys(data).forEach(function (key, index, arr) {
            self.changeValue(key, data[key]);
            if (!arr[index + 1]) {
                return true;
            }
        });
    }

    changeValueWithPromise(valueName: string, data: any): Promise<boolean> {
        return Promise.resolve((() => {
            this.changeValue(valueName, data);
            return true;
        })());
    }

    removeValue(valueName: string) {
        if (typeof this.values[valueName] !== 'undefined') {
            this.values[valueName].next(null);
            delete this.values[valueName];
        }
    }

    removeAll() {
        const self = this;
        Object.keys(this.values).forEach(function (key, index) {
            self.removeValue(key);
        });
    }
}
