<ng-container *ngIf="loggedIn === true">
    <div class="menu--toggle-button color--white justify-content-start">
        <button *ngIf="undefined !== currentUser && null !== currentUser" mat-icon-button (click)="onSidenavClose()">
            <mat-icon *ngIf="sidenavOpened === false">menu</mat-icon>
            <mat-icon *ngIf="sidenavOpened === true">menu_open</mat-icon>
        </button>
    </div>
    <mat-sidenav-container>
        <mat-sidenav class="main-category nav sidebar" #sidenav mode="side" [(opened)]="sidenavOpened" role="navigation"
                     *ngIf="undefined !== currentUser && null !== currentUser">
            <div class="sidebar-content">
                <div class="sidebar-profile mt-l">
                    <div class="user-pic">
                        <a routerLink="/dashboard">
                            <img class="img-responsive img-rounded" src="{{currentUser.logo}}" alt="User picture">
                        </a>
                    </div>
                    <div class="user-info">
                        <a routerLink="/account">
                            <span class="user-name">{{currentUser.firstName}}
                                <strong>{{currentUser.lastName}}</strong>
                            </span>
                            <br>
                            <span class="user-role">{{currentUser.userGroup.name}}</span>
                        </a>
                    </div>
                </div>
                <div class="sidebar-menu">
                    <app-navigation [items]="currentUser.navigation"></app-navigation>
                </div>
            </div>
            <div class="sidebar-footer">
                <div>
                    <a routerLink="/base_settings">
                        <i class="fas fa-cogs">
                        </i>
                    </a>
                </div>
                <div>
                    <a routerLink="/account">
                        <i class="fas fa-user">
                        </i>
                    </a>
                </div>
                <div>
                    <a routerLink="/help">
                        <i class="fas fa-question-circle">
                        </i>
                    </a>
                </div>
                <div>
                    <a (click)="logout()">
                        <i class="fas fa-sign-out-alt">
                        </i>
                    </a>
                </div>
            </div>
        </mat-sidenav>

        <mat-sidenav-content>
            <main>
                <router-outlet>
                </router-outlet>
            </main>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-container>

<ng-container *ngIf="loggedIn === false">
    <mat-sidenav-container [class.is--logged-out]="true">
        <mat-sidenav class="main-category nav sidebar" #sidenav mode="side" [(opened)]="sidenavOpened" role="navigation">
        </mat-sidenav>

        <mat-sidenav-content>
            <main>
                <router-outlet>
                </router-outlet>
            </main>
        </mat-sidenav-content>
    </mat-sidenav-container>
</ng-container>
