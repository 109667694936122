import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SystemService} from '../../../System/Service/system.service';

@Component({
    selector: 'app-description-headline',
    templateUrl: './description.headline.component.html',
    styleUrls: ['../../../System/system.component.css']
})
export class DescriptionHeadlineComponent implements OnInit {
    @Input() headline: string;
    @Input() config: any;
    @Input() data: any;

    showAddition: boolean;
    additional: string;
    extraHeadlineClass: string;

    constructor(
        public translate: TranslateService,
        public systemService: SystemService
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.createForm();
    }


    ngOnInit() {
        this.showAddition = false;
        this.extraHeadlineClass = '';

        if (typeof this.config !== 'undefined') {
            this.additional = this.config.additional;
            this.extraHeadlineClass = this.config.extraHeadlineClass;

            if (typeof this.data === 'undefined' && typeof this.config.getUrl !== 'undefined') {
                this.systemService.getData(this.config.getUrl).subscribe(getResult => {
                    if (typeof this.config.modelName !== 'undefined') {
                        this.data = getResult[this.config.modelName];
                    } else {
                        this.data = getResult;
                    }
                    this.showAddition = true;
                });
            } else {
                this.showAddition = true;
            }
        }
    }

    createForm() {
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }
}
