import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
    ControlContainer,
    FormControl, FormGroup,
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {DataChangeService} from '../../../System/Service/data.change.service';

@Component({
    selector: 'app-loop',
    templateUrl: './loop.component.html',
    styleUrls: ['../../../System/system.component.css']
})
export class LoopComponent implements OnInit {
    @Input() controlName: any;
    @Input() placeholder: any;
    @Input() config: any;
    @Input() dataChangeService: DataChangeService;
    @Input() changing: Subject<any>;
    @Input() selections: any;
    @Output() afterSelect = new EventEmitter<number>();
    public form: FormGroup;
    public control: FormControl;

    constructor(
        private controlContainer: ControlContainer,
        public translate: TranslateService
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.createForm();
    }

    createForm() {
    }

    ngOnInit() {
        this.form = <FormGroup>this.controlContainer.control;
    }

    getData() {
        return this.dataChangeService.getValue(this.config.loopModel, []);
    }

    onFieldValueChange(event, field: any, index: number) {
        this.dataChangeService.setSubValue(this.config.loopModel, index, field.identifier, event);
    }

    trackByFunction(index, item) {
        return index.id;
    }

    fieldValue(field: any, index: number): any {
        const model = this.dataChangeService.getValue(this.config.loopModel, []);

        if (typeof field.subIdentifier === 'undefined') {
            return model[index][field.identifier];
        } else {
            if (typeof field.subSubIdentifier === 'undefined') {
                const value = model[index][field.identifier];
                return value[field.subIdentifier];
            } else {
                const value = model[index][field.identifier];
                const subFieldValue = value.getValue();
                return subFieldValue[field.subSubIdentifier];
            }
        }
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }
}
