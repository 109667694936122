<ul>
    <li *ngFor="let menu of items" [ngClass]="{'active': menu.active}" class="sidebar-dropdown">
        <a *ngIf="menu.type === 'header'" (click)='toggle(menu)'>
            <i class="fas {{menu.info.iconName}}">
            </i>
            <span>
                {{menu.info.name | translate}}
            </span>
        </a>
        <div class="sidebar-submenu" [@slide]="getState(menu)" *ngIf="menu.children.length > 0">
            <ul>
                <li *ngFor="let submenu of menu.children" (click)="onItemSelected(submenu)">
                    <i class="fas {{submenu.iconName}}">
                    </i>
                    <a>
                        {{ getLabel(submenu) | translate}}
                    </a>
                </li>
            </ul>
        </div>
    </li>
</ul>
