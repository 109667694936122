import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';

import {ApiService} from '../../../config/api.service';
import {User} from '../Model/user';

@Injectable({providedIn: 'root'})
export class SystemService {

    constructor(private apiService: ApiService) {
    }

    getTemplate(template: string): Observable<any> {
        return this.apiService.restCall('/be/api/backend/template/get/' + template);
    }

    getSearchResults(url: string, queryString: string) {
        if (queryString.length < 3) {
          return [];
        }

        return this.apiService.restCall(url + queryString);
    }

    getSearchResultsWithParameter(url: string, queryString: string, parameters: any) {
        if (queryString.length < 3) {
          return [];
        }

        return this.apiService.getWithParameters(url + queryString, parameters);
    }

    getGlobalSearchResults(queryString: string) {
        if (queryString === '') {
          return [];
        }

        return this.apiService.restCall('/be/api/search/results/' + queryString);
    }

    getData(url: string): Observable<any> {
        return this.apiService.restCall(url);
    }

    getDataWithId(url: string, id: number): Observable<any> {
        return this.apiService.restCall(url + id);
    }

    getWithUrl(url: string): Observable<any[]> {
        return this.apiService.restCall(url);
    }

    getWithUrlAndParameters(url: string, params: any): Observable<any[]> {
        return this.apiService.getWithParameters(url, params);
    }

    getDetailUrl(url: string, data: any): Observable<any> {
        return this.apiService.getWithParameters(url, data);
    }

    putCall(data: any, url: string): Observable<any> {
        return this.apiService.put(url, data);
    }

    putWithoutData(url: string): Observable<any> {
        return this.apiService.restCall(url);
    }

    getQuickEditData(mode: string, id: string): Observable<any> {
        return this.apiService.restCall('/be/api/' + mode + '/quick/edit/get/' + id);
    }

    getFeedUsers(): Observable<User[]> {
        return this.apiService.restCall('/be/api/user/feeds/get');
    }

    exportData(data: any): Observable<any> {
        return this.apiService.download('/be/api/data/export', data);
    }

    importData(data: any): Observable<any> {
        return this.apiService.put('/be/api/data/import', data);
    }

    public getTimes() {
        let i = 0,
            j = 0,
            timeString;
        const times = [];

        for (i; i <= 46; i = i + 2) {
            timeString = j.toString();

            if (j < 10) {
                timeString = '0' + timeString;
            }
            times.push({
                id: i,
                name: timeString + ':00'
            });
            times.push({
                id: i + 1,
                name: timeString + ':30'
            });
            j = j + 1;
        }

        return times;
    }
}
