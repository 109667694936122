import {SafePipe} from './BaseComponents/Pipes/safe.pipe';
import {BatchDeleteComponent} from './BaseComponents/Table/_dialogs/batch.delete.component';
import {LOCALE_ID, ModuleWithProviders, NgModule} from '@angular/core';
import {FileUploadComponent} from './BaseComponents/FileUpload/file.upload.component';
import {MaterialModule} from './material.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {RouterModule} from '@angular/router';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {ColorPickerModule} from 'ngx-color-picker';
import {DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule} from '@angular/material/core';
import {ContentComponent} from './Content/content.component';
import {ApiService} from '../config/api.service';
import {MediaService} from '../config/media.service';
import {APP_DATE_FORMATS, AppDateAdapter} from './System/Adapter/date.adapter';
import {PaginatedTableComponent} from './BaseComponents/Table/paginated.table.component';
import {SimpleTableComponent} from './BaseComponents/Table/simple.table.component';
import {CommonModule} from '@angular/common';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {TranslateModule} from '@ngx-translate/core';
import {CommentComponent} from './BaseComponents/Comments/comment.component';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import {NgxTextDiffModule} from 'ngx-text-diff';
import {RowContentPreviewComponent} from './BaseComponents/Fields/RowContentEditor/preview/row.content.preview.component';
import {CurrencyFormat} from './BaseComponents/Pipes/comma.replacement.pipe';
import {NavigationComponent} from './BaseComponents/Navigation/navigation.component';
import {OutputComponent} from './BaseComponents/Output/output.component';
import {FileModalDialogComponent} from './BaseComponents/FileModal/file.modal';
import {ModalDeleteComponent} from './BaseComponents/Modal/modal.delete.component';
import {CommentAddComponent} from './BaseComponents/Comments/add/comment.add.component';
import {ExporterComponent} from './BaseComponents/Exporter/exporter.component';
import {ImporterComponent} from './BaseComponents/Importer/importer.component';
import {ImporterMappingComponent} from './BaseComponents/Importer/mapping/importer.mapping.component';
import {LoaderComponent} from './BaseComponents/Loader/loader.component';
import {DashboardTableComponent} from './BaseComponents/Table/dashboard.table.component';
import {CustomFieldsComponent} from './BaseComponents/CustomFields/custom.fields.component';
import {SearchSelectComponent} from './BaseComponents/Fields/SearchSelect/search.select.component';
import {EditorComponent} from './BaseComponents/Fields/Editor/editor.component';
import {DiffComponent} from './BaseComponents/Fields/Diff/diff.component';
import {ClickOutsideDirective} from './BaseComponents/Directives/click.outside.directive';
import {GlobalSearchComponent} from './BaseComponents/Fields/GlobalSearch/global.search.component';
import {QuickEditComponent} from './BaseComponents/QuickEdit/quick.edit.component';
import {TreeComponent} from './BaseComponents/Tree/tree.component';
import {SeoComponent} from './BaseComponents/Seo/seo.component';
import {TreeModule} from 'angular-tree-component';
import {MultiTabComponent} from './BaseComponents/Tab/multi.tab.component';
import {FieldComponent} from './BaseComponents/Fields/Field/field.component';
import {PublicationListComponent} from './BaseComponents/Fields/PublicationList/publication.list.component';
import {RowContentEditorComponent, FrontendEditRowContentComponent, FrontendAddRowContentComponent, FrontendEditRowComponent, FrontendAddRowColumnComponent} from './BaseComponents/Fields/RowContentEditor/row.content.editor.component';
import {ChartComponent} from './BaseComponents/Chart/chart.component';
import {DetailComponent} from './System/_components/Detail/detail.component';
import {AddComponent} from './System/_components/Add/add.component';
import {DataChangeService} from './System/Service/data.change.service';
import {ActionTableComponent} from './BaseComponents/Table/action.table.component';
import {ModalComponent} from './System/_components/Dialog/modal.component';
import {DistributorCompareComponent} from './BaseComponents/Fields/DistributorCompare/distributor.compare.component';
import {SelectListComponent} from './BaseComponents/Fields/SelectList/select.list.component';
import {AccordionComponent} from './BaseComponents/Fields/Accordion/accordion.component';
import {CooperationListComponent} from './BaseComponents/Fields/CooperationList/cooperation.list.component';
import {LoopComponent} from './BaseComponents/Fields/Loop/loop.component';
import {DescriptionTextComponent} from './BaseComponents/Fields/DescriptionText/description.text.component';
import {DescriptionHeadlineComponent} from './BaseComponents/Fields/DescriptionHeadline/description.headline.component';
import {StepperComponent} from './BaseComponents/Stepper/stepper.component';
import {EditComponent} from './System/_components/Edit/edit.component';
import {CheckedListComponent} from './BaseComponents/Fields/CheckedList/checked.list.component';
import {DocumentDetailComponent} from './BaseComponents/Fields/DocumentDetail/document.detail.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {PdfPreviewComponent} from './BaseComponents/Fields/PdfPreview/pdf.preview.component';
import {ChangePasswordComponent} from './BaseComponents/Fields/ChangePassword/change.password.component';
import {UserGroupRightsComponent} from './BaseComponents/Fields/UserGroupRights/user.group.rights.component';
import {DashboardAddWidgetComponent, DashboardComponent} from './BaseComponents/Dashboard/dashboard.component';
import {DashboardEditWidgetComponent, WidgetComponent} from './BaseComponents/Dashboard/Widget/widget.component';
import {FileComponent} from './BaseComponents/Files/file.component';
import {SubTabComponent} from './BaseComponents/Tab/sub.tab.component';
import {AttachmentListComponent} from './BaseComponents/Fields/AttachmentList/attachment.list.component';
import {GalleryModule} from '@ngx-gallery/core';
import {GridsterModule} from 'angular-gridster2';
import {LightboxModule} from '@ngx-gallery/lightbox';
import {GallerizeModule} from '@ngx-gallery/gallerize';
import {CommentEditComponent} from './BaseComponents/Comments/edit/comment.edit.component';
import {SnackbarComponent} from './BaseComponents/Snackbar/snackbar.service';
import {OnDefinedModelDirective} from './BaseComponents/Directives/on.defined.model.directive';
import {ToasterComponent} from './System/Notification/app.toaster.component';
import {ModalUpdateCompareComponent} from './BaseComponents/Modal/modal.update.compare.component';
import {MultiTreeComponent} from './BaseComponents/MultiTree/multi.tree.component';
import {TableOutputPipe} from './BaseComponents/Pipes/table.output.pipe';
import {InViewportModule} from 'ng-in-viewport';
import {ReferenceComponent} from './BaseComponents/Fields/Reference/reference.component';
import {ErrorDetailsComponent} from './BaseComponents/Snackbar/detail/error.details.component';
import {NgxGraphModule} from '@swimlane/ngx-graph';
import {ProcessComponent} from './BaseComponents/Fields/Process/process.component';
import {ProcessAddEntryComponent} from './BaseComponents/Fields/Process/add/process.add.entry.component';
import {ProcessEditEntryComponent} from './BaseComponents/Fields/Process/edit/process.edit.entry.component';
import {ProcessActionComponent} from './BaseComponents/Fields/ProcessAction/process.action.component';
import {SingleTabComponent} from './BaseComponents/Tab/Single/single.tab.component';
import {DragAndDropListComponent} from './BaseComponents/Fields/DragAndDropList/drag.and.drop.list.component';
import {InterviewComponent} from './BaseComponents/Fields/Interview/interview.component';
import {ItemListComponent} from './BaseComponents/Fields/ItemList/item.list.component';
import {MessagesComponent} from './BaseComponents/Fields/Messages/messages.component';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        MaterialModule,
        CommonModule,
        RouterModule,
        NgxMatSelectSearchModule,
        NgxChartsModule,
        NgxGraphModule,
        ColorPickerModule,
        InViewportModule,
        PdfViewerModule,
        GridsterModule,
        TreeModule.forRoot(),
        NgxPageScrollModule,
        GalleryModule.withConfig({
          loadingMode: 'indeterminate'
        }),
        LightboxModule,
        GallerizeModule,
        NgxPageScrollCoreModule.forRoot(
            {
                duration: 2500
            }
        ),
        NgxTextDiffModule,
        TranslateModule,
        CKEditorModule,
        MatNativeDateModule
    ],
    entryComponents: [
        BatchDeleteComponent,
        ModalDeleteComponent,
        ExporterComponent,
        ImporterComponent,
        ImporterMappingComponent,
        FileModalDialogComponent,
        GlobalSearchComponent,
        QuickEditComponent,
        CommentAddComponent,
        ProcessActionComponent,
        ProcessAddEntryComponent,
        ProcessEditEntryComponent,
        ErrorDetailsComponent,
        CommentEditComponent,
        DashboardAddWidgetComponent,
        DashboardEditWidgetComponent,
        PublicationListComponent,
        RowContentEditorComponent,
        RowContentPreviewComponent,
        ModalComponent,
        ModalUpdateCompareComponent,
        DetailComponent,
        SnackbarComponent,
        AddComponent,
        EditComponent,
        LoaderComponent
    ],
    declarations: [
        FileUploadComponent,
        OutputComponent,
        ReferenceComponent,
        SafePipe,
        TableOutputPipe,
        ClickOutsideDirective,
        OnDefinedModelDirective,
        CurrencyFormat,
        CommentComponent,
        CommentAddComponent,
        ProcessAddEntryComponent,
        ProcessActionComponent,
        ProcessEditEntryComponent,
        ErrorDetailsComponent,
        CommentEditComponent,
        ToasterComponent,
        DashboardAddWidgetComponent,
        DashboardEditWidgetComponent,
        ContentComponent,
        NavigationComponent,
        PaginatedTableComponent,
        ActionTableComponent,
        TreeComponent,
        SeoComponent,
        MultiTreeComponent,
        MultiTabComponent,
        SingleTabComponent,
        ChartComponent,
        SimpleTableComponent,
        CustomFieldsComponent,
        SearchSelectComponent,
        SelectListComponent,
        DragAndDropListComponent,
        InterviewComponent,
        ItemListComponent,
        MessagesComponent,
        AccordionComponent,
        CooperationListComponent,
        LoopComponent,
        AttachmentListComponent,
        SnackbarComponent,
        SubTabComponent,
        WidgetComponent,
        FileComponent,
        ChangePasswordComponent,
        UserGroupRightsComponent,
        DashboardComponent,
        PdfPreviewComponent,
        DocumentDetailComponent,
        CheckedListComponent,
        StepperComponent,
        DescriptionTextComponent,
        DescriptionHeadlineComponent,
        DistributorCompareComponent,
        ProcessComponent,
        GlobalSearchComponent,
        QuickEditComponent,
        EditorComponent,
        DiffComponent,
        FieldComponent,
        FrontendEditRowContentComponent,
        FrontendAddRowContentComponent,
        FrontendEditRowComponent,
        FrontendAddRowColumnComponent,
        RowContentEditorComponent,
        PublicationListComponent,
        RowContentPreviewComponent,
        DashboardTableComponent,
        FileModalDialogComponent,
        BatchDeleteComponent,
        ExporterComponent,
        ImporterComponent,
        ImporterMappingComponent,
        ModalDeleteComponent,
        ModalComponent,
        ModalUpdateCompareComponent,
        DetailComponent,
        AddComponent,
        EditComponent,
        LoaderComponent
    ],
    exports: [
        TranslateModule,
        CommonModule,
        MatNativeDateModule,
        RouterModule,
        HttpClientModule,
        MaterialModule,
        GridsterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMatSelectSearchModule,
        OutputComponent,
        ReferenceComponent,
        NavigationComponent,
        PaginatedTableComponent,
        ActionTableComponent,
        TreeComponent,
        SeoComponent,
        MultiTreeComponent,
        MultiTabComponent,
        SingleTabComponent,
        ChartComponent,
        SimpleTableComponent,
        CustomFieldsComponent,
        SearchSelectComponent,
        SelectListComponent,
        DragAndDropListComponent,
        InterviewComponent,
        ItemListComponent,
        SnackbarComponent,
        AccordionComponent,
        CooperationListComponent,
        LoopComponent,
        AttachmentListComponent,
        SubTabComponent,
        WidgetComponent,
        FileComponent,
        ToasterComponent,
        ChangePasswordComponent,
        UserGroupRightsComponent,
        DashboardComponent,
        PdfPreviewComponent,
        DocumentDetailComponent,
        CheckedListComponent,
        StepperComponent,
        DescriptionTextComponent,
        DescriptionHeadlineComponent,
        DistributorCompareComponent,
        ProcessComponent,
        GlobalSearchComponent,
        EditorComponent,
        DiffComponent,
        FieldComponent,
        FrontendEditRowContentComponent,
        FrontendAddRowContentComponent,
        FrontendEditRowComponent,
        FrontendAddRowColumnComponent,
        RowContentEditorComponent,
        PublicationListComponent,
        RowContentPreviewComponent,
        QuickEditComponent,
        DashboardTableComponent,
        SafePipe,
        TableOutputPipe,
        ClickOutsideDirective,
        OnDefinedModelDirective,
        CurrencyFormat,
        CommentComponent,
        CommentAddComponent,
        ProcessAddEntryComponent,
        ProcessActionComponent,
        ProcessEditEntryComponent,
        ErrorDetailsComponent,
        CommentEditComponent,
        DashboardAddWidgetComponent,
        DashboardEditWidgetComponent,
        BatchDeleteComponent,
        ExporterComponent,
        ImporterComponent,
        ImporterMappingComponent,
        FileModalDialogComponent,
        FileUploadComponent,
        ColorPickerModule,
        InViewportModule,
        PdfViewerModule,
        TreeModule,
        NgxPageScrollModule,
        NgxPageScrollCoreModule,
        NgxTextDiffModule,
        NgxChartsModule,
        NgxGraphModule,
        GalleryModule,
        CKEditorModule,
        ColorPickerModule,
        LoaderComponent
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                ApiService,
                DataChangeService,
                MediaService,
                {provide: LOCALE_ID, useValue: 'de'},
                {provide: DateAdapter, useClass: AppDateAdapter},
                {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
            ]
        };
    }
}
