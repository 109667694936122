import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface FileModalDialogData {
    file: string;
    extension: string;
}

@Component({
    selector: 'app-file-modal-dialog-component',
    templateUrl: '_dialogs/file.modal.dialog.html',
})
export class FileModalDialogComponent implements OnInit {
    @Input() file: string;
    mode: string;
    extension: string;
    private textExtensions: string[] = ['pdf', 'txt', 'doc', 'docx'];
    private videoExtensions: string[] = ['mpeg', 'mpg', 'mp4', 'avi', 'mov', 'swf'];

    constructor(
        public dialogRef: MatDialogRef<FileModalDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FileModalDialogData) {
    }

    ngOnInit() {
        this.file = this.data.file;

        if (typeof this.data.extension === 'undefined') {
            this.mode = 'image';
        } else {
            this.extension = this.data.extension;

            if (this.textExtensions.includes(this.data.extension)) {
                this.mode = 'text';
            } else if (this.videoExtensions.includes(this.data.extension)) {
                this.mode = 'video';
            } else {
                this.mode = 'image';
            }
        }
    }

    onNoClick(): void {
        this.closeFileModalDialog();
    }

    closeFileModalDialog(): void {
        this.dialogRef.close();
    }
}
