import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpEventType} from '@angular/common/http';
import {ApiService} from '../../../config/api.service';
import {TranslateService} from '@ngx-translate/core';
import {SystemService} from '../../System/Service/system.service';
import {Observable, Subject} from 'rxjs';
import {FileModalDialogComponent} from '../FileModal/file.modal';
import {MatDialog} from '@angular/material/dialog';
import {v4 as uuid} from 'uuid';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file.upload.component.html',
    styleUrls: ['../../System/system.component.css']
})
export class FileUploadComponent implements OnInit {
    @Input() files: any;
    @Input() currentId: any;
    @Input() changing: Subject<any>;
    @Input() fileArray: any;
    @Input() config: {
        uploadCall: string,
        getCall: string,
        moveFile: boolean,
        assignFile: boolean,
        showUpload: boolean,
        returnAll: boolean,
        multiple: boolean,
        showSwitch: boolean,
        isModal: boolean,
        showImages: boolean,
        moveCall: string,
        saveCall: string,
        assignCall: string,
        associationCall: string,
        assignAlbum: string,
        dropText: string,
        formControlName: string,
        allowedExtensions: any,
        baseModel: any
    };
    @Output() onUploadFinished = new EventEmitter<any>();
    uploadPercentages: any[];
    images: any[];
    existingImages: any[];
    selectedImageIds: number[];
    uploadSuccess: any;
    selectExistingImage: boolean;
    showAssignExistingImages: boolean;
    showSaveImages: boolean;
    showImageGallery: boolean;
    disableSwitch: boolean;
    id: string;

    constructor(
        public translate: TranslateService,
        private cd: ChangeDetectorRef,
        public dialog: MatDialog,
        private apiService: ApiService,
        private systemService: SystemService
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
    }

    switchImage(image: any): void {
        if (this.in_array(image.id)) {
            this.removeImage(image);
        } else {
            this.addImage(image);
        }
    }

    addImage(image: any): void {
        if (this.config.multiple === false) {
            if (typeof this.fileArray === 'undefined' || this.fileArray === null) {
                this.fileArray = [];
            } else {
                if (typeof this.fileArray === 'string') {
                    this.fileArray = [];
                } else {
                    this.fileArray.splice(0, 1);
                }
            }

            this.selectedImageIds = [];
            this.selectedImageIds.push(image.id);
            this.fileArray.push(image);
            this.onUploadFinished.emit(this.fileArray);

            if (this.fileArray.length > 0) {
                this.showAssignExistingImages = true;
            }
        } else {
            this.selectedImageIds.push(image.id);
            this.fileArray.push(image);
            this.onUploadFinished.emit(this.fileArray);
            if (this.fileArray.length > 0) {
                this.showAssignExistingImages = true;
            }
        }
        this.cd.detectChanges();
    }

    removeImage(image: any): void {
        let i = 0;
        for (i; i <= this.fileArray.length - 1; i++) {
            if (this.fileArray[i].id === image.id) {
                this.selectedImageIds.splice(i, 1);
                this.fileArray.splice(i, 1);

                if (this.fileArray.length === 0) {
                  this.showAssignExistingImages = false;
                }
            }
        }
    }

    in_array(id): boolean {
        let i = 0;

        for (i; i <= this.selectedImageIds.length - 1; i++) {
            if (this.selectedImageIds[i] === id) {
                return true;
            }
        }

        return false;
    }

    onUpload(event: any): void {
        this.files = event.target.files;

        let i = 0;

        for (i; i < this.files.length; i++) {
            this.uploadPercentages.push({
                percentage: 0
            });
        }
    }

    removeUploadSelection(): void {
        this.files = [];
        this.uploadPercentages = [];
        this.uploadSuccess = {};
    }

    async doUpload() {
        let i = 0;

        for (i; i < this.files.length; i++) {
            if (typeof this.config.allowedExtensions !== 'undefined') {
                if (this.config.allowedExtensions.indexOf(this.files[i]['extension']) > -1) {
                    this.uploadSuccess = {
                        success: false,
                        message: 'EXTENSION NOT ALLOWED'
                    };

                    continue;
                }
            }

            const result = await this.performUpload(this.files[i], i);
        }
    }

    performUpload(file, i): void {
        this.apiService.uploadFile(file, this.config.uploadCall).subscribe(
            result => {
                if (result.type === HttpEventType.UploadProgress) {
                    this.uploadPercentages[i].percentage = Math.round(100 * result.loaded / result.total);
                    this.cd.detectChanges();
                } else if (result.type === HttpEventType.Response) {
                    if (result.body.success === false) {
                        this.uploadSuccess = {
                            success: false,
                            message: result.body.message
                        };
                    } else {
                        this.uploadSuccess = {
                            success: true
                        };
                        if (this.config.moveFile === true) {
                            this.apiService.put(
                                this.config.moveCall,
                                {
                                    file: result.body.data.src,
                                    model: this.config.baseModel
                                }).subscribe(
                                res => {
                                    this.fileArray.push(result.body.data);
                                    this.cd.detectChanges();
                                    this.createAssociation(result.body.data);
                                    if (typeof this.onUploadFinished !== 'undefined') {
                                        this.onUploadFinished.emit(this.fileArray);
                                    }
                                }
                            );
                        } else if (this.config.assignFile === true) {
                            this.apiService.assignMediaFileToAlbum({
                                file: (typeof result.body.data === 'undefined') ? result.body : result.body.data,
                                area: this.config.assignAlbum
                            }).subscribe(
                                res => {
                                    if (typeof this.fileArray === 'string') {
                                        this.fileArray = [];
                                        this.fileArray.push((typeof result.body.data === 'undefined') ? result.body : result.body.data);
                                    } else {
                                        this.fileArray.push((typeof result.body.data === 'undefined') ? result.body : result.body.data);
                                    }
                                    this.cd.detectChanges();
                                    this.createAssociation((typeof result.body.data === 'undefined') ? result.body : result.body.data);
                                    if (typeof this.onUploadFinished !== 'undefined') {
                                        this.onUploadFinished.emit(this.fileArray);
                                    }
                                }
                            );
                        } else {
                            if (typeof result.body.data === 'undefined') {
                                this.fileArray.push(result.body);
                            } else {
                                this.fileArray.push(result.body.data);
                            }

                            this.cd.detectChanges();
                            this.createAssociation(result.body.data);
                            if (typeof this.onUploadFinished !== 'undefined') {
                                this.onUploadFinished.emit(this.fileArray);
                            }
                        }
                    }
                }
            }
        );
    }

    createAssociation(image): void {
      if (typeof this.config.associationCall !== 'undefined') {
        this.systemService.putCall({
          'model': this.currentId,
          'images': image
        }, this.config.associationCall).subscribe(result => {
            this.images.push(result.data);
            this.cd.detectChanges();
        });
      }
    }

    switchSelectionMode(): void {
        this.disableSwitch = true;
        this.selectExistingImage = !this.selectExistingImage;

        if (this.selectExistingImage === true) {
            if (this.existingImages.length === 0) {
                this.systemService.getData('/be/api/media/get').subscribe(result => {
                    this.existingImages = result;
                    this.cd.detectChanges();
                    this.disableSwitch = false;
                });
            } else {
                this.fileArray = [];
                this.disableSwitch = false;
            }
        } else {
            this.disableSwitch = false;
        }
    }

    deleteImage(image: any): void {
      this.removeExistingImage(image).subscribe(result => {
          this.showSaveImages = true;
      });
    }

    approveImage(image: any): void {
      this.approveExistingImage(image).subscribe(result => {
          this.showSaveImages = true;
      });
    }

    setMainImage(image: any): void {
      this.deselectMainImage(image).subscribe(result => {
          this.showSaveImages = true;
      });
    }

    deselectMainImage(image: any): Observable<boolean> {
      return new Observable(observer => {
        let imageCount = 0;

        for (imageCount; imageCount <= this.images.length - 1; imageCount++) {
          if (this.images[imageCount].id !== image.id) {
            this.images[imageCount].main = false;
          }

          if (imageCount === this.images.length - 1) {
            observer.next(true);
          }
        }
      });
    }

    saveImages(): void {
        this.systemService.putCall(this.images, this.config.saveCall).subscribe(res => {
            this.systemService.getData(this.config.getCall + this.currentId).subscribe(
            result => {
                  this.images = result;

                  this.showImageGallery = true;
                  this.cd.detectChanges();
              }
            );
        });
    }

    assignExistingImages(): void {
        if (typeof this.config.assignCall !== 'undefined') {
            this.systemService.putCall({
                'model': this.currentId,
                'images': this.fileArray
            }, this.config.assignCall).subscribe(result => {
                this.systemService.getData(this.config.getCall + this.currentId).subscribe(
                    res => {
                        this.images = res;
                        this.fileArray = [];
                        this.selectedImageIds = [];

                        this.showImageGallery = true;
                        this.cd.detectChanges();
                    }
                );
            });
        }
    }

    openFileModal(src: string): void {
        const dialogRef = this.dialog.open(FileModalDialogComponent, {
            minWidth: '10rem',
            data: {
              file: src
            }
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    removeExistingImage(image: any): Observable<boolean> {
      return new Observable(observer => {
        let imageCount = 0;

        for (imageCount; imageCount <= this.images.length - 1; imageCount++) {
          if (this.images[imageCount].id === image.id) {
            if (typeof this.images[imageCount].delete === 'undefined') {
              this.images[imageCount].delete = true;
            } else {
              this.images[imageCount].delete = !this.images[imageCount].delete;
            }
          }

          if (imageCount === this.images.length - 1) {
            observer.next(true);
          }
        }
      });
    }

    approveExistingImage(image: any): Observable<boolean> {
      return new Observable(observer => {
        let imageCount = 0;

        for (imageCount; imageCount <= this.images.length - 1; imageCount++) {
          if (this.images[imageCount].id === image.id) {
            if (typeof this.images[imageCount].approved === 'undefined') {
              this.images[imageCount].approved = true;
            } else {
              this.images[imageCount].approved = !this.images[imageCount].approved;
            }
              this.images[imageCount].isChanged = true;
          }

          if (imageCount === this.images.length - 1) {
            observer.next(true);
          }
        }
      });
    }

    ngOnInit() {
        this.files = [];
        if (typeof this.fileArray === 'undefined') {
            this.fileArray = [];
        }
        this.images = [];
        this.uploadPercentages = [];
        this.existingImages = [];
        this.selectedImageIds = [];
        this.uploadSuccess = {};
        this.selectExistingImage = false;
        this.showImageGallery = false;
        this.showAssignExistingImages = false;
        this.disableSwitch = false;
        this.showSaveImages = false;
        this.id = 'file-' + uuid();;

        if (this.config.showImages === true && typeof this.config.getCall !== 'undefined') {
            this.systemService.getData(this.config.getCall + this.currentId).subscribe(
                result => {
                    this.images = result;

                    this.showImageGallery = true;
                    this.cd.detectChanges();
                }
            );
        }

        if (typeof this.changing !== 'undefined') {
            this.changing.subscribe(value => {
                if (typeof this.config.getCall === 'undefined') {
                    this.images = value;
                    this.cd.detectChanges();
                }
            });
        }
    }
}
