import {Injectable, NgZone} from '@angular/core';
import {Observable} from 'rxjs';
import {EventSourcePolyfill} from '../../../../node_modules/event-source-polyfill/src/eventsource.min.js';

@Injectable({
    providedIn: 'root'
})
export class CheckForUpdateService {
    constructor(private zone: NgZone) {}

    getServerSentEvent(url: string): Observable<any> {
        return new Observable(observer => {
            const es = new EventSourcePolyfill(url);

            es.onmessage = event => {
                this.zone.run(() => {
                    observer.next(event);
                })
            };
            es.onerror = error => {
                if (typeof error.error !== 'undefined') {
                    this.zone.run(() => {
                        observer.error(error);
                    })
                }
            };
        });
    }
}
