import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {ControlContainer, FormControlDirective, FormGroupDirective, FormGroupName} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../System/Model/user';
import {SystemService} from '../../System/Service/system.service';
import {ActivatedRoute} from '@angular/router';
import {Subject} from 'rxjs';
import {DataChangeService} from '../../System/Service/data.change.service';
import {ManipulationService} from '../../System/Service/manipulation.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-simple-table',
    templateUrl: './simple.table.component.html',
    styleUrls: ['../../System/system.component.css'],
    providers: [
        FormControlDirective,
        FormGroupDirective
    ],
    viewProviders: [
        {
            provide: ControlContainer,
            useExisting: FormGroupName
        }
    ]
})
export class SimpleTableComponent implements OnInit {
    @Input() dataChangeService: DataChangeService;
    @Input() attributes: any[];
    @Input() inputFields: any[];
    @Input() changing: Subject<any>;
    @Input() headers: any[];
    @Input() activeCompare: any[];
    @Input() isModal: Boolean = false;
    @Input() config: {
        addLink: string,
        getUrl: string,
        getUrlParameters: any,
        editLink: string,
        model: string,
        showLogo: boolean,
        showComment: boolean,
        showAdd: boolean,
        emitToParent: boolean,
        commentLink: string,
        modelName: string,
        subModel: string,
        zone: string,
        showDelete: boolean,
        showEdit: boolean,
        routeToEdit: boolean,
        routeAfterEdit: boolean,
        routeToAdd: boolean,
        routeToDelete: boolean,
        translateName: boolean,
        activeCompare: any,
        deleteLink: string
    };
    @Output() addClick = new EventEmitter<boolean>();
    @Output() editClick = new EventEmitter<any>();
    @Output() editRouteClick = new EventEmitter<any>();
    @Output() deleteClick = new EventEmitter<any>();
    @Output() blurEvent = new EventEmitter<any>();
    @Output() afterLoadEvent = new EventEmitter<any>();
    currentUser: User;
    showEdit: boolean;
    public data: any;

    constructor(
        public translate: TranslateService,
        private cd: ChangeDetectorRef,
        private manipulationService: ManipulationService,
        private route: ActivatedRoute,
        public systemService: SystemService
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
    }

    ngOnInit() {
        this.currentUser = JSON.parse(localStorage.getItem('user'));

        this.showEdit = this.currentUser.userGroup.settings['edit_' + this.config.zone].active;

        if (typeof this.config.modelName === 'undefined') {
            this.config.modelName = this.config.model;
        }

        if (typeof this.changing !== 'undefined') {
            this.changing.subscribe(value => {
                if (this.config.modelName === value) {
                    if (typeof this.config.getUrl === 'undefined') {
                        if (typeof this.config.subModel === 'undefined') {
                            this.data = this.dataChangeService.values[this.config.modelName].getValue();
                            this.cd.detectChanges();
                        } else {
                            const model = this.dataChangeService.values[this.config.modelName].getValue();
                            this.data = model[this.config.subModel];
                            this.cd.detectChanges();
                        }
                    } else {
                        if (typeof this.config.getUrlParameters === 'undefined') {
                            this.systemService.getWithUrl(this.config.getUrl).subscribe(result => {
                                this.dataChangeService.changeValue(this.config.modelName, result);
                                this.data = this.dataChangeService.getValue(this.config.modelName);

                                if (typeof this.config.emitToParent !== 'undefined' && this.config.emitToParent === true) {
                                    this.afterLoadEvent.emit(this.data);
                                }
                                this.cd.detectChanges();
                            });
                        } else {
                            let parameterCount = 0,
                                urlParams = '';

                            for (parameterCount; parameterCount <= this.config.getUrlParameters.length - 1; parameterCount++) {
                                if (this.config.getUrlParameters[parameterCount].identifier === 'id') {
                                    if (this.isModal === true && typeof this.dataChangeService !== 'undefined') {
                                        urlParams += +this.dataChangeService.getValue('id', '');
                                    } else {
                                        urlParams += +this.route.snapshot.paramMap.get('id');
                                    }
                                }

                                if (parameterCount === this.config.getUrlParameters.length - 1) {
                                    this.systemService.getWithUrl(this.config.getUrl + urlParams).subscribe(result => {
                                        this.dataChangeService.changeValue(this.config.modelName, result);
                                        this.data = this.dataChangeService.getValue(this.config.modelName);
                                        this.cd.detectChanges();
                                    });
                                }
                            }
                        }
                    }
                }
                if (value === true) {
                    this.cd.detectChanges();
                }
            });
        }

        if (typeof this.data === 'undefined') {
            if (typeof this.config.getUrl === 'undefined') {
                if (typeof this.dataChangeService.values[this.config.modelName] === 'undefined') {
                    this.data = [];
                } else {
                    if (typeof this.config.subModel === 'undefined') {
                        this.data = this.dataChangeService.values[this.config.modelName].getValue();
                        this.cd.detectChanges();
                    } else {
                        const model = this.dataChangeService.values[this.config.modelName].getValue();
                        this.data = model[this.config.subModel];
                        this.cd.detectChanges();
                    }
                }
            } else {
                if (typeof this.config.getUrlParameters === 'undefined') {
                    this.systemService.getWithUrl(this.config.getUrl).subscribe(result => {
                        this.dataChangeService.changeValue(this.config.modelName, result);
                        this.data = this.dataChangeService.getValue(this.config.modelName);
                        if (typeof this.config.emitToParent !== 'undefined' && this.config.emitToParent === true) {
                            this.afterLoadEvent.emit(this.data);
                        }
                        this.cd.detectChanges();
                    });
                } else {
                    let parameterCount = 0,
                        urlParams = '';

                    for (parameterCount; parameterCount <= this.config.getUrlParameters.length - 1; parameterCount++) {
                        if (this.config.getUrlParameters[parameterCount].identifier === 'id') {
                            if (this.isModal === true && typeof this.dataChangeService !== 'undefined') {
                                urlParams += +this.dataChangeService.getValue('id', '');
                            } else {
                                urlParams += +this.route.snapshot.paramMap.get('id');
                            }
                        }

                        if (parameterCount === this.config.getUrlParameters.length - 1) {
                            this.systemService.getWithUrl(this.config.getUrl + urlParams).subscribe(result => {
                                this.dataChangeService.changeValue(this.config.modelName, result);
                                this.data = this.dataChangeService.getValue(this.config.modelName);
                                this.cd.detectChanges();
                            });
                        }
                    }
                }
            }
        }
    }

    getData() {
        if (typeof this.config.subModel === 'undefined') {
            return this.dataChangeService.getValue(this.config.modelName, []);
        } else {
            const model = this.dataChangeService.getValue(this.config.modelName, {});

            if (typeof model.id === 'undefined') {
                return [];
            }

            return model[this.config.subModel];
        }
    }

    editClicked(data: any) {
        this.editClick.emit(data);
    }

    editRouteClicked(data: any, url: string) {
        this.editRouteClick.emit({
            data: data,
            url: url
        });
    }

    isString(obj: any) {
        return typeof obj === 'string';
    }

    deleteClicked(data: any) {
        this.deleteClick.emit(data);
    }

    addClicked() {
        this.addClick.emit(true);
    }

    trackByFunction(index, item) {
        return index.id;
    }

    isArray(obj: any ) {
      return Array.isArray(obj);
    }
}
