<div class="flex">
    <div class="data">
        <p>{{data.message | translate}}</p>
        <span class="snackbar--button" (click)="showDetails()" *ngIf="null !== data.trace">
            {{'DETAILS' | translate}}
        </span>
    </div>
    <div class="dismiss">
        <button class="mat-simple-snackbar-action" mat-icon-button (click)="snackBarRef.dismiss()">
            <i class="far fa-times-circle"></i>
        </button>
    </div>
</div>
