import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FormGroup} from '@angular/forms';
import {User} from '../../System/Model/user';
import {ModalComponent} from '../../System/_components/Dialog/modal.component';
import {MatDialog} from '@angular/material/dialog';
import { DataChangeService } from 'src/app/System/Service/data.change.service';

@Component({
    selector: 'app-action-table',
    templateUrl: './action.table.component.html',
    styleUrls: ['../../System/system.component.css']
})
export class ActionTableComponent implements OnInit {
    private baseFields = [
        'text',
        'number'
    ];

    @Input() form: FormGroup;
    @Input() config: any;
    @Input() dataChangeService: DataChangeService;

    @Output() addRowClick = new EventEmitter<any>();
    @Output() completeSelection = new EventEmitter<any>();
    @Output() onFieldChange = new EventEmitter<any>();
    rows: any[];

    public model: any;
    currentUser: User;
    showEdit: boolean;

    constructor(
        public translate: TranslateService,
        public dialog: MatDialog
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.createForm();
    }

    createForm() {
    }

    ngOnInit() {
        this.currentUser = JSON.parse(localStorage.getItem('user'));

        this.showEdit = this.currentUser.userGroup.settings['edit_customers'].active;
        this.model = this.dataChangeService.getValue(this.config.modelName);
        this.rows = this.config.rows;
    }

    getModel() {
        return this.dataChangeService.getValue(this.config.modelName);
    }

    trackByFunction(index, item) {
        return index;
    }

    showBaseField(type: string, undefinedModel, row): boolean {
        return this.isBaseField(type) && this.checkUndefinedModel(undefinedModel, row);
    }

    isBaseField(type: string): boolean {
        return (this.baseFields.indexOf(type) !== -1);
    }

    checkUndefinedModel(undefinedModel, row): boolean {
        return (typeof row[undefinedModel] === 'undefined');
    }

    fieldValue(field: any, index: number): any {
        const model = this.dataChangeService.getValue(this.config.modelName, []);

        if (typeof field.subIdentifier === 'undefined') {
            return model[index][field.identifier];
        } else {
            if (typeof field.subSubIdentifier === 'undefined') {
                const value = model[index][field.identifier];
                return value[field.subIdentifier];
            } else {
                const value = model[index][field.identifier];
                const subFieldValue = value.getValue();
                return subFieldValue[field.subSubIdentifier];
            }
        }
    }

    checkBool(identifier: string, expected: boolean): boolean {
        return (this.dataChangeService.getValue(identifier, '') === expected);
    }

    openModal(config: any, data: any): void {
        const dialogRef = this.dialog.open(ModalComponent, {
            width: '80rem',
            data: {
                config: config,
                model: data
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (config.returnType === 'single' && typeof res !== 'undefined') {
                this.completeSelection.emit({
                    result: res[config.returnPosition],
                    selectedRow: data
                });
            }
        });
    }

    addRow(): void {
        this.addRowClick.emit();
    }

    getOutputValue(index: number, identifier: string, subIdentifier: string = '') {
        const model = this.dataChangeService.getValue(this.config.modelName, []);

        if (subIdentifier === '') {
            return model[index][identifier];
        }

        return model[index][identifier][subIdentifier];
    }

    onFieldValueChange(event, identifier, index): void {
        this.onFieldChange.emit({
            event: event,
            identifier: identifier,
            index: index
        });
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }
}
