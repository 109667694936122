import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SystemService} from '../../../System/Service/system.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-distributor-compare',
    templateUrl: './distributor.compare.component.html',
    styleUrls: ['../../../System/system.component.css']
})
export class DistributorCompareComponent implements OnInit {
    @Input() position: any;

    distributorInfos: any;
    result: any;
    selectedDistributor: any;
    @Output() selectionClick = new EventEmitter<any>();
    @HostBinding('class.col-md-10') setClass: boolean;
    @HostBinding('class.content--section') setContentClass: boolean;

    constructor(
        private systemService: SystemService,
        private cd: ChangeDetectorRef,
        public translate: TranslateService
    ) {
    }

    ngOnInit() {
        console.log('compare');
    }

    compareDistributors(): void {
        this.systemService.putCall(this.position, '/be/api/distributor/request/position/distributor/compare').subscribe(
            response => {
                let i = 0;

                for (i; i <= response.length - 1; i++) {
                    if (response[i].totalRecommendation === true) {
                        this.selectedDistributor = response[i];
                    }
                }

                this.distributorInfos = response;
                this.cd.detectChanges();
            }
        );
    }

    setDistributor(info: any): void {
        this.selectedDistributor = info;
        this.result = info;
        this.cd.detectChanges();
        this.selectionClick.emit(this.selectedDistributor);
    }
}
