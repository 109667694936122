<h3 class="description--headline">{{ placeholder | translate }}</h3>

<div class="row list--row" *ngIf="model.length < 1">
    <div class="alert full--width-row info">
        {{ 'NO_ATTACHMENTS' | translate }}
    </div>
</div>

<div class="row list--row" *ngFor="let date of model">
    <div class="col-md-12 pn">
        <div class="attachment--list-entry flex">
            <div class="entry--info" (click)="openFileModal(date.src)">
                <i class="far fa-file"></i> {{ date.file }}.{{ date.extension }}
            </div>
            <div class="entry--action ml-a">
                <button (click)="remove(date)" class="border--none bg-transparent">
                    <i class="fas fa-minus"></i>
                </button>
            </div>
        </div>
    </div>
</div>