<div [formGroup]="form">
    <mat-form-field *ngIf="type === 'text'">
        <input matInput
               placeholder="{{ placeHolder | translate }} {{getPlaceHolderAddition()}}"
               [(ngModel)]="model"
               [readonly]="undefined !== disabled ? disabled : false"
               (change)="changeValue(controlName, model)"
               formControlName="{{controlName}}"
               autocomplete="{{autoComplete}}"
               value="{{value}}">
    </mat-form-field>

    <mat-form-field *ngIf="type === 'textarea'">
        <textarea
                matInput
                [(ngModel)]="model"
                (change)="changeValue(controlName, model)"
                formControlName="{{controlName}}"
                value="{{value}}"
                placeholder="{{ placeHolder | translate }} {{getPlaceHolderAddition()}}"></textarea>
    </mat-form-field>

    <mat-form-field *ngIf="type === 'number'">
        <input matInput
               type="number"
               placeholder="{{ placeHolder | translate }} {{getPlaceHolderAddition()}}"
               [(ngModel)]="model"
               [readonly]="undefined !== disabled ? disabled : false"
               (change)="changeValue(controlName, model)"
               formControlName="{{controlName}}"
               autocomplete="{{autoComplete}}"
               value="{{value}}">
    </mat-form-field>

    <mat-form-field *ngIf="type === 'hourInput'">
        <input matInput
               placeholder="{{ placeHolder | translate }} {{getPlaceHolderAddition()}}"
               [(ngModel)]="model"
               (focus)="formatHourInput(controlName)"
               (blur)="formatHourInput(controlName)"
               [readonly]="undefined !== disabled ? disabled : false"
               (change)="changeValue(controlName, model)"
               formControlName="{{controlName}}"
               autocomplete="{{autoComplete}}"
               value="{{value}}">
    </mat-form-field>

    <mat-form-field *ngIf="type === 'time'">
        <input matInput
               type="time"
               placeholder="{{ placeHolder | translate }} {{getPlaceHolderAddition()}}"
               [(ngModel)]="model"
               (focus)="formatHourInput(controlName)"
               (blur)="formatHourInput(controlName)"
               [readonly]="undefined !== disabled ? disabled : false"
               (change)="changeValue(controlName, model)"
               formControlName="{{controlName}}"
               autocomplete="{{autoComplete}}"
               value="{{value}}">
    </mat-form-field>

    <mat-form-field *ngIf="type === 'select' && undefined !== getSelections()">
        <mat-select
                    [disabled]="undefined !== disabled ? disabled : false"
                    [compareWith]="compareObjects"
                    placeholder="{{ placeHolder | translate }}"
                    [(ngModel)]="model"
                    (selectionChange)="changeValue(controlName, $event.value)"
                    [formControl]="control">
            <mat-option class="default--select-option" [value]="''">{{ 'PLEASE_SELECT' | translate }}</mat-option>
            <mat-option class="select--option" *ngFor="let selection of getSelections()" [value]="selection">
                <span *ngIf="undefined !== selection.translate && selection.translate === false">{{selection.name}}</span>
                <span *ngIf="undefined === selection.translate && undefined !== selection.name">{{selection.name | translate}}</span>
                <span *ngIf="undefined === selection.translate && undefined !== selection.outputName">{{selection.outputName | translate}}</span>
                <span *ngIf="undefined === selection.translate && undefined === selection.name && undefined === selection.outputName">{{selection | translate}}</span>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div *ngIf="type === 'password'">
        <mat-form-field>
            <input matInput
                   required
                   placeholder="{{ 'PASSWORD' | translate }}"
                   [(ngModel)]="model"
                   (change)="changeValue(controlName, model)"
                   formControlName="{{controlName}}"
                   value="{{model}}"
                   [type]="hidePassword ? 'password' : 'text'">
            <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off'
                : 'visibility'}}
            </mat-icon>
        </mat-form-field>
    </div>

    <div class="row" *ngIf="type === 'checkbox'">
        <mat-checkbox
                (change)="changeValue(controlName, model)"
                formControlName="{{controlName}}"
                [(ngModel)]="model">
            {{ placeHolder | translate }}
        </mat-checkbox>
    </div>

    <div class="row" *ngIf="type === 'rangeSlider'">
        <h3 class="description--headline">{{ placeHolder | translate }}</h3>

        <mat-slider
            (change)="changeValue(controlName, model)"
            thumbLabel
            formControlName="{{controlName}}"
            tickInterval="{{config.interval}}"
            min="{{config.from}}"
            [(ngModel)]="model"
            max="{{config.to}}"></mat-slider>
    </div>

    <div *ngIf="type === 'editor'">
        <h3 class="description--headline">{{ placeHolder | translate }}</h3>

        <app-editor
            [mention]="config.mentions"
            [model]="model"
            (editorChange)="changeValue(controlName, $event)"
            [formGroup]="form"
            [controlName]="controlName"
        ></app-editor>
    </div>

    <div *ngIf="type === 'code'">
        <h3 class="description--headline">{{ placeHolder | translate }}</h3>

        <app-code-editor
            [model]="model"
            (codeChange)="changeValue(controlName, $event)"
            [formGroup]="form"
            [config]="config"
            [controlName]="controlName"
        ></app-code-editor>
    </div>

    <div *ngIf="type === 'color'">
        <h3 class="description--headline">{{ placeHolder | translate }}</h3>

        <input [(colorPicker)]="model"
               class="color--picker"
               [style.background]="model"
               [cpWidth]="'375px'"
               (colorPickerChange)="changeValue(controlName, $event)"
               [(ngModel)]="model"
               formControlName="{{controlName}}"
               value="{{model}}">

        <div class="color--picker-border"></div>
        <div class="color--indicator" [style.background-color]="model"></div>
    </div>

    <mat-form-field *ngIf="type === 'datepicker' && undefined !== value">
        <input matInput
               [value]="getDatePickerValue()"
               formControlName="{{controlName}}"
               (dateChange)="changeValue(controlName, $event.value)"
               [matDatepicker]="datepicker"
               placeholder="{{ placeHolder | translate }}">
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker disabled="false" [startAt]="getDatePickerValue()"></mat-datepicker>
    </mat-form-field>
</div>
