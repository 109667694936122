import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface ErrorDetailsComponentData {
  message: any;
  trace: [];
}

@Component({
    selector: 'app-error-details',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './error.details.component.html',
    styleUrls: ['../../../System/system.component.css']
})
export class ErrorDetailsComponent implements OnInit {
    locationString: string;
    completeTrace: object [];
    showTrace: boolean;

    constructor(
        public translate: TranslateService,
        private cd: ChangeDetectorRef,
        public dialogRef: MatDialogRef<ErrorDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ErrorDetailsComponentData,
        private location: Location
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.locationString = this.location.path();
    }

    ngOnInit() {
        this.completeTrace = [];
        this.showTrace = false;

        let traceCount = 0;

        for (traceCount; traceCount <= this.data.trace.length - 1; traceCount++) {
            const trace: object = this.data.trace[traceCount];

            if (trace['class'] !== '') {
                this.completeTrace.push(trace);
            }

            if (traceCount === this.data.trace.length - 1) {
                this.showTrace = true;
                this.cd.detectChanges();
            }
        }
    }

    noClicked() {
        this.dialogRef.close();
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }
}
