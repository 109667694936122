<mat-horizontal-stepper [linear]="true" #stepper *ngIf="undefined !== data && showForm === true; else loading">
    <mat-step [stepControl]="forms[step.formName]" *ngFor="let step of steps; first as isFirst; last as isLast">
        <form (keydown.enter)="handleEnterKeyPress($event)" [formGroup]="forms[step.formName]">
            <ng-template matStepLabel>{{ step.headline | translate }}</ng-template>

            <div *ngFor="let row of step.rows">
                <div class="{{row.rowClass}}">
                    <div *ngFor="let col of row.cols" class="{{col.colClass}}">
                        <div *ngFor="let field of col.fields">
                            <app-field
                                    *ngIf="field.type !== 'subTabs' && field.type !== 'descriptionHeadline' && undefined === field.undefinedModel && undefined !== data
                                                        || undefined !== data &&
                                                        (undefined !== field.undefinedModel && (undefined !== data[field.undefinedModel].id || data[field.undefinedModel].length > 0))"
                                    [placeHolder]="field.placeholder"
                                    [model]="undefined !== field.subIdentifier ? data[field.identifier][field.subIdentifier] : data[field.identifier]"
                                    [controlName]="field.formControlName"
                                    [autoComplete]="field.autoComplete"
                                    [value]="undefined !== field.subIdentifier ? data[field.identifier][field.subIdentifier] : data[field.identifier]"
                                    [disabled]="field.disabled"
                                    [selections]="selections[field.selectionIdentifier]"
                                    [selectionsFromModel]="field.selectionsFromModel"
                                    [selectionsFromModelValues]="field.selectionsFromMoodel !== true ? [] : data[field.selectionsFromModelName][field.selectionsFromSubModelName]"
                                    [config]="field.config"
                                    [type]="field.type"
                                    [fileArray]="data[field.fileArrayIdentifier]"
                            ></app-field>

                            <div *ngIf="field.type === 'descriptionHeadline'">
                                <app-description-headline
                                        [headline]="field.headline">
                                </app-description-headline>
                            </div>

                            <div *ngIf="undefined !== data && field.type === 'projectTemplateStructure'">
                                <app-project-template-structure
                                        [form]="forms[step.formName]"
                                        [data]="model.model">
                                </app-project-template-structure>
                            </div>

                            <div *ngIf="field.type === 'returnPosition'">
                                <app-return-position-component
                                    (afterPositionSelect)="afterAdditionModelSelect($event, field.additionalModelName)"
                                    [model]="data">
                                </app-return-position-component>
                            </div>

                            <div *ngIf="field.type === 'returnPositionCondition'">
                                <app-return-position-condition-component
                                    [changing]="changingValue"
                                    [model]="additionalModels[field.additionalModelName]">
                                </app-return-position-condition-component>
                            </div>

                            <div class="modal--tab-container" *ngIf="field.type === 'subTabs'">
                                <mat-tab-group>
                                    <mat-tab label="{{tab.headline}}" *ngFor="let tab of field.tabs">
                                        <div *ngFor="let tabField of tab.fields">
                                            <app-field
                                                    *ngIf="undefined === tabField.undefinedModel && undefined !== data
                                                        || undefined !== data &&
                                                        (undefined !== tabField.undefinedModel && (undefined !== data[tabField.undefinedModel].id || data[tabField.undefinedModel].length > 0))"
                                                    [placeHolder]="tabField.placeholder"
                                                    [model]="undefined !== tabField.subIdentifier ? data[field.modelName][tabField.identifier][tabField.subIdentifier] : data[tabField.identifier]"
                                                    [controlName]="tabField.formControlName"
                                                    [autoComplete]="tabField.autoComplete"
                                                    [value]="undefined !== tabField.subIdentifier ? data[field.modelName][tabField.identifier][tabField.subIdentifier] : data[tabField.identifier]"
                                                    [disabled]="tabField.disabled"
                                                    [selections]="selections[tabField.selectionIdentifier]"
                                                    [selectionsFromModel]="tabField.selectionsFromModel"
                                                    [selectionsFromModelValues]="tabField.selectionsFromMoodel !== true ? [] : data[tabField.selectionsFromModelName][tabField.selectionsFromSubModelName]"
                                                    [config]="tabField.config"
                                                    [type]="tabField.type"
                                                    [fileArray]="data[tabField.fileArrayIdentifier]"
                                            ></app-field>
                                        </div>
                                    </mat-tab>
                                </mat-tab-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <button mat-button matStepperPrevious mat-raised-button *ngIf="!isFirst">{{ 'BACK' | translate }}</button>
                <button mat-button matStepperNext mat-raised-button *ngIf="!isLast">{{ 'NEXT' | translate }}</button>
                <button type="submit" mat-button mat-raised-button *ngIf="isLast" (click)="save()">{{ 'SAVE' | translate }}</button>
            </div>
        </form>
    </mat-step>
</mat-horizontal-stepper>

<ng-template #loading>
    <app-loader-component></app-loader-component>
</ng-template>
