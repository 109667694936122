import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

export interface BatchDeleteComponentDialogData {
    message: any;
    data: any;
}

@Component({
    selector: 'app-batch-delete-dialog-component',
    templateUrl: './batch.delete.dialog.html',
})
export class BatchDeleteComponent implements OnInit {
    message: string;
    event: Event;
    @Output() confirmClick = new EventEmitter<any>();

    constructor(
        public dialogRef: MatDialogRef<BatchDeleteComponent>,
        public translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: BatchDeleteComponentDialogData
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
    }

    ngOnInit() {
        this.message = this.data.message;
        this.data = this.data.data;
    }

    confirmClicked() {
        this.dialogRef.close(this.data);
    }

    closeDialog(): void {
        this.onNoClick();
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }
}
