import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter, Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {SystemService} from '../../../System/Service/system.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface CommentEditComponentData {
  comment: any;
  model: any;
  companies: any;
  config: any;
}

@Component({
    selector: 'app-edit-comment',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './comment.edit.component.html',
    styleUrls: ['../../../System/system.component.css']
})
export class CommentEditComponent implements OnInit {
    @Input() uploadConfig: any;
    @Output() addClick = new EventEmitter<any>();
    @Output() noClick = new EventEmitter<boolean>();
    commentForm: FormGroup;
    locationString: string;
    comment: any;
    attachments: any;
    companies: any;
    commentId: number;

    constructor(
        private fb: FormBuilder,
        private cd: ChangeDetectorRef,
        public translate: TranslateService,
        public systemService: SystemService,
        public dialogRef: MatDialogRef<CommentEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CommentEditComponentData,
        private location: Location
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.locationString = this.location.path();
        this.companies = this.data.companies;

        this.createForm();
    }

    ngOnInit() {
        this.attachments = this.data.comment.attachments;
        this.commentId = this.data.comment.id;
        this.comment = this.data.comment;

        this.commentForm.addControl('message', new FormControl(this.comment.message));
        this.commentForm.addControl('isImportant', new FormControl(this.comment.isImportant));
        this.commentForm.addControl('company', new FormControl(this.comment.company));
        this.commentForm.valueChanges.subscribe(result => this.comment = result);

        if (typeof this.uploadConfig === 'undefined') {
          this.uploadConfig = this.data.config.uploadConfig;
        }

        this.uploadConfig.baseModel = this.data.model;
        this.cd.detectChanges();
    }

    editClicked() {
        this.comment.id = this.commentId;
        this.comment.attachments = this.attachments;

        this.systemService.putCall(this.comment, this.data.config.saveCall).subscribe(added => {
          this.noClicked();
        });
    }

    compareObjects(o1: any, o2: any): boolean {
        return o1.name === o2.name && o1.id === o2.id;
    }

    onFinishUpload(uploadedFiles): void {
        this.attachments = uploadedFiles;
    }

    noClicked() {
        this.noClick.emit(true);
        this.dialogRef.close();
    }

    createForm() {
        this.commentForm = this.fb.group({});
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }
}
