import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import {
  FormBuilder,
  FormControl, FormGroup
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {SystemService} from '../../../System/Service/system.service';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {QuickEditComponent} from '../../QuickEdit/quick.edit.component';

@Component({
    selector: 'app-global-search',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './global.search.component.html',
    styleUrls: ['../../../System/system.component.css']
})
export class GlobalSearchComponent implements OnInit {
    showFilterSearch: boolean;
    searchResults: any;
    detailSearchResult: any;
    mode: any;
    detailComponents: any[];
    @Input() config: any;
    @Input() model: any;
    @Output() afterSelect = new EventEmitter<number>();
    globalSearchSelectForm: FormGroup;
    searchField: FormControl = new FormControl();

    constructor(
        private fb: FormBuilder,
        private systemService: SystemService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<GlobalSearchComponent>,
        public translate: TranslateService,
        private _router: Router,
        private cd: ChangeDetectorRef,
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.createForm();
    }

    createForm() {
        this.globalSearchSelectForm = this.fb.group({});
    }

    ngOnInit() {
        this.searchField.valueChanges
            .pipe(
                debounceTime(1000),
                distinctUntilChanged(),
                switchMap((query) => this.systemService.getGlobalSearchResults(query))
            )
            .subscribe(result => {
                this.searchResults = result;
                this.cd.detectChanges();
            });
    }

    onSearchResultSelected(searchResult: any, mode: string) {
        this.mode = mode;
        if (typeof searchResult.detailCall !== 'undefined') {
            this.detailSearchResult = searchResult;

            this.systemService.getWithUrl(this.detailSearchResult.detailCall).subscribe(result => {
                this.detailComponents = result;
                this.cd.detectChanges();
            });
        } else {
            this.routeToEntry(searchResult.link);
        }
    }

    routeToEntry(link: string) {
        this.dialogRef.close(link);
    }

    openQuickEdit(result: any) {
        this.dialog.open(QuickEditComponent, {
            width: '50rem',
            data: {
                mode: this.mode,
                id: result.id
            }
        });
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }
}
