<div class="center">
    <div *ngIf="mode === 'image'">
        <img src="{{file}}">
    </div>
    <div *ngIf="mode === 'text'">
        <object style="display: block; width:100%;height:100vh;" [data]="file | safe" type="application/pdf">
            <embed [src]="file | safe" type="application/pdf" />
        </object>
    </div>
    <div *ngIf="mode === 'video'">
        <video controls>
            <source [src]="file | safe" type="video/mp4" *ngIf="data.extension === 'mp4'">
            <source [src]="file | safe" type="video/ogg" *ngIf="data.extension === 'ogg'">
            Your browser does not support the video tag.
        </video>
    </div>
</div>
