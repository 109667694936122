import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {NavItem} from './Model/nav.item';
import {NavService} from './Service/nav.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['../../System/system.component.css'],
    animations: [
        trigger('slide', [
            state('up', style({ height: 0 })),
            state('down', style({ height: '*' })),
            transition('up <=> down', animate(200))
        ])
    ]
})
export class NavigationComponent implements OnInit {
    active: boolean;
    activeItem: any;
    footerNavigationRoutes = [
        '/base_settings',
        '/help',
        '/company/add',
        '/account'
    ];
    @HostBinding('attr.aria-expanded') ariaExpanded = false;
    @Input() items: NavItem[];
    @Input() depth: number;

    constructor(public router: Router) {
        if (this.depth === undefined) {
            this.depth = 0;
        }

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.ngOnInit();
            }
        });
    }

    ngOnInit() {
        if (this.router.url) {
            let itemCount = 0;

            if (this.footerNavigationRoutes.includes(this.router.url) || this.router.url.includes('/company/edit/')) {
                this.activeItem = {};
            } else {
                for (itemCount; itemCount <= this.items.length - 1; itemCount++) {
                    let childrenCount = 0;

                    for (childrenCount; childrenCount <= this.items[itemCount].children.length - 1; childrenCount++) {
                        if (this.router.url.indexOf(this.items[itemCount].children[childrenCount].route) > -1) {
                            this.items[itemCount].active = true;
                            this.activeItem = this.items[itemCount].children[childrenCount];
                        }
                    }
                }
            }
        }
    }

    getState(currentMenu) {
        if (currentMenu.active) {
            return 'down';
        } else {
            return 'up';
        }
    }

    toggle(currentMenu) {
        if (currentMenu.type === 'header') {
            this.items.forEach(element => {
                if (element === currentMenu) {
                    currentMenu.active = !currentMenu.active;
                } else {
                    element.active = false;
                }
            });
        }
    }

    getLabel(item: NavItem): string {
        return item.displayName;
    }

    onItemSelected(item: NavItem) {
        if (!item.children || !item.children.length) {
            localStorage.setItem('paginationCurrentPage', '1');
            this.router.navigate([item.route]);
            // this.navService.closeNav();
        }
        if (item.children && item.children.length) {
            item.active = !item.active;
        }
    }
}
