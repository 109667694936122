<div class="list--row row--secondary">
    <div *ngIf="config.showUpload !== false && undefined !== uploadSuccess.message" class="alert danger">
        {{uploadSuccess.message}}
    </div>

    <div class="button-row" *ngIf="config.showUpload !== false && undefined === config.showSwitch">
        <div (click)="switchSelectionMode()" class="mat-raised-button file--button">
            <span *ngIf="selectExistingImage === false"><i class="far fa-hand-pointer"></i> {{ 'SELECT_IMAGE' | translate }}</span>
            <span *ngIf="selectExistingImage === true"><i class="fas fa-cloud-upload-alt"></i> {{ 'UPLOAD_IMAGE' | translate }}</span>
        </div>
        <div (click)="assignExistingImages()" *ngIf="showAssignExistingImages === true && undefined !== config.assignCall" class="mat-raised-button file--button">
            <i class="fas fa-link"></i> {{ 'ASSIGN' | translate }}
        </div>
    </div>

    <div *ngIf="config.showUpload !== false && selectExistingImage === false">
        <div id="dropbox" class="dropbox drop-box--class">
            <span>{{ config.dropText | translate }}</span>

            <br>
            <br>

            <input type="file"
                   name="{{id}}"
                   id="{{id}}"
                   multiple="multiple"
                   (change)="onUpload($event)"
                   class="inputFile"/>

            <label for="{{id}}">{{ 'CHOOSE_FILE' | translate }}</label>
        </div>

        <div *ngIf="undefined !== files && files.length > 0">
            <mat-list>
                <mat-list-item *ngFor="let file of files; let i = index">
                    <div class="file--upload-list-item row">
                        <div class="col-md-3">
                            <p mat-line>
                                <i class="fas fa-sticky-note"></i> {{file.name}}
                            </p>
                        </div>

                        <div class="col-md-9">
                            <div mat-line class="progress">
                                <div class="progress-bar progress-bar-success progress-bar-striped"
                                     role="progressbar"
                                     [attr.aria-valuenow]="uploadPercentages[i].percentage"
                                     aria-valuemin="0" aria-valuemax="100"
                                     style="min-width: 2rem;"
                                     [style.width]="(uploadPercentages[i].percentage/100)*100 + '%'">
                                    {{uploadPercentages[i].percentage}}%
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-list-item>
            </mat-list>

            <div class="row">
                <div class="col-md-6">
                </div>
                <div class="col-md-6">
                    <div class="button-row no--icon-margin">
                        <div (click)="doUpload()" class="mat-raised-button mat-primary">
                            <i class="fas fa-upload"></i> {{ 'UPLOAD' | translate }}
                        </div>
                        <div (click)="removeUploadSelection()" class="mat-raised-button delete--button">
                            <i class="far fa-trash-alt"></i> {{ 'RESET' | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="config.showUpload !== false && selectExistingImage === true">
        <div class="image--selection-wrapper" [ngClass]="{'max--height': undefined !== config.isModal && config.isModal === true}">
            <mat-tab-group>
                <mat-tab *ngFor="let area of existingImages" label="{{ area.name }}">
                    <div (click)="switchImage(image)" class="row image--selection-entry" [ngClass]="{'active': in_array(image.id)}" *ngFor="let image of area.media; let i = index">
                        <div class="col-md-4">
                            <img (click)="openFileModal(image.src);$event.stopPropagation()" class="image--selection--preview-image" src='{{image.src}}'>
                        </div>
                        <div class="col-md-5">
                            {{(image.media.file | slice:0:25) + '..'}}
                        </div>
                        <div class="col-md-3">
                            <div *ngIf="in_array(image.id) == false"
                                 class='list--button text-center action--row-button'>
                                <i class="fas fa-plus"></i>
                            </div>
                            <div *ngIf="in_array(image.id) == true"
                                 class='list--button text-center action--row-button'>
                                <i class="fas fa-minus"></i>
                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>

    <div *ngIf="config.showImages === true">
        <h3 class="description--headline mt-5">{{ 'CONNECTED_IMAGES' | translate }}</h3>

        <div class="file--list">
            <div class="row list--entry header">
                <div class="col-md-3">
                    {{ 'IMAGE' | translate }}
                </div>
                <div class="col-md-4">
                    {{ 'NAME' | translate }}
                </div>
                <div class="col-md-2">
                    {{ 'EXTENSION' | translate }}
                </div>
                <div class="col-md-3">
                    {{ 'ACTIONS' | translate }}
                </div>
            </div>
            <div class="row list--entry" [ngClass]="{'delete--item': image.delete === true}" *ngFor="let image of images; let i = index">
                <div class="col-md-3">
                    <img (click)="openFileModal(image.media.src)" class="image--selection--preview-image" src='{{image.media.src}}'>
                </div>
                <div class="col-md-4">
                    {{image.media.file}}
                </div>
                <div class="col-md-2">
                    {{image.media.extension}}
                </div>
                <div class="col-md-3 action--column">
                <span *ngIf="undefined === image.approved || image.approved === false">
                    <i (click)="approveImage(image)" class="fas fa-times" aria-hidden="true"></i>
                </span>
                <span *ngIf="image.approved === true">
                    <i (click)="approveImage(image)" class="fas fa-check-circle" aria-hidden="true"></i>
                </span>
                <span *ngIf="undefined === image.delete || image.delete === false">
                    <i (click)="deleteImage(image)" class="fas fa-trash" aria-hidden="true"></i>
                </span>
                <span *ngIf="image.delete === true">
                    <i (click)="deleteImage(image)" class="fas fa-minus-circle" aria-hidden="true"></i>
                </span>
                <span>
                    <mat-checkbox
                            (change)="setMainImage(image)"
                            [(ngModel)]="image.main">
                        {{ 'MAIN_IMAGE' | translate }}
                    </mat-checkbox>
                </span>
                </div>
            </div>
            <div class="button-row" *ngIf="showSaveImages === true">
                <div (click)="saveImages()" class="mat-raised-button">
                    <i class="fas fa-save"></i> {{ 'SAVE' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>
