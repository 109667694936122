import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes, NoPreloading} from '@angular/router';

import {AuthGuard} from './System/Guard/auth.guard';

const routes: Routes = [
    {
        path: 'login',
        loadChildren: () => import('./Login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'publication',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'genre',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'author',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'question',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'dashboard',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'publicationList',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'quote',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'cooperation',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'contest',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'responsible',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'contribution',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'age_group',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'school',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'school_type',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'vote_export_type',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'cooperation_partner',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'story',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'event',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'forum',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'storyReview',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'publicationReview',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'blogCategory',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'blogEntry',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'frontenduser',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'market',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'contact',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'staff',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'user',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'media',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'settings',
        loadChildren: () => import('./System/system.module').then(m => m.SystemModule),
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {useHash: true, preloadingStrategy: NoPreloading})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
