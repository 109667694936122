import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
  ControlContainer,
  FormControl, FormGroup
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {DataChangeService} from '../../../System/Service/data.change.service';
import * as BalloonEditor from '../../../BaseComponents/libs/ckeditor-balloon-mentions/build/ckeditor';
import { SystemService } from 'src/app/System/Service/system.service';
import { moveMessagePortToContext } from 'worker_threads';

@Component({
    selector: 'app-field',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './field.component.html',
    styleUrls: ['../../../System/system.component.css']
})
export class FieldComponent implements OnInit {
    showFilterSearch: boolean;
    data: any[];
    @Input() type: string;
    @Input() currentId: number;
    @Input() placeHolder: string;
    @Input() placeholderAddition: string;
    @Input() fileArray: any;
    @Input() config: any;
    @Input() model: any;
    @Input() disabled: boolean;
    @Input() selections: any[];
    @Input() selectionsFromModel: boolean;
    @Input() selectionsFromModelValues: any[];
    @Input() value: any;
    @Input() valueType: string;
    @Input() controlName: string;
    @Input() autoComplete: boolean;
    @Input() dataChangeService: DataChangeService;
    public form: FormGroup;
    public control: FormControl;
    public Editor = BalloonEditor;
    hidePassword: boolean;
    @Output() onChange = new EventEmitter<any>();

    constructor(
        private controlContainer: ControlContainer,
        private cd: ChangeDetectorRef,
        private systemService: SystemService,
        public translate: TranslateService
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.createForm();
    }

    createForm() {
    }

    ngOnInit() {
        this.hidePassword = true;
        if (this.selectionsFromModel === true) {
            this.selections = this.selectionsFromModelValues;
        }
        this.form = <FormGroup>this.controlContainer.control;
        this.control = <FormControl>this.form.get(this.controlName);

        if (this.type === 'datepicker') {
            this.value = new Date(this.value.date);
            
            this.cd.detectChanges();
        }

        if (
            typeof this.controlName !== 'undefined' &&
            (
                typeof this.config !== 'undefined' &&
                (
                    typeof this.config.ignoreControlName === 'undefined' ||
                    this.config.ignoreControlName === false
                )
            )
        ) {
            if (typeof this.dataChangeService.values[this.controlName] !== 'undefined') {
                this.dataChangeService.values[this.controlName].subscribe(result => {
                    this.model = result;
                    this.cd.detectChanges();
                });
            }
        }

        if (
            this.type === 'select' &&
            (typeof this.selections === 'undefined' || this.selections.length === 0) &&
            !this.selectionsFromModel
        ) {
            if (typeof this.config !== 'undefined') {
                if (typeof this.config.getUrlParameters === 'undefined') {
                    this.systemService.getData(this.config.getCall).subscribe(selections => {
                        this.selections = selections;
                        this.cd.detectChanges();
                    });
                } else {
                    let parameterCount = 0,
                        urlParams = '';
                    const parameterLength = this.config.getUrlParameters.length - 1;

                    for (parameterCount; parameterCount <= parameterLength; parameterCount++) {
                        const parameter = this.config.getUrlParameters[parameterCount];

                        urlParams += '/' + this.dataChangeService.getValue(parameter.identifier, '');

                        if (parameterCount === parameterLength) {
                            this.systemService.getData(this.config.getCall + urlParams).subscribe(selections => {
                                this.selections = selections;
                                this.cd.detectChanges();
                            });
                        }
                    }
                }
            }
        }
    }

    getDatePickerValue() {
        return this.value;
    }

    getSelections() {
        return this.selections;
    }

    getPlaceHolderAddition() {
        return (typeof this.placeholderAddition === 'undefined') ? '' : this.placeholderAddition;
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }

    compareObjects(o1: any, o2: any): boolean {
        if (typeof o2 === 'string') {
            return o2 === o1.value;
        } else {
            return o1.name === o2.name && o1.id === o2.id;
        }
    }

    formatHourInput(valueName) {
        if (this.model === '') {
            this.model = '00:00';
            this.setDataChangeServiceValue(valueName, this.model);
        } else {
            const times = this.model.split(':');
            let minutes = parseInt(times[1], 0);
            let output = '';
            let hours = parseInt(times[0], 0);

            if (minutes > 59) {
                const hoursFromMinutes = minutes / 60;

                hours = hours + hoursFromMinutes;
                minutes = minutes - (60 * hoursFromMinutes);
                output = '0' + minutes.toString();
            } else if (minutes < 10) {
                output = '0' + minutes.toString();
            } else {
                output = minutes.toString();
            }

            if (hours < 10) {
                output = '0' + hours.toString() + ':' + output;
            } else {
                output = hours.toString() + ':' + output;
            }

            this.model = output;
            this.setDataChangeServiceValue(valueName, this.model);
        }
    }

    changeValue(valueName: string, value: any) {
        this.setDataChangeServiceValue(valueName, value);
    }

    setDataChangeServiceValue(valueName: string, value: any) {
        if (typeof this.dataChangeService.values[valueName] !== 'undefined') {
            if (
                typeof this.config !== 'undefined' &&
                typeof this.config.avoidSetChange !== 'undefined' &&
                this.config.avoidSetChange === true
            ) {
                this.onChange.emit(value);
            } else {
                this.dataChangeService.changeValue(valueName, value);
                this.onChange.emit(value);
            }
        } else if (typeof this.config === 'undefined') {
            this.onChange.emit(value);
        } else if (typeof this.config.changeSubValue !== 'undefined' && this.config.changeSubValue === true) {
            const model = this.dataChangeService.getValue(this.config.model, []);

            model[this.config.index][this.config.value] = value;
            this.dataChangeService.changeValue(this.config.model, model);
            this.onChange.emit(value);
        }
    }
}
