import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    ControlContainer,
    FormControl, FormGroup,
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import { SystemService } from 'src/app/System/Service/system.service';
import {DataChangeService} from '../../../System/Service/data.change.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-select-list',
    templateUrl: './select.list.component.html',
    styleUrls: ['../../../System/system.component.css']
})
export class SelectListComponent implements OnInit {
    showFilterSearch: boolean;
    data: any;
    @Input() identifier: string;
    @Input() controlName: any;
    @Input() placeholder: any;
    @Input() dataChangeService: DataChangeService;
    @Input() config: any;
    @Input() clearing: Subject<any>;
    @Input() selections: any;
    @Output() afterSelect = new EventEmitter<number>();
    public form: FormGroup;
    public control: FormControl;

    public model: any;
    public isSearchSelect: boolean;

    constructor(
        private controlContainer: ControlContainer,
        public systemService: SystemService,
        private cd: ChangeDetectorRef,
        public translate: TranslateService
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.createForm();
    }

    createForm() {
    }

    ngOnInit() {
        // TODO get selections with parameters
        this.form = <FormGroup>this.controlContainer.control;
        this.control = <FormControl>this.form.get(this.controlName);
        this.isSearchSelect = (typeof this.config.searchSelect === 'undefined') ? false : this.config.searchSelect;

        if (this.isSearchSelect === false) {
            if (typeof this.selections === 'undefined') {
                if (typeof this.config.selections.getUrlParameters === 'undefined') {
                    this.systemService.getData(this.config.selections.getCall).subscribe(selections => {
                        this.selections = selections;
                        this.cd.detectChanges();
                    });
                } else {
                    let parameterCount = 0,
                        urlParams = '';
                    const parameterLength = this.config.selections.getUrlParameters.length - 1;
    
                    for (parameterCount; parameterCount <= parameterLength; parameterCount++) {
                        const parameter = this.config.selections.getUrlParameters[parameterCount];
    
                        urlParams += '/' + this.dataChangeService.getValue(parameter.identifier, '');
    
                        if (parameterCount === parameterLength) {
                            this.systemService.getData(this.config.selections.getCall + urlParams).subscribe(selections => {
                                this.selections = selections;
                                this.cd.detectChanges();
                            });
                        }
                    }
                }
            }
        }
        const model = this.dataChangeService.getValue(this.identifier, []);

        if (model.length > 0) {
            this.model = model;
        } else {
            if (typeof this.config.model !== 'undefined') {
                if (typeof this.config.model.getUrlParameters === 'undefined') {
                    this.systemService.getData(this.config.model.getCall).subscribe(data => {
                        this.model = data;
                        this.dataChangeService.changeValue(this.identifier, this.model);
                        this.cd.detectChanges();
                    });
                } else {
                    let modelParameterCount = 0,
                        urlParams = '';
                    const parameterLength = this.config.model.getUrlParameters.length - 1;

                    for (modelParameterCount; modelParameterCount <= parameterLength; modelParameterCount++) {
                        const parameter = this.config.model.getUrlParameters[modelParameterCount];

                        urlParams += '/' + this.dataChangeService.getValue(parameter.identifier, '');

                        if (modelParameterCount === parameterLength) {
                            this.systemService.getData(this.config.model.getCall + urlParams).subscribe(data => {
                                this.model = data;
                                this.dataChangeService.changeValue(this.identifier, this.model);
                                this.cd.detectChanges();
                            });
                        }
                    }
                }
            } else {
                this.model = [];
            }
        }
    }

    getData() {
        return this.model;
    }

    afterDataSet() {
        this.dataChangeService.changeValue(this.identifier, this.model);
        this.afterSelect.emit(this.model);
    }

    setSelection(event) {
        let data, i = 0, isSelected = false, position = 0;

        if (typeof this.model === 'undefined') {
            this.model = [];
        }
        if (typeof event.slug === 'undefined') {
            data = event.value;
        } else {
            data = event;
        }
        for (i; i <= this.model.length - 1; i++) {
            let key = 'id';
            if (typeof this.model[i].id === 'undefined') {
                key = 'slug';
            }
            if (typeof this.model[i][this.controlName] !== 'undefined') {
                if (this.model[i][this.controlName][key] === data[key]) {
                    isSelected = true;
                    position = i;
                }
            }
            if (this.model[i][key] === data[key]) {
                isSelected = true;
                position = i;
            }
        }
        
        if (isSelected === false) {
            this.model.push(data);
        } else {
            this.model.splice(position, 1);
        }
        if (typeof event.source !== 'undefined') {
            event.source.value = undefined;
        }
        this.afterDataSet();
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }
}
