import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataChangeService} from './data.change.service';
import {Form, FormControl, FormGroup} from '@angular/forms';

@Injectable({providedIn: 'root'})
export class ManipulationService {
    async getFieldByIdentifier(identifier: string, config: any): Promise<any> {
        return Promise.resolve((() => {
            let tabCount = 0;

            // iterate through all tabs rows and cols to compare fields
            for (tabCount; tabCount <= config.tabs.length - 1; tabCount++) {
                const tab = config.tabs[tabCount];
                let rowCount = 0;

                for (rowCount; rowCount <= tab.rows.length - 1; rowCount++) {
                    const row = tab.rows[rowCount];
                    let colCount = 0;

                    for (colCount; colCount <= row.cols.length - 1; colCount++) {
                        const col = row.cols[colCount];
                        let fieldCount = 0;

                        for (fieldCount; fieldCount <= col.fields.length - 1; fieldCount++) {
                            if (col.fields[fieldCount].identifier === identifier) {
                                return col.fields[fieldCount];
                            }
                        }
                    }
                }
            }
        })());
    }

    resetModel(dataChangeService: DataChangeService, data: any): Observable<any> {
        return new Observable(observer => {
            const length = Object.keys(data).length;

            Object.keys(data).forEach(function (key, index) {
                if (typeof data[key] === 'object') {
                    data[key] = [];
                }
                dataChangeService.setValue(key, data[key]);

                if (index === length - 1) {
                    observer.next(data);
                }
            });
        });
    }

    resetArrays(config: any, data): Observable<any> {
        return new Observable(observer => {
            if (typeof config.resetData !== 'undefined' && config.resetData.length > 0) {
                let resetCount = 0;

                for (resetCount; resetCount <= config.resetData.length - 1; resetCount++) {
                    this.resetArray(data[config.resetData[resetCount].name]).subscribe(res => {
                        data[config.resetData[resetCount].name] = res;
                        if (resetCount === config.resetData.length - 1) {
                            observer.next(data);
                        }
                    });
                }
            } else {
                observer.next(data);
            }
        });
    }

    resetArray(array: []): Observable<any> {
        return new Observable(observer => {
            const result = [],
                arrayCount = Object.keys(array).length;

            if (arrayCount === 0) {
                observer.next(result);
            } else {
                Object.keys(array).forEach(function (key, index) {
                    result.push(array[key]);
                    if (index === arrayCount - 1) {
                        observer.next(result);
                    }
                });
            }
        });
    }

    getDataFromChangeService(dataChangeService: DataChangeService, formControls = null): Observable<any> {
        return new Observable(observer => {
            const result = {},
                length = Object.entries(dataChangeService.values).length;
            let count = 0;

            if (formControls === null || typeof formControls === 'undefined') {
                for (const [key, dataChangeValue] of Object.entries(dataChangeService.values)) {
                    if (typeof dataChangeValue.value === 'undefined') {
                        if (count === length - 1) {
                            observer.next(result);
                        } else {
                            count++;
                            continue;
                        }
                    }
                    result[key] = dataChangeValue.value;

                    if (count === length - 1) {
                        observer.next(result);
                    }
                    count++;
                }
            } else {
                for (let controlCount = 0; controlCount <= formControls.length - 1 ; controlCount++) {
                    const dataChangeValue = formControls[controlCount];

                    if (typeof dataChangeService.getValue(dataChangeValue) === 'undefined') {
                        if (controlCount === formControls.length - 1) {
                            observer.next(result);
                        } else {
                            continue;
                        }
                    }
                    result[dataChangeValue] = dataChangeService.getValue(dataChangeValue);

                    if (controlCount === formControls.length - 1) {
                        observer.next(result);
                    }
                }
            }
        });
    }

    setDataToChangeService(data: any, dataChangeService: DataChangeService): Observable<DataChangeService> {
        return new Observable(observer => {
            const length = Object.entries(data).length;
            let count = 0;

            for (const [key, dataValue] of Object.entries(data)) {
                dataChangeService.changeValue(key, dataValue);

                if (count === length - 1) {
                    observer.next(dataChangeService);
                }
                count++;
            }
        });
    }

    setActionTableValues(
        res: any,
        field: any,
        modelName: string,
        data: any,
        form: FormGroup,
        dataChangeService: DataChangeService
    ): Promise<any> {
        return Promise.resolve((() => {
            let resultCount = 0;
            const rows = [];

            if (typeof  res === 'object') {
                res = Object.values(res);
            }

            for (resultCount; resultCount <= res.length - 1; resultCount++) {
                let colCount = 0;
                const cols = [];

                for (colCount; colCount <= field.config.rows[0].cols.length - 1; colCount++) {
                    const col = Object.assign({}, field.config.rows[0].cols[colCount]);
                    if (field.config.rows[0].cols[colCount].type !== 'conditional') {
                        let model;

                        if (typeof field.config.rows[0].cols[colCount].subIdentifier !== 'undefined') {
                            model = res[resultCount][field.config.rows[0].cols[colCount].subIdentifier][field.config.rows[0].cols[colCount].identifier];
                        } else {
                            model = res[resultCount][field.config.rows[0].cols[colCount].identifier];
                        }

                        if (field.config.rows[0].cols[colCount].type !== 'output') {
                            const controlName = field.config.rows[0].cols[colCount].controlName,
                                observerData = this.setObserver(
                                controlName,
                                resultCount,
                                {},
                                model,
                                dataChangeService,
                                form,
                                data
                            );

                            form = observerData.form;
                            data = observerData.data;
                            dataChangeService = observerData.dataChangeService;
                            col.controlName = String(controlName);
                        }
                    }

                    if (field.config.rows[0].cols[colCount].type === 'conditional') {
                        let caseCount = 0;
                        const cases = [];
                        col.cases = field.config.rows[0].cols[colCount].cases;

                        for (caseCount; caseCount <= field.config.rows[0].cols[colCount].cases.length - 1; caseCount++) {
                            const caseCopy = Object.assign({}, field.config.rows[0].cols[colCount].cases[caseCount]);
                            if (caseCopy.type !== 'output') {
                                const conditionControlName = caseCopy.controlName,
                                    observerData = this.setObserver(
                                    conditionControlName,
                                    resultCount,
                                    {},
                                    res[resultCount][field.config.rows[0].cols[colCount].cases[caseCount].identifier],
                                    dataChangeService,
                                    form,
                                    data
                                );

                                form = observerData.form;
                                data = observerData.data;
                                dataChangeService = observerData.dataChangeService;
                                caseCopy.formControlName = String(conditionControlName);
                            }
                            cases.push(caseCopy);

                            if (caseCount === field.config.rows[0].cols[colCount].cases.length - 1) {
                                col.cases = cases;
                            }
                        }
                    }
                    cols.push(col);
                }

                rows.push({
                    'cols': cols
                });

                if (resultCount === res.length - 1) {
                    field.config.rows = rows;
                    data[modelName] = res;
                    return {
                        data: data,
                        form: form
                    };
                }
            }
        })());
    }

    setSingleModelData(event: any, config: any, dataChangeService: DataChangeService) {
        let singleModelCount = 0;

        for (singleModelCount; singleModelCount <= config.setSingleModel.length - 1; singleModelCount++) {
            const modelName = config.setSingleModel[singleModelCount].modelName,
                setModelName = config.setSingleModel[singleModelCount].setModelName;

            if (modelName === 'event') {
                dataChangeService.changeValue(setModelName, event);
            } else {
                if (typeof config.setSingleModel[singleModelCount].setSubModelName !== 'undefined') {
                    const setSubModelName = config.setSingleModel[singleModelCount].setSubModelName;
                    if (typeof config.setSingleModel[singleModelCount].subModelName !== 'undefined') {
                        const subModelName = config.setSingleModel[singleModelCount].subModelName;
                        const value = dataChangeService.getValue(modelName);
                        value[subModelName] = event[setModelName][setSubModelName];

                        dataChangeService.changeValue(modelName, value);
                    } else {
                        dataChangeService.changeValue(modelName, event[setModelName][setSubModelName]);
                    }
                } else {
                    dataChangeService.changeValue(modelName, event[setModelName]);
                }
            }

            if (singleModelCount === config.setSingleModel.length - 1) {
                return dataChangeService;
            }
        }
    }

    setObserver(
        controlName,
        controlAddition,
        model,
        existingModel = null,
        dataChangeService: DataChangeService,
        form: FormGroup,
        data: any
    ): any {
        const control = controlName + controlAddition;
        form.addControl(control, new FormControl(''));
        if (typeof existingModel === null) {
            dataChangeService.setValue(control, data[model.model][model.arrayPosition][model.subModel]);
        } else {
            dataChangeService.setValue(control, existingModel);
        }
        return {
            form: form,
            dataChangeService: dataChangeService,
            data: data
        };
    }
}
