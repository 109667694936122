import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class StorageService {
    private storageSub = new Subject<String>();

    watchStorage(): Observable<any> {
        return this.storageSub.asObservable();
    }

    setItem(key: string, data: any) {
        localStorage.setItem(key, data);
        this.storageSub.next(key);
    }

    removeItem(key) {
        localStorage.removeItem(key);
        this.storageSub.next(key);
    }

    getItem(key) {
        localStorage.getItem(key);
    }
}
