<div [formGroup]="form" *ngIf="showForm === true">
    <div class="row list--row">
        <div class="col-md-6 pln">
            <mat-form-field>
                <input matInput
                       placeholder="{{ 'NAME' | translate }}"
                       [(ngModel)]="model.name"
                       (change)="changeModel()"
                       formControlName="name"
                       value="{{model.name}}">
            </mat-form-field>

            <mat-form-field>
                <mat-select
                        [compareWith]="compareObjects"
                        placeholder="{{ 'SECTOR' | translate }}"
                        (change)="changeModel()"
                        [(ngModel)]="model.sector"
                        formControlName="sector">
                    <mat-option class="default--select-option" [value]="''">{{ 'PLEASE_SELECT' | translate }}</mat-option>
                    <mat-option class="select--option" *ngFor="let sector of sectors" [value]="sector">
                        <span>{{sector.name | translate}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="null !== model.sector && model.sector.id">
                <mat-select
                        [compareWith]="compareObjects"
                        placeholder="{{ 'FIELD' | translate }}"
                        [(ngModel)]="model.value"
                        (selectionChange)="changeTypeAndField()"
                        formControlName="value">
                    <mat-option class="default--select-option" [value]="''">{{ 'PLEASE_SELECT' | translate }}</mat-option>
                    <mat-option class="select--option" *ngFor="let field of model.sector.fields" [value]="field">
                        <span>{{field.name | translate}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-select
                        [compareWith]="compareName"
                        placeholder="{{ 'TYPE' | translate }}"
                        [(ngModel)]="model.type"
                        (selectionChange)="changeTypeAndField()"
                        formControlName="type">
                    <mat-option class="default--select-option" [value]="''">{{ 'PLEASE_SELECT' | translate }}</mat-option>
                    <mat-option class="select--option" *ngFor="let type of types" [value]="type">
                        <span>{{type | translate}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-6 prn">
            <app-description-headline
                    *ngIf="model.type !== ''"
                    [headline]="'CONFIGURATION'">
            </app-description-headline>

            <mat-form-field
                    *ngIf="model.type === 'sendMail'"
            >
                <input matInput
                       placeholder="{{ 'TEMPLATE' | translate }}"
                       [(ngModel)]="model.config.template"
                       (change)="changeModel()"
                       formControlName="configTemplate"
                       value="{{model.config.template}}">
            </mat-form-field>

            <mat-form-field
                    *ngIf="model.type === 'performAction'"
            >
                <mat-select
                        [compareWith]="compareClass"
                        placeholder="{{ 'CLASS' | translate }}"
                        (change)="changeModel()"
                        [(ngModel)]="model.config.class"
                        formControlName="configClass">
                    <mat-option class="default--select-option" [value]="''">{{ 'PLEASE_SELECT' | translate }}</mat-option>
                    <mat-option class="select--option" *ngFor="let class of model.sector.classes" [value]="class">
                        <span>{{class.name | translate}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field
                    *ngIf="model.type === 'performAction' && '' !== model.config.class"
            >
                <mat-select
                        [compareWith]="compareName"
                        placeholder="{{ 'ACTION' | translate }}"
                        (change)="changeModel()"
                        [(ngModel)]="model.config.action"
                        formControlName="configAction">
                    <mat-option class="default--select-option" [value]="''">{{ 'PLEASE_SELECT' | translate }}</mat-option>
                    <mat-option class="select--option" *ngFor="let function of model.config.class.functions" [value]="function">
                        <span>{{function.name | translate}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field
                    *ngIf="model.type === 'setNewValue'"
            >
                <mat-select
                        [compareWith]="compareName"
                        placeholder="{{ 'TYPE' | translate }}"
                        (change)="changeModel()"
                        [(ngModel)]="model.config.type"
                        formControlName="configType">
                    <mat-option class="default--select-option" [value]="''">{{ 'PLEASE_SELECT' | translate }}</mat-option>
                    <mat-option class="select--option" *ngFor="let type of setNewValueTypes" [value]="type">
                        <span>{{type | translate}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field
                    *ngIf="model.type === 'setNewValue'"
            >
                <mat-select
                        [compareWith]="compareName"
                        placeholder="{{ 'DIRECTION' | translate }}"
                        [(ngModel)]="model.config.decreaseDirection"
                        (change)="changeModel()"
                        formControlName="configDecreaseDirection">
                    <mat-option class="default--select-option" [value]="''">{{ 'PLEASE_SELECT' | translate }}</mat-option>
                    <mat-option class="select--option" *ngFor="let direction of directions" [value]="direction">
                        <span>{{direction | translate}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field
                    *ngIf="model.type === 'setNewValue' && classFields !== undefined && classFields !== null && classFields.length > 0"
            >
                <mat-select
                        [compareWith]="compareObjects"
                        placeholder="{{ 'CHANGE_IDENTIFIER' | translate }}"
                        [(ngModel)]="model.config.changeIdentifier"
                        (selectionChange)="changeChangeIdentifier()"
                        formControlName="configChangeIdentifier">
                    <mat-option class="default--select-option" [value]="''">{{ 'PLEASE_SELECT' | translate }}</mat-option>
                    <mat-option class="select--option" *ngFor="let field of classFields" [value]="field">
                        <span>{{field.name | translate}}</span>
                        <span class="color--grey" *ngIf="field.targetEntity !== null"> {{ 'IS_SUB_CLASS' | translate }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field
                    *ngIf="subClassFields !== null && model.config.type === 'setSubClass'"
            >
                <mat-select
                        [compareWith]="compareObjects"
                        placeholder="{{ 'SUB_CLASS_IDENTIFIER' | translate }}"
                        [(ngModel)]="model.config.subClassChangeIdentifier"
                        (change)="changeModel()"
                        formControlName="configSubClassChangeIdentifier">
                    <mat-option class="default--select-option" [value]="''">{{ 'PLEASE_SELECT' | translate }}</mat-option>
                    <mat-option class="select--option" *ngFor="let field of subClassFields" [value]="field">
                        <span>{{field.name | translate}}</span>
                        <span class="color--grey" *ngIf="field.targetEntity !== null"> {{ 'IS_SUB_CLASS' | translate }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div *ngIf="model.type !== ''">
                <app-description-headline
                        [headline]="'SETTINGS'">
                </app-description-headline>

                <div class="button-row">
                    <div (click)="addSetting()" class="mat-raised-button">
                        <i class="fas fa-plus"></i> {{ 'ADD_SETTING' | translate }}
                    </div>
                </div>

                <mat-accordion>
                    <mat-expansion-panel *ngFor="let setting of model.settings; let i = index;">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                ({{i + 1}}) - {{setting.name}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="mt-md row list--row">
                            <div class="col-md-6 pln">
                                <mat-form-field>
                                    <input matInput
                                           placeholder="{{ 'NAME' | translate }}"
                                           [(ngModel)]="setting.name"
                                           (change)="changeModel()"
                                           formControlName="setting-{{i}}-name"
                                           value="{{setting.name}}">
                                </mat-form-field>

                                <mat-form-field>
                                    <input matInput
                                           placeholder="{{ 'KEY' | translate }}"
                                           [(ngModel)]="setting.key"
                                           (change)="changeModel()"
                                           formControlName="setting-{{i}}-key"
                                           value="{{setting.key}}">
                                </mat-form-field>

                                <mat-form-field>
                                    <input matInput
                                           placeholder="{{ 'DEFAULT_VALUE' | translate }}"
                                           [(ngModel)]="setting.value"
                                           (change)="changeModel()"
                                           formControlName="setting-{{i}}-value"
                                           value="{{setting.value}}">
                                </mat-form-field>
                            </div>
                            <div class="col-md-6 prn">
                                <mat-form-field>
                                    <mat-select
                                            [compareWith]="compareName"
                                            placeholder="{{ 'TYPE' | translate }}"
                                            [(ngModel)]="setting.type"
                                            (change)="changeModel()"
                                            formControlName="setting-{{i}}-value">
                                        <mat-option class="default--select-option" [value]="''">{{ 'PLEASE_SELECT' | translate }}</mat-option>
                                        <mat-option class="select--option" *ngFor="let type of inputTypes" [value]="type">
                                            <span>{{type | translate}}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div *ngIf="setting.type === 'select'">
                                    <div class="button-row">
                                        <div (click)="addOption(i)" class="mat-raised-button">
                                            <i class="fas fa-plus"></i> {{ 'ADD_OPTION' | translate }}
                                        </div>
                                    </div>

                                    <div cdkDropList class="simple--drag-and-drop-list" (cdkDropListDropped)="dropOption($event, i)">
                                        <div class="row simple--drag-and-drop-list-element pn" *ngFor="let settingOption of setting.options; let optionIndex = index;" cdkDrag>
                                            <div class="pb-md pt-md pl-md fw">
                                                <mat-form-field>
                                                    <input matInput
                                                           placeholder="{{ 'OPTION_VALUE' | translate }}"
                                                           [(ngModel)]="setting.options[optionIndex]"
                                                           (change)="changeModel()"
                                                           formControlName="setting-{{i}}-option-{{optionIndex}}"
                                                           value="{{setting.options[optionIndex]}}">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>
</div>
