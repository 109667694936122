import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FileModalDialogComponent} from '../../FileModal/file.modal';
import {MatDialog} from '@angular/material/dialog';
import {BatchDeleteComponent} from '../../Table/_dialogs/batch.delete.component';
import {SystemService} from '../../../System/Service/system.service';

@Component({
    selector: 'app-attachment-list',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './attachment.list.component.html',
    styleUrls: ['../../../System/system.component.css']
})
export class AttachmentListComponent implements OnInit {
    @Input() placeholder: string;
    @Input() config: any;
    @Input() currentId: any;
    @Input() model: any;

    constructor(
        public dialog: MatDialog,
        private systemService: SystemService,
        private cd: ChangeDetectorRef,
        public translate: TranslateService
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
    }

    ngOnInit() {
    }

    remove(attachment) {
        const dialogRef = this.dialog.open(BatchDeleteComponent, {
            width: '80rem',
            data: {
                message: this.config.deleteMessage,
                data: attachment
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result !== false) {
                this.systemService.putCall(
                    {
                        'file': result,
                        'model': {
                            'id': this.currentId
                        }
                    },
                    this.config.deleteUrl
                ).subscribe(
                    res => {
                        this.model.filter(item => item === attachment);
                        if (this.model.indexOf(attachment) !== false) {
                            this.model.splice(this.model.indexOf(attachment), 1);
                            this.cd.detectChanges();
                        }
                    }
                );
            }
        });
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }

    openFileModal(src: string): void {
        const dialogRef = this.dialog.open(FileModalDialogComponent, {
            minWidth: '10rem',
            data: {
                file: src
            }
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }
}
