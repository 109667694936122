<form (keydown.enter)="handleEnterKeyPress($event)" *ngIf="showForm === true; else loading;" [formGroup]="quickEditForm" (ngSubmit)="save()">
    <div *ngIf="showEdit">
        <div *ngFor="let field of fields" class="row list--row">
            <div *ngIf="field.type === 'text'" class="col-md-12">
                <mat-form-field>
                    <input matInput
                           placeholder="{{ field.placeholder | translate }}"
                           [(ngModel)]="model[field.name]"
                           formControlName="{{field.formControlName}}"
                           value="{{model[field.name]}}">
                </mat-form-field>
            </div>
            <div *ngIf="field.type === 'select'" class="col-md-12">
                <mat-form-field>
                    <mat-select
                            [compareWith]="compareObjects"
                            placeholder="{{ 'COUNTRY' | translate }}" [(ngModel)]="model[field.name]"
                            [formControl]="formControls[field.modelName]"
                            required>
                        <mat-option>--</mat-option>
                        <mat-option *ngFor="let item of selectionValues[field.modelName]" [value]="item">
                            {{item.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="button-row">
            <button type="submit" mat-raised-button color="primary">
              <i   class="fas fa-save"></i> {{ 'SAVE' | translate }}
            </button>
        </div>
    </div>

    <div *ngIf="!showEdit" class="alert danger">
        {{ 'NOT_ALLOWED_MESSAGE' | translate }}
    </div>
</form>

<ng-template #loading>
  <app-loader-component></app-loader-component>
</ng-template>

