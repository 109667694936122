import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {FileModalDialogComponent} from '../FileModal/file.modal';
import {SystemService} from '../../System/Service/system.service';
import {CommentAddComponent} from './add/comment.add.component';
import {Subject} from 'rxjs';
import {CommentEditComponent} from './edit/comment.edit.component';
import {MatDialog} from '@angular/material/dialog';
import {DataChangeService} from '../../System/Service/data.change.service';
import {ManipulationService} from '../../System/Service/manipulation.service';

@Component({
    selector: 'app-comment',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './comment.component.html',
    styleUrls: ['../../System/system.component.css']
})
export class CommentComponent implements OnInit {
    @Input() currentId: number;
    @Input() config: any;
    @Input() isModal: Boolean = false;
    @Input() dataChangeService: DataChangeService;
    @Input() companies: any;
    @Input() changing: Subject<any>;
    @Output() addClick = new EventEmitter<boolean>();
    @Output() deleteClick = new EventEmitter<number>();
    locationString: string;
    currentUser: any;
    activeComment: number;

    comments: any[];

    constructor(
        public translate: TranslateService,
        public systemService: SystemService,
        public manipulatoinService: ManipulationService,
        public dialog: MatDialog,
        private cd: ChangeDetectorRef,
        private location: Location
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.locationString = this.location.path();
    }

    ngOnInit() {
        this.currentUser = JSON.parse(localStorage.getItem('user'));

        if (this.isModal === true && typeof this.dataChangeService !== 'undefined') {
            this.currentId = this.dataChangeService.getValue('id', '');
        }

        this.changing.subscribe(value => {
            this.systemService.getDataWithId(this.config.getUrl, this.currentId).subscribe(comments => {
                this.comments = comments;
                this.cd.detectChanges();
            });
        });
        this.systemService.getDataWithId(this.config.getUrl, this.currentId).subscribe(comments => {
            this.comments = comments;
            this.cd.detectChanges();
        });

        if (typeof this.config.companyGetUrl !== 'undefined') {
            this.systemService.getDataWithId(this.config.companyGetUrl, this.currentId).subscribe(companies => {
                if (typeof this.config.multipleCompanies !== 'undefined' && this.config.multipleCompanies === true) {
                    this.companies = companies;
                    this.cd.detectChanges();
                } else {
                    this.companies = [];

                    this.companies.push(companies);
                    this.cd.detectChanges();
                }
            });
        } else if (typeof this.config.useCompanyFromModel !== 'undefined' && this.config.useCompanyFromModel === true) {
            this.companies = this.dataChangeService.getValue('companies', []);

            if (this.dataChangeService.getValue('company') !== '') {
                this.companies.push(this.dataChangeService.getValue('company'));
            }
            this.cd.detectChanges();
        }

        this.activeComment = 0;
    }

    addClicked(comment: any = null) {
        this.manipulatoinService.getDataFromChangeService(this.dataChangeService).subscribe( data => {
            const dialogRef = this.dialog.open(CommentAddComponent, {
                minWidth: '80rem',
                data: {
                    config: this.config,
                    parent: comment,
                    companies: this.companies,
                    model: data
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                this.systemService.getDataWithId(this.config.getUrl, data.id).subscribe(comments => {
                    this.comments = comments;
                    this.cd.detectChanges();
                });
            });
        });
    }

    editClicked(comment: any = null) {
        this.manipulatoinService.getDataFromChangeService(this.dataChangeService).subscribe( data => {
            const dialogRef = this.dialog.open(CommentEditComponent, {
                minWidth: '80rem',
                data: {
                    config: this.config,
                    comment: comment,
                    companies: this.companies,
                    model: data
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                this.systemService.getDataWithId(this.config.getUrl, data.id).subscribe(comments => {
                    this.comments = comments;
                    this.cd.detectChanges();
                });
            });
        });
    }

    afterScroll(parent) {
        this.activeComment = parent;
    }

    deleteClicked(notice) {
        this.deleteClick.emit(notice);
    }

    openFileModal(file): void {
        const dialogRef = this.dialog.open(FileModalDialogComponent, {
            width: '80rem',
            maxWidth: '80vw',
            data: {
                file: file.src,
                extension: file.extension
            }
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }
}
