<div [formGroup]="form">
  <mat-form-field>
    <input matInput
           required
           placeholder="{{ 'OLD_PASSWORD' | translate }}"
           [(ngModel)]="model.oldPassword"
           formControlName="oldPassword"
           value="{{model.oldPassword}}"
           [type]="hidePassword ? 'password' : 'text'">
    <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' :
      'visibility'}}
    </mat-icon>
  </mat-form-field>

  <mat-form-field>
    <input matInput
           required
           placeholder="{{ 'NEW_PASSWORD' | translate }}"
           [(ngModel)]="model.newPassword"
           formControlName="newPassword"
           value="{{model.newPassword}}"
           [type]="hidePassword ? 'password' : 'text'">
    <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' :
      'visibility'}}
    </mat-icon>
  </mat-form-field>

  <mat-form-field>
    <input matInput
           required
           placeholder="{{ 'CONFIRM_PASSWORD' | translate }}"
           [(ngModel)]="model.confirmPassword"
           [errorStateMatcher]="matcher"
           formControlName="confirmPassword"
           value="{{model.confirmPassword}}"
           [type]="hidePassword ? 'password' : 'text'">
    <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{hidePassword ? 'visibility_off' :
      'visibility'}}
    </mat-icon>
  </mat-form-field>
</div>
