import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DataChangeService} from '../../../System/Service/data.change.service';

@Component({
    selector: 'app-description-text',
    templateUrl: './description.text.component.html',
    styleUrls: ['../../../System/system.component.css']
})
export class DescriptionTextComponent implements OnInit {
    @Input() config: any;
    @Input() dataChangeService: DataChangeService;

    public model: any;

    constructor(
        public translate: TranslateService
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.createForm();
    }

    ngOnInit() {
        if (typeof this.config.identifier !== 'undefined') {
            this.dataChangeService.values[this.config.identifier].subscribe(result => {
                this.model = result;
            });
        }
    }

    createForm() {
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }
}
