import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  FormBuilder,
  FormControl, FormGroup
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {SystemService} from '../../../System/Service/system.service';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {DataChangeService} from '../../../System/Service/data.change.service';

@Component({
    selector: 'app-search-select',
    templateUrl: './search.select.component.html',
    styleUrls: ['../../../System/system.component.css']
})
export class SearchSelectComponent implements OnInit {
    showFilterSearch: boolean;
    data: any;
    @Input() config: any;
    @Input() dataChangeService: DataChangeService;
    @Output() afterSelect = new EventEmitter<number>();
    searchSelectForm: FormGroup;
    searchField: FormControl = new FormControl();
    public model: any;

    constructor(
        private fb: FormBuilder,
        private systemService: SystemService,
        public translate: TranslateService,
        private cd: ChangeDetectorRef,
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.createForm();
    }

    createForm() {
        this.searchSelectForm = this.fb.group({});
    }

    ngOnInit() {
        this.showFilterSearch = false;
        if (typeof this.config.identifier !== 'undefined') {
            this.dataChangeService.values[this.config.identifier].subscribe(result => {
                this.model = result;
            });
        }

        if (typeof this.config.getParameters === 'undefined') {
            this.searchField.valueChanges
                .pipe(
                    debounceTime(250),
                    distinctUntilChanged(),
                    switchMap((query) => this.systemService.getSearchResults(this.config.url, query))
                )
                .subscribe(result => {
                    this.data = result.results;
                    this.showFilterSearch = true;
                    this.cd.detectChanges();
                });
        } else {
            let parameterCount = 0;
            const parameters = [];

            for (parameterCount; parameterCount <= this.config.getParameters.length - 1; parameterCount++) {
                const parameter = this.config.getParameters[parameterCount];

                if (parameter.type === 'subModel') {
                    const addParameter = {};

                    if (typeof parameter.parameterIdentifier !== 'undefined') {
                        addParameter[parameter.parameterIdentifier] = this.dataChangeService.values[parameter.subModelIdentifier]._value;
                    } else {
                        addParameter[parameter.subModelIdentifier] = this.dataChangeService.values[parameter.subModelIdentifier]._value;
                    }
                    parameters.push(addParameter);
                }

                if (parameterCount === this.config.getParameters.length - 1) {
                    this.searchField.valueChanges
                        .pipe(
                            debounceTime(250),
                            distinctUntilChanged(),
                            switchMap((query) => this.systemService.getSearchResultsWithParameter(this.config.url, query, parameters))
                        )
                        .subscribe(result => {
                            this.data = result.results;
                            this.showFilterSearch = true;
                            this.cd.detectChanges();
                        });
                }
            }
        }
    }

    afterDataSet(data: any) {
      this.afterSelect.emit(data);
    }

    setResult(event) {
        this.toggleSearch();
        this.afterDataSet(event);
    }

    toggleSearch(): void {
        if (this.showFilterSearch === false) {
          this.searchField.setValue('');
          this.cd.detectChanges();
          this.data = [];
        }

        this.showFilterSearch = !this.showFilterSearch;
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }

    checkShowOutput(): boolean {
        return (
            undefined !== this.model &&
            this.model !== '' &&
            this.model !== null &&
            (
                undefined === this.config.showOutput ||
                this.config.showOutput === true
            )
        );
    }
}
