<div class="button-row">
    <div (click)="compareDistributors()" class="mat-raised-button">
        <i class="fas fa-arrow-left"></i> {{ 'COMPARE_DISTRIBUTORS' | translate }}
    </div>
</div>

<div class="table--section" *ngIf="undefined !== distributorInfos">
    <table class="table table-hover mtxl" ng-table="tableParams">
        <thead class="table--head">
            <tr>
                <td class="table--head--cell">
                    {{ 'NAME' | translate }}
                </td>
                <td class="table--head--cell">
                    {{ 'PRICE' | translate }}
                </td>
                <td class="table--head--cell">
                    {{ 'QUALITY' | translate }}
                </td>
                <td class="table--head--cell">
                    {{ 'IN_TIME' | translate }}
                </td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let distributorInfo of distributorInfos"
                (click)="setDistributor(distributorInfo)"
                class="table--row"
                [ngClass]="{'is--active': selectedDistributor.distributor.id === distributorInfo.distributor.id}">
                <td class="table--row--cell">
                    {{distributorInfo.distributor.name}}
                </td>
                <td class="table--row--cell">
                    <i class="material-icons" *ngIf="distributorInfo.priceRecommendation === true">
                        done
                    </i>
                    <i class="material-icons" *ngIf="distributorInfo.priceRecommendation === false">
                        clear
                    </i>
                    <span *ngIf="distributorInfo.price !== 'NO_PRICE_COMPARE_POSSIBLE'">
                        ({{distributorInfo.price}} €)
                    </span>
                </td>
                <td class="table--row--cell">
                    <i class="material-icons" *ngIf="distributorInfo.qualityRecommendation === true">
                        done
                    </i>
                    <i class="material-icons" *ngIf="distributorInfo.qualityRecommendation === false">
                        clear
                    </i>
                </td>
                <td class="table--row--cell">
                    <i class="material-icons" *ngIf="distributorInfo.inTimeRecommendation === true">
                        done
                    </i>
                    <i class="material-icons" *ngIf="distributorInfo.inTimeRecommendation === false">
                        clear
                    </i>
                </td>
            </tr>
        </tbody>
    </table>
</div>