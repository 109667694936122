import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';

@Injectable()
export class MediaService {
    constructor(
        private apiService: ApiService
    ) {
    }

    uploadPurchaseInvoice(fileItem: File): Observable<any> {
        return this.apiService.uploadFile(fileItem, 'be/api/finance/purchase/invoice/upload');
    }
}
