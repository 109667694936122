import 'hammerjs';
import 'mousetrap';
import {LOCALE_ID, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {JwtModule} from '@auth0/angular-jwt';

import {JwtInterceptor, SystemComponent} from './System/system.component';
import {SharedModule} from './shared.module';
import {AppRoutingModule} from './app-routing.module';
import {ApiService} from '../config/api.service';
import {MediaService} from '../config/media.service';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {APP_DATE_FORMATS, AppDateAdapter} from './System/Adapter/date.adapter';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {SnackBarService} from './BaseComponents/Snackbar/snackbar.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {NavService} from './BaseComponents/Navigation/Service/nav.service';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {DataChangeService} from './System/Service/data.change.service';
import {CheckForUpdateService} from './System/Service/check.for.update.service';
import {MultiTranslateHttpLoader} from './System/Service/multi-http-loader';
import {StorageService} from './System/Service/storage.service';

export function tokenGetter() {
    return localStorage.getItem('currentUser');
}

registerLocaleData(localeDe);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new MultiTranslateHttpLoader(http, [
        {prefix: './assets/i18n/', suffix: '.json'},
    ]);
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: ['https://api.storysh.de'],
                disallowedRoutes: []
            }
        }),
        SharedModule
    ],
    declarations: [
        SystemComponent
    ],
    exports: [
    ],
    providers: [
        ApiService,
        NavService,
        SnackBarService,
        MediaService,
        DataChangeService,
        StorageService,
        CheckForUpdateService,
        {provide: LOCALE_ID, useValue: 'de'},
        {provide: DateAdapter, useClass: AppDateAdapter},
        {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true}
    ],
    bootstrap: [SystemComponent]
})
export class AppModule {
}
