import {Component, Inject, Injectable, NgZone, OnInit} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';
import {MatDialog} from '@angular/material/dialog';
import {ErrorDetailsComponent} from './detail/error.details.component';

@Injectable()
export class SnackBarService {
    constructor(
        public snackBar: MatSnackBar,
        public translate: TranslateService,
        private zone: NgZone
    ) {
    }

    open(mode = 'success', action = 'success', message = '', trace = null) {
        const config = new MatSnackBarConfig();
        let duration = 5000;

        if (mode === 'error') {
            duration = 10000000;
        }

        if (typeof message === 'undefined') {
            message = '';
        }

        if (message !== '') {
            this.translate.get(
                message
            ).subscribe((res: string) => {
                config.duration = duration;
                config.panelClass = [mode];
                this.zone.run(() => {
                    this.snackBar.openFromComponent(SnackbarComponent, {
                        duration: duration,
                        panelClass: mode,
                        data: {
                            message: message,
                            trace: trace
                        }
                    });
                });
            });
        }
    }
}

@Component({
    selector: 'app-snackbar-component',
    templateUrl: './snackbar.component.html',
    styleUrls: ['../../System/system.component.css']
})
export class SnackbarComponent implements OnInit {
    constructor(
        public snackBarRef: MatSnackBarRef<SnackbarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any,
        public dialog: MatDialog,
        public translate: TranslateService
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
    }

    ngOnInit() {}

    showDetails() {
        const dialogRef = this.dialog.open(ErrorDetailsComponent, {
            minWidth: '60rem',
            data: {
                trace: this.data.trace,
                message: this.data.message
            }
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }
}
