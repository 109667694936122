import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormControl, FormGroup
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {SystemService} from '../../../System/Service/system.service';
import * as BalloonEditor from '../../libs/ckeditor-balloon-mentions/build/ckeditor';

@Component({
    selector: 'app-editor',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './editor.component.html',
    styleUrls: ['../../../System/system.component.css']
})
export class EditorComponent implements OnInit {
    public Editor = BalloonEditor;
    data: any[];
    editorConfig: any;
    @Input() model: any;
    @Input() controlName: string;
    @Input() mention: boolean = false;
    public form: FormGroup;
    public control: FormControl;
    @Output() editorChange = new EventEmitter<any>();

    constructor(
        private fb: FormBuilder,
        private cd: ChangeDetectorRef,
        private systemService: SystemService,
        private controlContainer: ControlContainer,
        public translate: TranslateService
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.createForm();
    }

    createForm() {
    }

    ngOnInit() {
        this.form = <FormGroup>this.controlContainer.control;
        this.control = <FormControl>this.form.get(this.controlName);
        if (this.mention === true) {
            this.systemService.getFeedUsers().subscribe(result => {
                this.editorConfig = {
                    mention: {
                        feeds: [
                            {
                                marker: '@',
                                feed: result,
                                minimumCharacters: 1
                            }
                        ]
                    }
                };
                this.cd.detectChanges();
            });
        } else {
            this.editorConfig = {};
            this.cd.detectChanges();
        }
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }

    changeValue(value: any) {
        this.editorChange.emit(value);
    }
}
