import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {User} from '../Model/user';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    public userSubject: BehaviorSubject<User>;

    constructor(private http: HttpClient, private router: Router) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    login(username: string, password: string) {
        return this.http.post<any>(`https://api.storysh.de/be_login`, {
            'username': username,
            'password': password
        })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.userSubject.next(user);

                    return user;
                }
            }));
    }

    logout(navigate = true) {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.userSubject.next(null);

        if (navigate === true) {
            this.router.navigateByUrl('/login');
        } else {
            window.location.href = '/login';
        }
        return false;
    }
}
