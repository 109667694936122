import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'tableOutputPipe'
})
export class TableOutputPipe implements PipeTransform {
    public formatItems = [
        'price'
    ];

    transform(data, attribute) {
        return this.getRenderedOutput(data, attribute);
    }

    getRenderedOutput(data, attribute): any {
        if (typeof data[attribute] === 'boolean') {
            if (data[attribute] === false) {
                return '<i class="material-icons">' +
                    'clear' +
                '</i>';
            }
            return '<i class="material-icons">' +
                'done' +
            '</i>';
        }

        if (data[attribute] === 'COST' || data[attribute] === 'GAIN') {
            if (data[attribute] === 'COST') {
                return '<i class="material-icons">' +
                    'trending_down' +
                '</i>';
            }
            return '<i class="material-icons">' +
                'trending_up' +
            '</i>';
        }

        if (attribute === 'description' || attribute === 'teaser' || data[attribute].length > 25) {
            return data[attribute].substring(0, 25) + '...';
        }

        if (!this.isString(data[attribute]) && this.shouldBeFormatted(attribute)) {
            return new Intl.NumberFormat().format(data[attribute]);
        }

        if (!this.isString(data[attribute]) && !this.shouldBeFormatted(attribute)) {
            if (typeof data[attribute] !== 'undefined' && data[attribute] !== null) {
                return data[attribute].toString();
            }
        }

        return data[attribute];
    }

    shouldBeFormatted(attribute: string) {
        return this.formatItems.includes(attribute);
    }

    isString(obj: any) {
        return typeof obj === 'string';
    }
}
