import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {
    ControlContainer,
    FormControl, FormGroup,
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {SystemService} from '../../../System/Service/system.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {DataChangeService} from '../../../System/Service/data.change.service';
import {ManipulationService} from '../../../System/Service/manipulation.service';

@Component({
    selector: 'app-process-action',
    templateUrl: './process.action.component.html',
    styleUrls: ['../../../System/system.component.css']
})
export class ProcessActionComponent implements OnInit {
    @Input() dataChangeService: DataChangeService;
    @Output() change = new EventEmitter<any>();

    public model: any;
    public showForm: boolean;
    public form: FormGroup;
    public sectors: any;
    public subClassFields: any;
    public classFields: any;

    public types = ['update', 'setNewValue', 'sendMail', 'performAction'];
    public setNewValueTypes = ['setSubClass', 'setStaticValue', 'setDynamicValue'];
    public directions = ['straight', 'reverse'];
    public inputTypes = ['text', 'select'];

    constructor(
        private controlContainer: ControlContainer,
        public manipulationService: ManipulationService,
        private cd: ChangeDetectorRef,
        public systemService: SystemService,
        public translate: TranslateService
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.createForm();
    }

    createForm() {
    }

    ngOnInit() {
        this.showForm = false;
        this.form = <FormGroup>this.controlContainer.control;
        this.subClassFields = null;

        this.manipulationService.getDataFromChangeService(this.dataChangeService).subscribe(model => {
            this.model = model;

            this.systemService.getData('/be/api/process/sectors/get').subscribe(sectors => {
                this.sectors = sectors;
                this.cd.detectChanges();

                if (this.model.name === '') {
                    this.showForm = true;
                    this.cd.detectChanges();
                } else {
                    if (this.model.sector !== '') {
                        for (const sector of this.sectors) {
                            if (sector.id === this.model.sector.id) {
                                this.model.sector = sector;
                            }
                        }
                    }

                    const settingLength = this.model.settings.length;
                    let settingCount = 0;

                    for (settingCount; settingCount <= settingLength - 1; settingCount++) {
                        this.form.addControl('setting-' + settingCount + '-type', new FormControl(''));
                        this.form.addControl('setting-' + settingCount + '-name', new FormControl(''));
                        this.form.addControl('setting-' + settingCount + '-key', new FormControl(''));
                        this.form.addControl('setting-' + settingCount + '-value', new FormControl(''));

                        if (settingCount === settingLength - 1) {
                            this.showForm = true;
                            this.cd.detectChanges();
                        }
                    }

                    if (settingLength === 0) {
                        this.showForm = true;
                        this.cd.detectChanges();
                    }
                }
            });
        });
    }

    compareName(n1: string, n2: string): boolean {
        return n1 === n2;
    }

    compareClass(o1: any, o2: string): boolean {
        return o1.id === o2;
    }

    compareObjects(o1: any, o2: any): boolean {
        return o1.id === o2.id;
    }

    changeTypeAndField(): void {
        if (this.model.value !== '' && this.model.type === 'setNewValue') {
            this.systemService.getWithUrlAndParameters('/be/api/process/sectors/subclass/fields', this.model.value).subscribe(subClassFields => {
                this.classFields = subClassFields;
                this.cd.detectChanges();
            });
        }
    }

    changeChangeIdentifier(): void {
        if (this.model.config.changeIdentifier.targetEntity !== null) {
            this.model.config.subClass = this.model.config.changeIdentifier.targetEntity;

            this.systemService.getWithUrlAndParameters('/be/api/process/sectors/subclass/fields', this.model.config.changeIdentifier).subscribe(subClassFields => {
                this.subClassFields = subClassFields;
                this.cd.detectChanges();
            });
        } else {
            this.subClassFields = null;
        }
    }

    addSetting(): void {
        const index = this.model.settings.length;

        this.form.addControl('setting-' + index + '-type', new FormControl(''));
        this.form.addControl('setting-' + index + '-name', new FormControl(''));
        this.form.addControl('setting-' + index + '-key', new FormControl(''));
        this.form.addControl('setting-' + index + '-value', new FormControl(''));

        this.model.settings.push(
            {
                'type': '',
                'options': [],
                'name': '',
                'key': '',
                'value': ''
            }
        );
    }

    addOption(settingIndex): void {
        const index = this.model.settings[settingIndex].options.length;

        this.form.addControl('setting-' + settingIndex + '-option-' + index, new FormControl(''));

        this.model.settings[settingIndex].options.push('');
    }

    dropOption(event: CdkDragDrop<string[]>, settingIndex: number) {
        moveItemInArray(this.model.settings[settingIndex].options, event.previousIndex, event.currentIndex);
    }

    changeModel(): void {
        const model = {...this.model};
        this.change.emit(model);
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }
}
