<div [formGroup]="form">
    <div class="row list--row" *ngIf="placeholder !== ''">
        <div class="col-md-12 column--padding">
            <h3 class="description--headline">{{ placeholder | translate }}</h3>
        </div>
    </div>
    <div class="row list--row" *ngFor="let date of getData(); let i = index; trackBy: trackByFunction">
        <div class="{{config.colClass}} column--padding" *ngFor="let field of config.fields">
            <app-field
                *ngIf="field.type !== 'output'"
                [placeHolder]="field.placeholder"
                (onChange)="onFieldValueChange($event, field, i)"
                [placeholderAddition]="date[field.placeholderAddition]"
                [model]="fieldValue(field, i)"
                [controlName]="field.formControlName + i"
                [autoComplete]="field.autoComplete"
                [selections]="selections[field.selectionIdentifier]"
                [value]="fieldValue(field, i)"
                [disabled]="field.disabled"
                [config]="field.config"
                [dataChangeService]="dataChangeService"
                [type]="field.type"
            ></app-field>

            <span *ngIf="field.type === 'output'">
                {{fieldValue(field, i)}}
            </span>
        </div>
    </div>
</div>
