<div [formGroup]="form">
    <ckeditor
        *ngIf="undefined !== editorConfig"
        [(ngModel)]="model"
        [formControlName]="controlName"
        (change)="changeValue(model)"
        [config]="editorConfig"
        [editor]="Editor">
    </ckeditor>
</div>
