<div class="button-row" [class.pl-xxl]="navOpen === false" *ngIf="undefined === config.showAdd || config.showAdd == true">
    <button routerLink="{{config.addLink}}" type="submit" mat-raised-button color="primary" *ngIf="showEdit && undefined === config.routeToAdd || config.routeToAdd == true">
        <i class="fas fa-save"></i> {{ 'ADD' | translate }}
    </button>
    <div (click)="addClicked()" class="mat-raised-button" *ngIf="config.routeToAdd == false">
        <i class="fas fa-save"></i> {{ 'ADD' | translate }}
    </div>

    <button (click)="importClicked()" *ngIf="undefined !== config.showImport && config.showImport == true && showEdit"
            mat-raised-button color="primary">
        <i class="fas fa-plus"></i> {{ 'IMPORT' | translate }}
    </button>
</div>

<div class="filter--row row" *ngIf="showRead">
    <div class="col-sm-3">
        <mat-form-field *ngIf="filters.length > 0 && undefined === config.showFilter">
            <mat-select placeholder="{{ 'FILTER' | translate }}"
                        [(ngModel)]="filter"
                        [required]="false"
                        (selectionChange)="setQueryFilter($event)"
                        [formControl]="filterControl"
                        required>
                <mat-option>--</mat-option>
                <mat-option *ngFor="let filter of filters; trackBy: trackByFunction" [value]="filter">
                    <span *ngIf="isString(filter.name)">{{ filter.name | translate }}</span>
                    <span
                        *ngIf="!isString(filter.name)">{{ filter.name.key | translate }} {{ filter.name.value }}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-sm-3">
        <mat-form-field *ngIf="undefined !== sortings && sortings.length > 0">
            <mat-select placeholder="{{ 'SORTING' | translate }}"
                        [(ngModel)]="sorting"
                        [required]="false"
                        (selectionChange)="setQuerySorting($event)"
                        [formControl]="sortingControl"
                        required>
                <mat-option>--</mat-option>
                <mat-option *ngFor="let sorting of sortings; trackBy: trackByFunction" [value]="sorting">
                    {{sorting.name | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-sm-3">
        <form (keydown.enter)="handleEnterKeyPress($event)" *ngIf="undefined === config.showSearch || config.showSearch === true" [formGroup]="searchForm">
            <mat-form-field>
                <input matInput
                       [ngModel]="search"
                       autocomplete="off"
                       placeholder="{{ 'SEARCH' | translate }}"
                       [formControl]="searchField">
            </mat-form-field>
        </form>
    </div>
    <div class="col-sm-3 filter--row-cell">
        <div (click)="clearFilter()"
             *ngIf="undefined === config.showSearch || config.showSearch === true || undefined !== sortings || filters.length > 0 && undefined === config.showFilter"
             class="mat-raised-button mat-primary right">
            <i class="far fa-times-circle"></i> {{ 'CLEAR_FILTER' | translate }}
        </div>

        <div (click)="exportClicked()"
             *ngIf="undefined !== config.showExport && config.showExport == true"
             class="mat-raised-button right">
            <i class="fas fa-download"></i> {{ 'EXPORT' | translate }}
        </div>
    </div>
</div>

<div *ngIf="config.showSelectionRow === true">
    <div class="batch--row row"
         [class.batch--row-active]="selectedItems.length > 0"
         *ngIf="config.deleteSelected == true || config.batchProcessing == true || undefined !== config.deleteSelected && undefined !== config.batchProcessing && showRead">
        <button (click)="deleteSelectedClicked(selectedItems)"
                *ngIf="undefined !== config.deleteSelected && config.deleteSelected == true"
                mat-raised-button color="primary">
            <i class="fas fa-minus"></i> {{ 'DELETE' | translate }}
        </button>

        <button (click)="batchClicked(selectedItems)"
                *ngIf="undefined !== config.batchProcessing && config.batchProcessing == true"
                mat-raised-button color="primary">
            <i class="fas fa-stream"></i> {{ 'BATCH_PROCESSING' | translate }}
        </button>
    </div>
</div>

<div *ngIf="showRead; else notAllowed">
    <div *ngIf="undefined !== paginator; else loading">
        <section class="table--section">
            <form (keydown.enter)="handleEnterKeyPress($event)" [formGroup]="tableForm">
                <table class="table table-hover mtxl" ng-table="tableParams">
                    <thead class="table--head">
                    <tr>
                        <td class="table--head--cell select--cell" scope="col"
                            *ngIf="undefined !== config.showIndication && config.showIndication == true">
                        </td>
                        <td class="table--head--cell select--cell" scope="col"
                            *ngIf="undefined !== config.batchProcessing && config.batchProcessing == true || undefined !== config.deleteSelected && config.deleteSelected == true">
                            <mat-checkbox
                                    formControlName="selectAllItems"
                                    (change)="switchTableSelects($event)"
                                    [(ngModel)]="selectAllItems">
                            </mat-checkbox>
                        </td>
                        <td class="table--head--cell" scope="col" *ngIf="config.showLogo === true">
                        </td>
                        <td class="table--head--cell" scope="col" *ngFor="let header of headers; trackBy: trackByFunction">
                            {{ header | translate }}
                        </td>
                        <td class="table--head--cell" scope="col">
                        </td>
                    </tr>
                    </thead>
                    <tbody *ngIf="paginator.data.length > 0">
                        <tr *ngFor="let data of paginator.data;" class="table--row" [ngClass]="{'is--active': in_array(data[config.selectionSelector]) && config.showSelection}">
                            <td class="table--row--cell select--cell" scope="row"
                                *ngIf="undefined !== config.showIndication && config.showIndication == true">
                                <div class="table--indicator indicator-{{data.indicator}}"></div>
                            </td>
                            <td class="table--row--cell  select--cell" scope="row"
                                *ngIf="undefined !== config.batchProcessing && config.batchProcessing == true || undefined !== config.deleteSelected && config.deleteSelected == true">
                                <mat-checkbox
                                        formControlName="selectItem-{{data.id}}"
                                        (change)="switchTableRow($event, data)"
                                        [(ngModel)]="data.selected">
                                </mat-checkbox>
                            </td>
                            <td class="table--row--cell image--cell" *ngIf="config.showLogo === true">
                                <img class="list--item-image" *ngIf="undefined === data.thumbnail" src='{{data.logo}}'>
                                <img class="list--item-image" *ngIf="undefined !== data.thumbnail" src='{{data.thumbnail}}'>
                            </td>
                            <td class="table--row--cell">
                                <div *ngIf="(undefined === config.showEdit || config.showEdit === true)
                                    && (undefined === config.routeToEdit || config.routeToEdit == true)"
                                >
                                    <a routerLink="{{config.editLink}}{{data.id}}">
                                        <strong>{{data.name | translate}}</strong>
                                    </a>
                                </div>
                                <div *ngIf="undefined === config.showEdit && config.showEdit === false">
                                    <a routerLink="{{config.editLink}}{{data.id}}">
                                        <strong>{{data.name | translate}}</strong>
                                    </a>
                                </div>
                                <div *ngIf="config.showIndication === true">
                                    <strong>{{data.name | translate}}</strong>
                                </div>
                            </td>
                            <td class="table--row--cell" *ngFor="let attribute of attributes; trackBy: trackByFunction">
                                <span innerHTML="{{ data | tableOutputPipe:attribute | translate }}">
                                </span>
                            </td>
                            <td class="table--row--cell action--cell">
                                <div class="list--action-row">
                                    <div class='list--button text-center action--row-button'
                                         *ngIf="undefined === config.showEdit || config.showEdit === true">
                                        <div *ngIf="undefined === config.routeToEdit || config.routeToEdit == true">
                                            <a routerLink="{{config.editLink}}{{data.id}}">
                                                <i class="fas fa-pen-square" aria-hidden="true"></i>
                                            </a>
                                        </div>
                                        <div *ngIf="config.routeToEdit == false">
                                            <i *ngIf="undefined === config.routeAfterEdit" (click)="editClicked(data)" class="fas fa-pen-square" aria-hidden="true"></i>
                                            <i *ngIf="undefined !== config.routeAfterEdit" (click)="editRouteClicked(data, config.editLink + data.id)" class="fas fa-pen-square" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div class='list--button text-center action--row-button'
                                         *ngIf="config.showComment">
                                        <a routerLink="{{config.commentLink}}{{data.id}}">
                                            <i class="fas fa-comment-dots" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                    <div class='list--button text-center action--row-button'
                                         *ngIf="config.showSelection">
                                        <i class="fas fa-{{config.selectionIcon}}" (click)="selectionClicked(data)" aria-hidden="true"></i>
                                    </div>
                                    <div class='list--button text-center action--row-button'
                                         *ngIf="config.showDelete && showEdit == true">
                                        <a routerLink="{{config.deleteLink}}{{data.id}}">
                                            <i class="fas fa-minus" aria-hidden="true"></i>
                                        </a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </section>

        <ul *ngIf="paginator.pages" class="pagination">
            <li class="page-item" [ngClass]="{disabled:paginator.page === 1}">
                <a class="page-link" (click)="setFirstPage()">
                    <i class="fas fa-fast-backward"></i>
                </a>
            </li>
            <li class="page-item" [ngClass]="{disabled:paginator.page === 1}">
                <a class="page-link" (click)="decreasePage()">
                    <i class="fas fa-step-backward"></i>
                </a>
            </li>
            <li class="page-item select--item">
                <mat-form-field>
                    <mat-select (selectionChange)="setPage($event)" [(ngModel)]="paginator.page">
                        <mat-option [value]="paginator.pages.start">
                            {{paginator.pages.start}}
                        </mat-option>
                        <mat-option *ngFor="let preStep of paginator.pages.preSteps; trackBy: trackByFunction" [value]="preStep">
                            {{preStep}}
                        </mat-option>
                        <mat-option *ngFor="let postStep of paginator.pages.mainSteps; trackBy: trackByFunction" [value]="postStep">
                            {{postStep}}
                        </mat-option>
                        <mat-option *ngFor="let postStep of paginator.pages.postSteps; trackBy: trackByFunction" [value]="postStep">
                            {{postStep}}
                        </mat-option>
                        <mat-option [value]="paginator.pages.end">
                            {{paginator.pages.end}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </li>
            <li class="page-item" [ngClass]="{disabled:paginator.page === paginator.pages.length}">
                <a class="page-link" (click)="increasePage()">
                    <i class="fas fa-step-forward"></i>
                </a>
            </li>
            <li class="page-item" [ngClass]="{disabled:paginator.page === paginator.pages.length}">
                <a class="page-link" (click)="setLastPage()">
                    <i class="fas fa-fast-forward"></i>
                </a>
            </li>
            <li class="page-item overview--item count--overview">
                {{paginator.range.from}} - {{paginator.range.to}} {{ 'FROM' | translate }} {{paginator.absolute}}
            </li>
            <li class="page-item overview--item paginated--table-selection-label">
                <strong>{{ 'ROWS_PER_PAGE' | translate }}</strong>
            </li>
            <li class="page-item select--item pagination--select-margin">
                <mat-form-field>
                    <mat-select (selectionChange)="setLimit($event)" [(ngModel)]="query.limit">
                        <mat-option *ngFor="let listLimit of config.paginationLimits; trackBy: trackByFunction"
                                    [value]="listLimit">{{listLimit}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </li>
            <li class="page-item overview--item paginated--table-selection-label" *ngIf="undefined === config.showAlphabetical">
                <strong>{{ 'ALPHABETICAL' | translate }}</strong>
            </li>
            <li class="page-item select--item pagination--select-margin" *ngIf="undefined === config.showAlphabetical">
                <mat-form-field>
                    <mat-select (selectionChange)="setAlphabeticalFilter($event)" [(ngModel)]="query.alphabetical">
                        <mat-option *ngFor="let letter of alphabet; trackBy: trackByFunction"
                                    [value]="letter">
                            {{ letter | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </li>
        </ul>
    </div>
</div>

<ng-template #loading>
    <app-loader-component></app-loader-component>
</ng-template>
<ng-template #notAllowed>
    <div class="alert danger">
        {{ 'NOT_ALLOWED_MESSAGE' | translate }}
    </div>
</ng-template>
