import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injectable,
    OnInit,
    Output
} from '@angular/core';
import {ApiService} from '../../config/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';
import {debounceTime, distinctUntilChanged, finalize, switchMap, tap} from 'rxjs/operators';
import {AuthenticationService} from './Service/authentification.service';
import {TranslateService} from '@ngx-translate/core';
import {FormControl} from '@angular/forms';
import {SystemService} from './Service/system.service';
import {GlobalSearchComponent} from '../BaseComponents/Fields/GlobalSearch/global.search.component';
import {MatDialog} from '@angular/material/dialog';
import {QuickEditComponent} from '../BaseComponents/QuickEdit/quick.edit.component';
import {User} from './Model/user';
import {StorageService} from './Service/storage.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthenticationService,
        private jwtHelper: JwtHelperService,
        private router: Router
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        // add authorization header with jwt token if available
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }

        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {

                }
            }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 403 || err.status === 401) {
                        this.authService.logout();
                    }
                }
            })
        );
    }
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-root',
    templateUrl: './system.component.html',
    styleUrls: ['./system.component.css'],
    host: {
      '(document:keydown)': 'onKeyPress($event)'
    }
})
export class SystemComponent implements OnInit {
    @Output() sidenavClose = new EventEmitter();
    title = 'app';
    sidenavOpened: boolean;
    loading: boolean;
    loggedIn: boolean;
    opened: boolean;
    showSearch: boolean;
    currentUser: any;
    showContent: any;
    user: User;
    searchResults: any[];
    restItemsUrl = '/be/api/user/current/get';
    searchField: FormControl = new FormControl();

    constructor(
        private apiService: ApiService,
        private _router: Router,
        private route: ActivatedRoute,
        public translate: TranslateService,
        private authService: AuthenticationService,
        private storageService: StorageService,
        private systemService: SystemService,
        public dialog: MatDialog,
        private cd: ChangeDetectorRef
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.showContent = _router.url;
        this.user = this.authService.userValue;
    }

    ngOnInit() {
        this.loading = true;
        this.showSearch = false;
        localStorage.setItem('paginationCurrentPage', '1');
        this.opened = true;

        if (localStorage.getItem('currentUser') !== null || typeof this.currentUser !== 'undefined') {
            this.getCurrentUserData().subscribe(result => {
                this.loggedIn = true;
                this.sidenavOpened = true;
                this.loading = false;
                this.cd.detectChanges();

                this.authService.userSubject.subscribe(user => {
                    this.currentUser = user;
                    this.cd.detectChanges();
                });
            });
            this.searchField.valueChanges
                .pipe(
                    debounceTime(1000),
                    distinctUntilChanged(),
                    switchMap((query) => this.systemService.getGlobalSearchResults(query))
                )
                .subscribe(result => {
                    this.searchResults = result;
                    this.cd.detectChanges();
                });
        } else {
            this.loggedIn = false;
            this.authService.logout();
        }
    }

    public onSidenavClose = () => {
        this.sidenavOpened = !this.sidenavOpened;
        this.storageService.setItem('navOpen', this.sidenavOpened);
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }

    onKeyPress(event: any) {
        const srcElement = event.srcElement;

        if (srcElement.tagName !== 'INPUT' && srcElement.tagName !== 'TEXTAREA' && srcElement.tagName !== 'DIV') {
            if (event.key === '.') {
                const dialogRef = this.dialog.open(GlobalSearchComponent, {
                    width: '80rem',
                    data: {
                    }
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (typeof result !== 'undefined') {
                      this._router.routeReuseStrategy.shouldReuseRoute = () => false;
                      this._router.navigate([result]);
                    }
                });
            }
            if (event.key === ',' && this._router.url.includes('edit')) {
                const url = this._router.url.substr(1);

                this.dialog.open(QuickEditComponent, {
                    width: '50rem',
                    data: {
                        mode: url.substr(0, url.indexOf('/')),
                        id: url.substr(url.lastIndexOf('/') + 1)
                    }
                });
            }
        }
    }

    getCurrentUserData(): Observable<boolean> {
        return new Observable(observer => {
            this.apiService.restCall(this.restItemsUrl)
                .subscribe(
                    currentUser => {
                        this.currentUser = currentUser;
                        localStorage.setItem('user', JSON.stringify(currentUser));
                        // this._router.navigateByUrl(this.route.snapshot.queryParams['returnUrl'] || '/dashboard');
                        observer.next(true);
                    },
                    error => {
                        if (error.status === 401) {
                            this.logout();
                        }
                    }
                );
        });
    }

    logout(): void {
        delete this.currentUser;
        this.authService.logout(false);
    }
}
