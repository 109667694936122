import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {
  FormBuilder,
  FormControl, FormGroup, Validators
} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {SystemService} from '../../System/Service/system.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {User} from '../../System/Model/user';

export interface QuickEditComponentDialogData {
    mode: string;
    id: string;
}

@Component({
    selector: 'app-quick-edit',
    templateUrl: './quick.edit.component.html',
    styleUrls: ['../../System/system.component.css']
})
export class QuickEditComponent implements OnInit {
    model: any[];
    showForm: boolean;
    saveCall: string;
    currentUser: User;
    showEdit: boolean;
    fields: any[] = [];
    formControls: FormControl[] = [];
    selectionValues: any[] = [];
    quickEditForm: FormGroup;

    constructor(
        private fb: FormBuilder,
        public translate: TranslateService,
        private systemService: SystemService,
        private cd: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: QuickEditComponentDialogData
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.createForm();
    }

    createForm() {
        this.quickEditForm = this.fb.group({});
    }

    ngOnInit() {
        this.currentUser = JSON.parse(localStorage.getItem('user'));
        this.showForm = false;
        this.showEdit = this.currentUser.userGroup.settings['edit_customers'].active;

        if (this.showEdit) {
            this.systemService.getQuickEditData(this.data.mode, this.data.id).subscribe(result => {
                let i = 0;
                this.saveCall = result.saveCall;
                this.model = result.model;
                this.fields = result.fields;

                for (i; i <= result.fields.length - 1; i++) {
                    if (typeof result.fields[i].valueCall === 'undefined') {
                        this.quickEditForm.addControl(
                            result.fields[i].formControlName, new FormControl('')
                        );
                    } else {
                        this.formControls[result.fields[i].modelName] = new FormControl('', [Validators.required]);
                        this.getSelectionValues(result.fields[i].valueCall, result.fields[i].modelName).then(
                            res => {
                                this.cd.detectChanges();
                            }
                        );
                    }

                    if (i === result.fields.length - 1) {
                        this.showForm = true;
                        this.cd.detectChanges();
                    }
                }
            });
        } else {
            this.showForm = true;
        }
    }

    async getSelectionValues(call: string, modelName: string): Promise<any> {
        return Promise.resolve((() => {
            this.systemService.getWithUrl(call).subscribe(result => {
                this.selectionValues[modelName] = result;
            });
            return true;
        })());
    }

    compareObjects(o1: any, o2: any): boolean {
        return o1.name === o2.name && o1.id === o2.id;
    }

    save(): void {
        this.systemService.putCall(this.model, this.saveCall).subscribe(result => {
        });
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }
}
