<div *ngIf="undefined !== config; else loading">
    <h2 class="mat-headline description--headline modal--headline">{{ config.headline | translate }}</h2>

    <div *ngIf="config.type !== 'stepper' && config.type !== 'accordion'">
        <form (keydown.enter)="handleEnterKeyPress($event)" *ngIf="showEdit; else notAllowed" [formGroup]="form">
            <div *ngIf="showForm === true; else loading;">
                <div class="{{row.rowClass}}" *ngFor="let row of config.rows">
                    <div *ngFor="let col of row.cols" class="{{col.colClass}}">
                        <div *ngFor="let field of col.fields">
                            <div *ngIf="field.type === 'buttonRow'">
                                <div class="button-row">
                                <span *ngFor="let button of field.buttons">
                                  <div *ngIf="button.type === 'pushToModel'" (click)="pushToModel(button.config)" class="mat-raised-button">
                                      <i class="{{button.iconClass}}"></i> {{ button.text | translate }}
                                  </div>
                                  <div *ngIf="button.type === 'link'" (click)="openLink(button.config)" class="mat-raised-button">
                                      <i class="{{button.iconClass}}"></i> {{ button.text | translate }}
                                  </div>
                                </span>
                                </div>
                            </div>

                            <app-field
                                    *ngIf="showBaseField(field)"
                                    [placeHolder]="field.placeholder"
                                    [model]="fieldValue(field)"
                                    [controlName]="field.formControlName"
                                    [autoComplete]="field.autoComplete"
                                    [value]="fieldValue(field)"
                                    [selections]="getSelections(field)"
                                    [config]="field.config"
                                    [valueType]="field.valueType"
                                    [disabled]="field.disabled"
                                    [dataChangeService]="dataChangeService"
                                    (onChange)="onValueChange($event, field.config)"
                                    [type]="field.type"
                                    [fileArray]="fileArray(field)"
                            ></app-field>

                            <div class="row" *ngIf="field.type === 'pdfPreview' && undefined !== models">
                                <app-pdf-preview-component
                                        [path]="models[field.modelName]">
                                </app-pdf-preview-component>
                            </div>

                            <div *ngIf="field.type === 'chips'">
                                <mat-chip-list>
                                    <mat-chip color="primary" selected *ngFor="let chip of field.config.chips">
                                        {{ chip.headline | translate }}
                                    </mat-chip>
                                </mat-chip-list>
                            </div>

                            <div *ngIf="field.type === 'singleAccordion'">
                                <mat-accordion *ngIf="undefined !== models">
                                    <mat-expansion-panel>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <span class="pl-md">
                                                    {{field.config.header | translate}}
                                                </span>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>

                                        <div class="accordion--content">
                                            <div *ngFor="let accordionField of field.config.fields">
                                                <app-field
                                                        *ngIf="showBaseField(accordionField)"
                                                        [placeHolder]="accordionField.placeholder"
                                                        [model]="fieldValue(accordionField)"
                                                        [controlName]="accordionField.formControlName"
                                                        [autoComplete]="accordionField.autoComplete"
                                                        [value]="fieldValue(accordionField)"
                                                        [selections]="undefined === accordionField.subSelectionIdentifier ? selections[accordionField.selectionIdentifier] : models[accordionField.selectionIdentifier][accordionField.subSelectionIdentifier]"
                                                        [config]="accordionField.config"
                                                        [valueType]="accordionField.valueType"
                                                        [disabled]="accordionField.disabled"
                                                        (onChange)="onValueChange($event, accordionField.config)"
                                                        [type]="accordionField.type"
                                                        [fileArray]="fileArray(accordionField)"
                                                ></app-field>
                                            </div>
                                        </div>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>

                            <app-file-upload
                                    *ngIf="field.type === 'fileUpload'"
                                    [config]="field.config"
                                    [currentId]="currentId"
                                    (onUploadFinished)="onUploaded(field.config, $event)"
                                    [fileArray]="fileArray(field)">
                            </app-file-upload>

                            <div
                                class="image--preview"
                                *ngIf="
                                    undefined === field.undefinedModel &&
                                    field.type === 'image'"
                            >
                                <img src="{{fieldValue(field)}}">
                            </div>

                            <div *ngIf="undefined !== models && field.type === 'warnDistributorRequest'">
                                <app-warn-distributor-request
                                        [form]="form"
                                        (noClick)="onNoClick()"
                                        [companies]="selections['companies']"
                                        [users]="selections['users']"
                                        [model]="models">
                                </app-warn-distributor-request>
                            </div>

                            <div *ngIf="field.type === 'loop'">
                                <app-loop
                                        [config]="field.config"
                                        [selections]="selections"
                                        [placeholder]="field.config.headline"
                                        [dataChangeService]="dataChangeService">
                                </app-loop>
                            </div>

                            <div *ngIf="undefined !== models && field.type === 'projectTemplateStructure'">
                                <app-project-template-structure
                                        [form]="form"
                                        (change)="onValueChange($event, field.config)"
                                        [data]="models">
                                </app-project-template-structure>
                            </div>

                            <div *ngIf="undefined !== models && field.type === 'attachmentList'">
                                <app-attachment-list
                                        [placeholder]="field.placeholder"
                                        [config]="field.config"
                                        [currentId]="models['id']"
                                        [model]="fieldValue(field, [])">
                                </app-attachment-list>
                            </div>

                            <div *ngIf="undefined !== models && field.type === 'actionTable'">
                                <app-action-table
                                        [dataChangeService]="dataChangeService"
                                        [form]="form"
                                        (onFieldChange)="onBlur($event, models[field.identifier], field.config)"
                                        (addRowClick)="addRow(models[field.identifier], field.config)"
                                        (completeSelection)="onCompleteSelection(models[field.identifier], field.config, $event)"
                                        [config]="field.config">
                                </app-action-table>
                            </div>

                            <div *ngIf="field.type === 'sendDocument'">
                                <app-send-document-dialog-component
                                        [dataChangeService]="dataChangeService"
                                        [contactBase]="contactBase(field)"
                                        [config]="field.config"
                                >
                                </app-send-document-dialog-component>
                            </div>

                            <div *ngIf="undefined !== models && field.type === 'resetPassword'">
                                <app-change-password
                                        [model]="models"
                                >
                                </app-change-password>
                            </div>

                            <div *ngIf="undefined !== models && field.type === 'userGroupRights'">
                                <app-user-group-rights
                                        [model]="models"
                                >
                                </app-user-group-rights>
                            </div>

                            <div *ngIf="field.type === 'processAction'">
                                <app-process-action
                                    (change)="onValueChange($event, field.config)"
                                    [dataChangeService]="dataChangeService"
                                >
                                </app-process-action>
                            </div>

                            <div *ngIf="field.type === 'paginatedTable'">
                                <app-paginated-table
                                        [config]="field.listConfig"
                                        [headers]="field.headers"
                                        [sortings]="field.sortings"
                                        [filters]="field.filters"
                                        [setFilterData]="field.setFilterData"
                                        [preselectedFilter]="field.preselectedFilter"
                                        (selectionClick)="paginatedTableSelectionClick($event)"
                                        [attributes]="field.attributes"
                                ></app-paginated-table>
                            </div>

                            <div *ngIf="field.type === 'productVariant'">
                                <app-add-product-variant
                                        [activeRouteId]="data.activeRouteId"
                                        (onSelectVariationValue)="variantSelection($event, field.config)">
                                </app-add-product-variant>
                            </div>

                            <div *ngIf="field.type === 'headline'">
                                <app-description-headline
                                        [headline]="field.headline"
                                        [config]="field.config"
                                >
                                </app-description-headline>
                            </div>

                            <div *ngIf="field.type === 'distributorCompare'">
                                <app-distributor-compare
                                        (selectionClick)="paginatedTableSelectionClick($event)"
                                        [position]="singleModel">
                                </app-distributor-compare>
                            </div>

                            <div *ngIf="field.type === 'offerPosition'">
                                <app-offer-position
                                        [config]="field.config"
                                        (afterCalculate)="onValueChange($event, field.config)"
                                        [data]="singleModel">
                                </app-offer-position>
                            </div>

                            <div *ngIf="field.type === 'selectList'">
                                <app-select-list
                                        (afterSelect)="simpleAfterSelect($event, field.identifier, field.afterSelectConfig)"
                                        [identifier]="field.identifier"
                                        [dataChangeService]="dataChangeService"
                                        [config]="field.config"
                                        [controlName]="field.formControlName"
                                        [selections]="selections[field.selectionIdentifier]"
                                        [placeholder]="field.placeholder">
                                </app-select-list>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="button-row no--icon-margin" *ngIf="undefined !== config.buttonRow">
            <span *ngFor="let button of config.buttonRow">
                <button type="submit" (click)="save()" *ngIf="button.type === 'submit'" mat-raised-button color="primary">
                    <i class="{{button.iconClass}}"></i> {{ button.text | translate }}
                </button>
                <div *ngIf="button.type === 'back'" (click)="closeDialog()" class="mat-raised-button">
                    <i class="{{button.iconClass}}"></i> {{ button.text | translate }}
                </div>
                <div *ngIf="button.type === 'modalOpen'" (click)="openModal(button.config)" class="mat-raised-button">
                    <i class="{{button.iconClass}}"></i> {{ button.text | translate }}
                </div>
                <div *ngIf="button.type === 'deleteWithUrl'" (click)="deleteWithUrl(button.url)" class="mat-raised-button delete--button">
                    <i class="{{button.iconClass}}"></i> {{ button.text | translate }}
                </div>
                <div *ngIf="button.type === 'pushToModel'" (click)="pushToModel(button.config)" class="mat-raised-button">
                    <i class="{{button.iconClass}}"></i> {{ button.text | translate }}
                </div>
            </span>
            </div>
        </form>
    </div>

    <div *ngIf="config.type === 'stepper'">
        <div *ngIf="showEdit; else notAllowed">
            <app-stepper-component
                    (addClick)="onNoClick()"
                    [config]="config"
                    [model]="data"
                    [steps]="config.steps">
            </app-stepper-component>
        </div>
    </div>

    <div *ngIf="config.type === 'accordion'">
        <form (keydown.enter)="handleEnterKeyPress($event)" *ngIf="showEdit; else notAllowed" [formGroup]="form">
            <div *ngIf="showForm === true; else loading;">
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let row of config.rows">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                            <span class="pl-md">
                                {{row.headline | translate}}
                            </span>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="accordion--content">
                            <div class="{{row.rowClass}}">
                                <div *ngFor="let col of row.cols" class="{{col.colClass}}">
                                    <div *ngFor="let field of col.fields">
                                        <div *ngIf="field.type === 'buttonRow'">
                                            <div class="button-row">
                                            <span *ngFor="let button of field.buttons">
                                                <div *ngIf="button.type === 'pushToModel'" (click)="pushToModel(button.config)" class="mat-raised-button">
                                                    <i class="{{button.iconClass}}"></i> {{ button.text | translate }}
                                                </div>
                                                <div *ngIf="button.type === 'link'" (click)="openLink(button.config)" class="mat-raised-button">
                                                    <i class="{{button.iconClass}}"></i> {{ button.text | translate }}
                                                </div>
                                            </span>
                                            </div>
                                        </div>

                                        <app-field
                                                *ngIf="showBaseField(field)"
                                                [placeHolder]="field.placeholder"
                                                [model]="fieldValue(field)"
                                                [controlName]="field.formControlName"
                                                [autoComplete]="field.autoComplete"
                                                [value]="fieldValue(field)"
                                                [selections]="getSelections(field)"
                                                [config]="field.config"
                                                [currentId]="currentId"
                                                [valueType]="field.valueType"
                                                [disabled]="field.disabled"
                                                (onChange)="onValueChange($event, field.config)"
                                                [type]="field.type"
                                                [dataChangeService]="dataChangeService"
                                                [fileArray]="fileArray(field)"
                                        ></app-field>

                                        <div *ngIf="field.type === 'simpleTable'">
                                            <app-simple-table
                                                    *ngIf="undefined !== field.config.model"
                                                    [dataChangeService]="dataChangeService"
                                                    [config]="field.config"
                                                    [isModal]="true"
                                                    (deleteClick)="deleteEntry($event, field.deleteConfig)"
                                                    (editClick)="openModal(field.editConfig, $event)"
                                                    [headers]="field.headers"
                                                    [attributes]="field.attributes"
                                            ></app-simple-table>
                                        </div>

                                        <app-file-upload
                                                *ngIf="field.type === 'fileUpload'"
                                                [config]="config"
                                                [currentId]="currentId"
                                                (onUploadFinished)="onUploaded(field.config, $event)"
                                                [fileArray]="fileArray(field)">
                                        </app-file-upload>

                                        <div *ngIf="undefined !== models && field.type === 'warnDistributorRequest'">
                                            <app-warn-distributor-request
                                                    [form]="form"
                                                    [companies]="selections['companies']"
                                                    [users]="selections['users']"
                                                    [model]="models">
                                            </app-warn-distributor-request>
                                        </div>

                                        <div *ngIf="undefined !== models && field.type === 'loop'">
                                            <app-loop
                                                    [config]="field.config"
                                                    [selections]="selections"
                                                    [placeholder]="field.config.headline"
                                                    [dataChangeService]="dataChangeService">
                                            </app-loop>
                                        </div>

                                        <div *ngIf="undefined !== models && field.type === 'attachmentList'">
                                            <app-attachment-list
                                                    [config]="field.config"
                                                    [currentId]="currentId"
                                                    [placeholder]="field.placeholder"
                                                    [model]="models[field.identifier]">
                                            </app-attachment-list>
                                        </div>

                                        <div *ngIf="undefined !== models && field.type === 'actionTable'">
                                            <app-action-table
                                                    [dataChangeService]="dataChangeService"
                                                    [form]="form"
                                                    (onFieldChange)="onBlur($event, models[field.identifier], field.config)"
                                                    (addRowClick)="addRow(models[field.identifier], field.config)"
                                                    (completeSelection)="onCompleteSelection(models[field.identifier], field.config, $event)"
                                                    [config]="field.config">
                                            </app-action-table>
                                        </div>

                                        <div *ngIf="undefined !== models && field.type === 'sendDocument'">
                                            <app-send-document-dialog-component
                                                    [model]="models"
                                                    [config]="field.config"
                                            >
                                            </app-send-document-dialog-component>
                                        </div>

                                        <div *ngIf="undefined !== models && field.type === 'resetPassword'">
                                            <app-change-password
                                                    [model]="models"
                                            >
                                            </app-change-password>
                                        </div>

                                        <div *ngIf="undefined !== models && field.type === 'userGroupRights'">
                                            <app-user-group-rights
                                                    [model]="models"
                                            >
                                            </app-user-group-rights>
                                        </div>

                                        <div *ngIf="field.type === 'paginatedTable'">
                                            <app-paginated-table
                                                    [config]="field.listConfig"
                                                    [headers]="field.headers"
                                                    [sortings]="field.sortings"
                                                    [filters]="field.filters"
                                                    [setFilterData]="field.setFilterData"
                                                    [preselectedFilter]="field.preselectedFilter"
                                                    (selectionClick)="paginatedTableSelectionClick($event)"
                                                    [attributes]="field.attributes"
                                            ></app-paginated-table>
                                        </div>

                                        <div *ngIf="field.type === 'productVariant'">
                                            <app-add-product-variant
                                                    [activeRouteId]="data.activeRouteId"
                                                    (onSelectVariationValue)="variantSelection($event, field.config)">
                                            </app-add-product-variant>
                                        </div>

                                        <div *ngIf="field.type === 'headline'">
                                            <app-description-headline
                                                    [headline]="field.headline"
                                                    [config]="field.config"
                                            >
                                            </app-description-headline>
                                        </div>

                                        <div *ngIf="field.type === 'distributorCompare'">
                                            <app-distributor-compare
                                                    (selectionClick)="paginatedTableSelectionClick($event)"
                                                    [position]="singleModel">
                                            </app-distributor-compare>
                                        </div>

                                        <div *ngIf="field.type === 'offerPosition'">
                                            <app-offer-position
                                                    (afterCalculate)="onValueChange($event, field.config)"
                                                    [data]="singleModel">
                                            </app-offer-position>
                                        </div>

                                        <div *ngIf="field.type === 'comments'">
                                            <app-comment
                                                    [currentId]="currentId"
                                                    [isModal]="true"
                                                    [dataChangeService]="dataChangeService"
                                                    [changing]="changingValue"
                                                    (deleteClick)="deleteEntry($event, field.deleteConfig)"
                                                    [config]="field.config"
                                            ></app-comment>
                                        </div>

                                        <div *ngIf="field.type === 'selectList'">
                                            <app-select-list
                                                    (afterSelect)="simpleAfterSelect($event, field.identifier, field.afterSelectConfig)"
                                                    [identifier]="field.identifier"
                                                    [dataChangeService]="dataChangeService"
                                                    [controlName]="field.formControlName"
                                                    [selections]="selections[field.selectionIdentifier]"
                                                    [placeholder]="field.placeholder">
                                            </app-select-list>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <div class="button-row no--icon-margin" *ngIf="undefined !== config.buttonRow">
            <span *ngFor="let button of config.buttonRow">
                <button type="submit" (click)="save()" *ngIf="button.type === 'submit'" mat-raised-button color="primary">
                    <i class="{{button.iconClass}}"></i> {{ button.text | translate }}
                </button>
                <div *ngIf="button.type === 'back'" (click)="closeDialog()" class="mat-raised-button">
                    <i class="{{button.iconClass}}"></i> {{ button.text | translate }}
                </div>
                <div *ngIf="button.type === 'modalOpen'" (click)="openModal(button.config)" class="mat-raised-button">
                    <i class="{{button.iconClass}}"></i> {{ button.text | translate }}
                </div>
                <div *ngIf="button.type === 'deleteWithUrl'" (click)="deleteWithUrl(button.url)" class="mat-raised-button delete--button">
                    <i class="{{button.iconClass}}"></i> {{ button.text | translate }}
                </div>
                <div *ngIf="button.type === 'pushToModel'" (click)="pushToModel(button.config)" class="mat-raised-button">
                    <i class="{{button.iconClass}}"></i> {{ button.text | translate }}
                </div>
            </span>
            </div>
        </form>
    </div>
</div>

<ng-template #loading>
    <app-loader-component></app-loader-component>
</ng-template>

<ng-template #notAllowed>
    <div class="alert danger">
        {{ 'NOT_ALLOWED_MESSAGE' | translate }}
    </div>
</ng-template>
