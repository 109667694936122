<div [formGroup]="globalSearchSelectForm" class="row list--row">
    <div class="col-md-12">
        <mat-form-field>
            <input matInput
                   autocomplete="off"
                   placeholder="{{ 'SEARCH' | translate }}"
                   [formControl]="searchField">
        </mat-form-field>
    </div>
    <div class="col-md-12" *ngIf="searchResults !== undefined">
        <div class="row list--row">
            <div class="col-md-6">
              <div class="row list--row">
                  <div class="col-md-6" *ngFor="let result of searchResults">
                      <h3 class="description--headline">
                          {{ result.headline | translate }}
                      </h3>
                      <a class="search--entry" *ngFor="let subResult of result.results" (click)="onSearchResultSelected(subResult, result.zone)">
                          <span *ngIf="undefined !== subResult.name">{{subResult.name}}</span>
                          <span *ngIf="undefined !== subResult.lastName"> {{subResult.lastName}}</span>
                          <span *ngIf="undefined !== subResult.number"> {{subResult.number}}</span>
                          <span *ngIf="undefined !== subResult.orderNumber"> {{subResult.orderNumber}}</span>
                      </a>
                  </div>
              </div>
            </div>
            <div class="col-md-6" *ngIf="undefined !== detailSearchResult">
                <h3 class="description--headline">{{detailSearchResult.name}}</h3>
                <mat-accordion *ngIf="undefined !== detailComponents">
                    <mat-expansion-panel *ngFor="let detailComponent of detailComponents">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{detailComponent.headline | translate}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div *ngIf="detailComponent.items.length > 0">
                            <a class="search--entry" *ngFor="let item of detailComponent.items">
                                {{item.name}}
                            </a>
                        </div>
                        <div *ngIf="detailComponent.items.length === 0" class="alert success">
                            <strong>{{ 'NO_VALUES_GIVEN' | translate }}</strong>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <div class="button-row">
                    <div (click)="routeToEntry(detailSearchResult.link)" class="mat-raised-button wp-button btn--action">
                        {{ 'TO_RESULT' | translate }}
                    </div>
                    <div (click)="openQuickEdit(detailSearchResult)" class="mat-raised-button wp-button btn--action">
                        {{ 'QUICK_EDIT' | translate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
