import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, EventEmitter,
    HostBinding,
    Input,
    OnInit, Output
} from '@angular/core';
import {SystemService} from '../../System/Service/system.service';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../System/Model/user';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Location} from '@angular/common';
import {DataChangeService} from '../../System/Service/data.change.service';
import {Subject} from 'rxjs';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-stepper-component',
    templateUrl: './stepper.component.html',
    styleUrls: ['../../System/system.component.css']
})
export class StepperComponent implements OnInit {
    @Input() config: any;
    @Input() model: any;
    @Input() steps: any[];
    @Output() addClick = new EventEmitter<any>();

    changingValue: Subject<any> = new Subject();
    forms: FormGroup[];

    @HostBinding('class.col-md-10') setClass: boolean;
    @HostBinding('class.content--section') setContentClass: boolean;

    selections: any[];
    additionalModels: any[];
    data: any[];
    singleModel: any[];
    currentUser: User;
    showForm: boolean;
    showEdit: boolean;
    showRead: boolean;

    constructor(
        private fb: FormBuilder,
        private cd: ChangeDetectorRef,
        public translate: TranslateService,
        public dialog: MatDialog,
        private systemService: SystemService,
        private dataChangeService: DataChangeService,
        private location: Location
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');

        this.createForm();
    }

    createForm() {
    }

    ngOnInit() {
        this.showForm = false;
        this.selections  = [];
        this.additionalModels  = [];

        this.setFormControls().then(res => {
            this.currentUser = JSON.parse(localStorage.getItem('user'));

            // every user can change the settings of the own account (except user rights)
            if (this.config.zone === 'myAccount') {
                this.showEdit = true;
                this.showRead = true;
            } else {
                this.showEdit = this.currentUser.userGroup.settings['edit_' + this.config.zone].active;
                this.showRead = this.currentUser.userGroup.settings['read_' + this.config.zone].active;
            }

            if (this.showEdit) {
                this.setSelections().then(selectionResult => {
                    this.singleModel = this.config.baseModel;

                    this.setDataChangeValues().then(result => {
                        this.showForm = true;
                        this.data = this.singleModel;

                        if (typeof this.model !== 'undefined') {
                            if (typeof this.model.config === 'undefined' || this.model.config.useModel === true) {
                                this.data = this.model;
                            }
                        }
                        this.cd.detectChanges();
                    });
                });
            }
        });
    }

    afterAdditionModelSelect(models, additionalModelName): void {
        this.additionalModels[additionalModelName] = models;
        this.changingValue.next(this.additionalModels[additionalModelName]);
    }

    async setSelections(): Promise<any> {
        return Promise.resolve((() => {
            this.config.selections.forEach(async (setting, index) => {
                this.systemService.getWithUrl(setting.getCall).subscribe(result => {
                    this.selections[setting.modelName] = result;
                    if (index === this.config.selections.length - 1) {
                        this.cd.detectChanges();
                    }
                });
            });

            return true;
        })());
    }

    async setDataChangeValues(): Promise<any> {
        return Promise.resolve((() => {
            const self = this;

            Object.keys(this.singleModel).forEach(function(key, index) {
                if (typeof self.singleModel[key] === 'string' || typeof self.singleModel[key] === 'number') {
                    self.dataChangeService.setValue(key, self.singleModel[key]);
                    self.dataChangeService.values[key].subscribe(result => {
                        self.singleModel[key] = result;
                    });
                } else if (typeof self.singleModel[key] === 'object') {
                    const modelKeyConstant = key;
                    let i = 0;

                    for (i; i <= self.singleModel[modelKeyConstant].length - 1; i++) {
                        let j = 0;
                        const row = i,
                            keys = Object.keys(self.singleModel[modelKeyConstant][i]);

                        for (j; j <= keys.length - 1; j++) {
                            const modelKey = keys[j];

                            self.dataChangeService.setValue(
                                key + '-' + modelKey, self.singleModel[modelKeyConstant][row][modelKey]
                            );
                            self.dataChangeService.values[modelKeyConstant + '-' + modelKey].subscribe(
                                result => {
                                    self.singleModel[modelKeyConstant][row][modelKey] = result;
                                }
                            );
                        }
                    }
                }

                if (self.singleModel[Object.keys(self.singleModel).length - 1] === key) {
                    return true;
                }
            });
        })());
    }

    goBack(): void {
        this.location.back();
    }

    async setFormControls(): Promise<any> {
        return Promise.resolve((() => {
            let i = 0;
            this.forms = [];
            for (i; i <= this.config.steps.length - 1; i++) {
                const step = this.config.steps[i];

                this.forms[step.formName] = this.fb.group({});
                if (typeof step.formControls !== 'undefined') {
                    step.formControls.forEach(async (control) => {
                        this.forms[step.formName].addControl(
                            control.name, new FormControl('')
                        );
                    });
                }

                if (i === this.config.steps.length - 1) {
                    return true;
                }
            }
        })());
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }

    save() {
        let putData;

        if (typeof this.data['model'] === 'undefined') {
            putData = this.data;
        } else {
            putData = this.data['model'];
        }

        if (Array.isArray(putData) && putData.length === 0) {
            putData = this.model.model;
        }

        this.systemService.putCall(putData, this.config.saveCall).subscribe(
            result => {
                this.addClick.emit(result);
            }
        );
    }
}
