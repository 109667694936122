import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../../config/api.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map, share} from 'rxjs/operators';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../System/Model/user';
import {ActivatedRoute} from '@angular/router';
import {StorageService} from '../../System/Service/storage.service';

@Component({
    selector: 'app-paginated-table',
    templateUrl: './paginated.table.component.html',
    styleUrls: ['../../System/system.component.css'],
    animations: [
        trigger('animationOption1', [
            state('open', style({
                height: '0px',
                padding: '0'
            })),
            state('close', style({
                height: 'auto',
                padding: '15px 0'
            })),
            transition('open => close', animate('300ms 0.5s ease-out')),
            transition('close => open', animate('300ms 0.5s ease-out'))
        ])
    ]
})
export class PaginatedTableComponent implements OnInit {
    paginator: any;
    alphabet: any[];
    querySearchParams: any;
    isUrlFilter: boolean;
    @Input() attributes: any[];
    @Input() headers: any[];
    @Input() preselectedFilter: any;
    @Input() filters: any[];
    @Input() setFilterData: any[];
    @Input() sortings: any[];
    @Input() config: {
        addLink: string,
        getUrl: string,
        editLink: string,
        paginatorModel: string,
        paginationLimits: any[],
        showLogo: boolean,
        showComment: boolean,
        setFilters: boolean,
        routeToAdd: boolean,
        showImport: boolean,
        batchProcessing: boolean,
        showIndication: boolean,
        deleteSelected: boolean,
        showAdd: boolean,
        showSelection: boolean,
        selectionSelector: string,
        commentLink: string,
        zone: string,
        showDelete: boolean,
        showFilter: boolean,
        showSearch: boolean,
        showAlphabetical: boolean,
        showExport: boolean,
        showEdit: boolean,
        showSelectionRow: boolean,
        selectionIcon: string,
        routeToEdit: boolean,
        routeAfterEdit: boolean,
        deleteLink: string
    };
    selectedItems: any[];
    selectAllItems: boolean;
    @Output() addClick = new EventEmitter<boolean>();
    @Output() importClick = new EventEmitter<any>();
    @Output() selectionClick = new EventEmitter<any>();
    @Output() deleteSelectedClick = new EventEmitter<any>();
    @Output() batchClick = new EventEmitter<any>();
    @Output() editRouteClick = new EventEmitter<any>();
    @Output() editClick = new EventEmitter<any>();
    @Output() exportClick = new EventEmitter<any>();
    public query = {
        page: 1,
        limit: 10,
        allFilters: [],
        allSortings: [],
        search: '',
        alphabetical: '',
        filter: '',
        sorting: ''
    };
    lastPage: number;
    filter: any;
    sorting: any;
    search: string;
    searchForm: FormGroup;
    currentUser: User;
    showEdit: boolean;
    navOpen: boolean;
    showRead: boolean;
    tableForm: FormGroup;
    searchField: FormControl = new FormControl();
    filterControl = new FormControl('', []);
    sortingControl = new FormControl('', []);

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        public translate: TranslateService,
        private storageService: StorageService,
        private cd: ChangeDetectorRef,
        private apiService: ApiService
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.createForm();
    }

    createForm() {
        this.searchForm = this.fb.group({
            searchField: '',
        });
        this.tableForm = this.fb.group({
            selectAllItems: '',
        });
    }

    ngOnInit() {
        if (typeof localStorage.getItem('navOpen') === 'undefined') {
            this.navOpen = true;
        } else {
            this.navOpen = (localStorage.getItem('navOpen') === 'true');
        }

        if (typeof localStorage.getItem('paginationCurrentPage') !== 'undefined') {
            this.query.page = +localStorage.getItem('paginationCurrentPage');
        }

        this.isUrlFilter = true;
        this.searchField.valueChanges
            .pipe(
                debounceTime(1000),
                distinctUntilChanged(),
                map(query => this.getSearchData(query))
            ).subscribe();
        this.query.allFilters = this.filters;
        this.query.allSortings = this.sortings;
        this.selectedItems = [];
        this.currentUser = JSON.parse(localStorage.getItem('user'));

        this.showEdit = this.currentUser.userGroup.settings['edit_' + this.config.zone].active;
        this.showRead = this.currentUser.userGroup.settings['read_' + this.config.zone].active;

        if (typeof this.config.showSelectionRow === 'undefined') {
            this.config.showSelectionRow = true;
        }

        if (this.showRead) {
            if (typeof this.preselectedFilter !== 'undefined') {
                this.query.filter = this.preselectedFilter.value;
            }

            this.alphabet = ['ALL', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

            this.route.queryParams.subscribe(params => {
                this.querySearchParams = params;
            });
            this.getData(this.query.page, this.query.limit, true);
        }

        this.storageService.watchStorage().subscribe((data: string) => {
            if (data === 'navOpen') {
                this.navOpen = (localStorage.getItem('navOpen') === 'true');
                this.cd.detectChanges();
            }
        });
    }

    getSearchData(query: string) {
        if (query !== '') {
            this.isUrlFilter = false;
            this.query.search = query;

            if (typeof localStorage.getItem('paginationCurrentPage') !== 'undefined') {
                this.getData(+localStorage.getItem('paginationCurrentPage'), this.query.limit);
            } else {
                this.getData(1, this.query.limit);
            }
        }
    }

    setQueryFilter(filter: any): void {
        this.query.filter = '';
        if (typeof filter.value !== 'undefined') {
            this.query.filter = filter.value.value;
        }
        this.isUrlFilter = false;
        this.getData(1, this.query.limit);
    }

    setQuerySorting(sorting: any): void {
        this.query.sorting = '';
        if (typeof sorting.value !== 'undefined') {
            this.query.sorting = sorting.value.value;
        }
        this.isUrlFilter = false;
        this.getData(1, this.query.limit);
    }

    clearFilter(): void {
        this.query = {
            page: this.query.page,
            limit: this.query.limit,
            allFilters: this.filters,
            allSortings: this.sortings,
            search: '',
            filter: '',
            alphabetical: '',
            sorting: ''
        };

        this.filter = {};
        this.sorting = {};
        this.search = '';

        this.getData(this.query.page, this.query.limit);
    }

    getData(page: number, limit: number, init: boolean = false, letter: string = '') {
        this.query.page = page;

        this.setQueryParams().then(res => {
            this.apiService.getWithParameters(this.config.getUrl, this.query).subscribe(
                result => {
                    if (this.config.setFilters === true && init === true) {
                        this.setFilters(result[this.config.paginatorModel]).then(data => {
                            this.setModel(result, init).then(
                                data => {
                                    this.cd.detectChanges();
                                }
                            );
                        });
                    } else {
                        this.setModel(result, init).then(
                            res => {
                                this.cd.detectChanges();
                            }
                        );
                    }
                }
            );
        });
    }

    async setQueryParams(): Promise<any> {
        return Promise.resolve((() => {
            if (this.isUrlFilter === true) {
                if (typeof this.querySearchParams['search'] !== 'undefined') {
                    this.query.search = this.querySearchParams['search'];
                }

                if (typeof this.querySearchParams['sorting'] !== 'undefined') {
                    let i = 0;

                    for (i; i <= this.sortings.length - 1; i++) {
                        if (this.sortings[i].value === this.querySearchParams['sorting']) {
                            this.query.sorting = this.querySearchParams['sorting'];
                        }
                    }
                }

                if (typeof this.querySearchParams['filter'] !== 'undefined') {
                    let i = 0;

                    for (i; i <= this.filters.length - 1; i++) {
                        if (this.filters[i].value === this.querySearchParams['filter']) {
                            this.query.filter = this.querySearchParams['filter'];
                        }
                    }
                }

                if (typeof this.querySearchParams['alphabetical'] !== 'undefined') {
                    let i = 0;

                    for (i; i <= this.alphabet.length - 1; i++) {
                        if (this.alphabet[i] === this.querySearchParams['alphabetical']) {
                            this.query.alphabetical = this.querySearchParams['alphabetical'];
                        }
                    }
                }
            }

            return true;
        })());
    }

    async setModel(result: any, init: boolean = false): Promise<any> {
        return Promise.resolve((() => {
            let i = 0;
            for (i; i <= result[this.config.paginatorModel].length - 1; i++) {
                this.tableForm.addControl(
                    'selectItem-' + result[this.config.paginatorModel][i].id, new FormControl('')
                );
            }
            this.query.page = result.page * 1;
            this.query.limit = result.limit * 1;
            this.lastPage = result.lastPage - 1;

            this.paginator = {
                data: result[this.config.paginatorModel],
                page: result.page * 1,
                pages: result.pages,
                absolute: result.absolute,
                range: result.range,
                limit: result.limit,
            };

            return true;
        })());
    }

    setFilters(data): Promise<any> {
        return Promise.resolve((() => {
            let i = 0, j = 0;

            for (i; i <= this.setFilterData.length - 1; i++) {
                const selectedData = [];
                const dataSet = this.setFilterData[i];

                for (j; j <= data.length - 1; j++) {
                    let k = 0;
                    const model = data[j];

                    if (selectedData.indexOf(model[dataSet['selector']]) === -1) {
                        if (model[dataSet['selector']] !== '') {
                            const parameters = [];
                            for (k; k <= dataSet['expression'].parameters.length - 1; k++) {
                                if (dataSet['expression'].parameters[k].value === 'VAR') {
                                    parameters.push({
                                        key: dataSet['expression'].parameters[k].key,
                                        value: model[dataSet['selector']]
                                    });
                                    continue;
                                }

                                parameters.push({
                                    key: dataSet['expression'].parameters[k].key,
                                    value: dataSet['expression'].parameters[k].value
                                });
                            }

                            this.filters.push({
                                name: {
                                    key: dataSet['displayName'],
                                    value: model[dataSet['selector']]
                                },
                                value: dataSet['valuePrefix'] + model[dataSet['selector']],
                                filterData: {
                                    ignoreManyToMany: true,
                                    expression: dataSet['expression'].key,
                                    parameters: parameters
                                }
                            });

                            selectedData.push(model[dataSet['selector']]);
                        }
                    }
                }
            }

            return true;
        })());
    }

    setFirstPage(): void {
        localStorage.setItem('paginationCurrentPage', '1');

        this.getData(1, this.query.limit);
    }

    addClicked() {
        this.addClick.emit(true);
    }

    setAlphabeticalFilter(letter): void {
        this.query.alphabetical = letter.value;

        if (this.query.alphabetical === 'ALL') {
            this.query.alphabetical = '';
        }
        this.isUrlFilter = false;
        this.getData(this.query.page, this.query.limit, false, this.query.alphabetical);
    }

    setLastPage(): void {
        localStorage.setItem('paginationCurrentPage', this.lastPage.toString());

        this.getData(
            this.lastPage,
            this.query.limit,
            false,
            this.query.alphabetical
        );
    }

    increasePage(): void {
        localStorage.setItem('paginationCurrentPage', (this.query.page + 1).toString());

        this.getData(
            this.query.page + 1,
            this.query.limit,
            false,
            this.query.alphabetical
        );
    }

    decreasePage(): void {
        localStorage.setItem('paginationCurrentPage', (this.query.page - 1).toString());

        this.getData(
            this.query.page - 1,
            this.query.limit,
            false,
            this.query.alphabetical
        );
    }

    setPage(page) {
        localStorage.setItem('paginationCurrentPage', page.value.toString());

        this.getData(
            page.value,
            this.query.limit,
            false,
            this.query.alphabetical
        );
    }

    setLimit(limit) {
        localStorage.setItem('paginationLimit', limit.value.toString());

        this.query.limit = limit.value;
        this.getData(
            this.query.page,
            this.query.limit,
            false,
            this.query.alphabetical
        );
    }

    importClicked() {
        this.importClick.emit();
    }

    selectionClicked(data: any) {
        if (this.selectedItems.length === 0) {
            this.selectedItems.push(data[this.config.selectionSelector]);
        } else {
            let i = 0,
                inArray = false;

            for (i; i <= this.selectedItems.length - 1; i++) {
                if (data[this.config.selectionSelector] === this.selectedItems[i]) {
                    inArray = true;
                    this.selectedItems.splice(i, 1);
                }
            }

            if (inArray === false) {
                this.selectedItems.push(data[this.config.selectionSelector]);
            }
        }

        this.selectionClick.emit(data);
    }

    deleteSelectedClicked(data: any) {
        this.deleteSelectedClick.emit(data);
    }

    batchClicked(data: any) {
        this.batchClick.emit(data);
    }

    exportClicked() {
        this.exportClick.emit();
    }

    editClicked(data: any) {
        this.editClick.emit(data);
    }

    editRouteClicked(data: any, url: string) {
        this.editRouteClick.emit({
            data: data,
            url: url
        });
    }

    switchTableRow(event, data): void {
        let i = 0;
        const length = this.selectedItems.length;

        data.selected = event.checked;

        if (length === 0 || event.checked === true) {
            this.selectedItems.push(data);
        } else {
            for (i; i <= length - 1; i++) {
                if (this.selectedItems[i].id === data.id) {
                    this.selectedItems.splice(i, 1);
                }
            }
        }
    }

    switchTableSelects(event: any): void {
        let i = 0;
        this.selectedItems = [];

        for (i; i <= this.paginator['data'].length - 1; i++) {
            this.paginator['data'][i].selected = event.checked;

            if (event.checked === true) {
                this.selectedItems.push(this.paginator['data'][i]);
            }
        }
    }

    trackByFunction(index, item) {
        return index;
    }

    isString(obj: any) {
        return typeof obj === 'string';
    }

    in_array(id): boolean {
        let i = 0;

        for (i; i <= this.selectedItems.length - 1; i++) {
            if (this.selectedItems[i] === id) {
                return true;
            }
        }

        return false;
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }
}
