<div class="description--text" *ngIf="undefined === config.outputs && undefined !== model">
    <p *ngIf="undefined === config.showFurtherInformation || config.showFurtherInformation === false">
        {{ model.name }}
    </p>
    <div *ngIf="undefined !== config.showFurtherInformation && config.showFurtherInformation === true">
        <p>
            <span *ngIf="undefined === config.showCompleteName || (undefined !== config.showCompleteName && config.showCompleteName === false)">{{model.name}}</span>
            <span *ngIf="undefined !== config.showCompleteName && config.showCompleteName === true">{{model.completeName}}</span>
        </p>
        <p>{{model.baseAddress}}</p>
        <p>{{model.zipCode}} - {{model.city}}</p>
    </div>
</div>

<div class="description--text" *ngIf="undefined !== config.outputs">
  <div *ngFor="let output of config.outputs">
    <p *ngIf="output.type === 'standard'">
      {{ model[output.modelName] | translate }}
    </p>
  </div>
</div>
