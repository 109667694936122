import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthGuard} from '../app/System/Guard/auth.guard';
import {ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {SnackBarService} from '../app/BaseComponents/Snackbar/snackbar.service';
import {catchError, finalize, map, mergeMap} from 'rxjs/operators';

@Injectable()
export class ApiService {
    routeSnapshot: ActivatedRouteSnapshot;
    stateSnapshot: RouterStateSnapshot;

    constructor(
        private _http: HttpClient,
        private authGuard: AuthGuard,
        private jwtHelper: JwtHelperService,
        private route: ActivatedRoute,
        private snackBar: SnackBarService,
        private router: Router
    ) {
        this.routeSnapshot = route.snapshot;
        this.stateSnapshot = router.routerState.snapshot;
    }

    restCall(url, data = {}): Observable<any> {
        const headers = new HttpHeaders();
        const user = JSON.parse(localStorage.getItem('currentUser'));

        url = 'https://api.storysh.de' + url;

        headers.append('Authorization', 'Bearer ' + user.token);
        headers.append('Content-Type', 'application/json; charset=utf-8');
        headers.append('Access-Control-Allow-Origin:', '*');

        return this._http.post(
            url,
            data,
            {headers: headers}
        ).pipe(
            catchError(this.handleError)
        );
    }

    restCallWithMessage(url, data = {}): Observable<any> {
        const headers = new HttpHeaders();
        const user = JSON.parse(localStorage.getItem('currentUser'));

        url = 'https://api.storysh.de' + url;

        headers.append('Authorization', 'Bearer ' + user.token);
        headers.append('Content-Type', 'application/json; charset=utf-8');
        headers.append('Access-Control-Allow-Origin:', '*');

        const response = this._http.post(url, data, {headers: headers}).pipe(
            catchError(this.handleError),
            map((res: Response) => {
                if (res['success'] === true) {
                  this.snackBar.open('success', 'CLOSE', 'SAVED_SUCCESSFUL');
                } else {
                  this.snackBar.open('error', 'CLOSE', res['message']);
                }

                return res;
            })
        );

        return response;
    }

    getWithParameters(url, data): Observable<any> {
        const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'});
        const user = JSON.parse(localStorage.getItem('currentUser'));

        url = 'https://api.storysh.de' + url;
        headers.append('Authorization', 'Bearer ' + user.token);

        const json = encodeURIComponent(JSON.stringify(data));
        let params = `data=${json}`;
        params = params.replace(/&nbsp;/g, ' ');

        return this._http.post(
            url,
            params,
            {headers: headers}
        ).pipe(
            catchError(this.handleError)
        );
    }

    post(url, data: FormData): Observable<any> {
        const headers = new HttpHeaders();

        url = 'https://api.storysh.de' + url;
        headers.append('Authorization', 'Bearer ' + localStorage.getItem('currentUser'));
        const user = JSON.parse(localStorage.getItem('currentUser'));
        headers.append('Authorization', 'Bearer ' + user.token);
        headers.append('Access-Control-Allow-Origin:', '*');

        return this._http.post(
            url,
            data,
            {
                headers: headers
            }
        ).pipe(
            catchError(this.handleError)
        );
    }

    put(url, data, showResponse = true): Observable<any> {
        const json = encodeURIComponent(JSON.stringify(data));
        let params = `data=${json}`;
        params = params.replace(/&nbsp;/g, ' ');
        const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'});
        const user = JSON.parse(localStorage.getItem('currentUser'));
        headers.append('Authorization', 'Bearer ' + user.token);
        url = 'https://api.storysh.de' + url;

        const response = this._http.post(url, params, {headers: headers}).pipe(
            catchError(this.handleError),
            map((res: Response) => {
                if (showResponse === true) {
                    if (res['success'] === true) {
                        this.snackBar.open('success', 'CLOSE', 'SAVED_SUCCESSFUL');
                    } else {
                        this.snackBar.open('error', 'CLOSE', res['message']);
                    }
                }

                return res;
            })
        );

        return response;
    }

    download(url, data): Observable<any> {
        const json = encodeURIComponent(JSON.stringify(data));
        let params = `data=${json}`;
        params = params.replace(/&nbsp;/g, ' ');
        const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'});
        const user = JSON.parse(localStorage.getItem('currentUser'));
        headers.append('Authorization', 'Bearer ' + user.token);
        url = 'https://api.storysh.de' + url;

        return this._http.post(url, params, {
            responseType: 'blob',
            headers: headers
        });
    }

    uploadFile(fileItem: File, url: string, showResponse = true): Observable<any> {
        const headers = new HttpHeaders();
        const formData: FormData = new FormData();

        url = 'https://api.storysh.de' + url;
        formData.append('fileItem', fileItem, fileItem.name);
        const user = JSON.parse(localStorage.getItem('currentUser'));
        headers.append('Authorization', 'Bearer ' + user.token);
        headers.append('Content-Type', 'application/json; charset=utf-8');
        headers.append('Access-Control-Allow-Origin:', '*');
        const req = new HttpRequest('POST', url, formData, {
            reportProgress: true,
            headers: headers
        });

        return this._http.request(req);
    }

    assignMediaFileToAlbum(data: object): Observable<any> {
        const url = 'https://api.storysh.de' + '/be/api/media/assign/album';
        const json = encodeURIComponent(JSON.stringify(data));
        let params = `data=${json}`;
        params = params.replace(/&nbsp;/g, ' ');
        const headers = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'});
        const user = JSON.parse(localStorage.getItem('currentUser'));
        headers.append('Authorization', 'Bearer ' + user.token);
        return this._http.post(url, params, {headers: headers});
    }

    private handleError(error: HttpErrorResponse) {
        if (typeof this.snackBar !== 'undefined') {
            if (error.error instanceof ErrorEvent) {
                this.snackBar.open('error', 'error', error.error.message);
            } else {
                this.snackBar.open('error', 'error', error.status + ': ' + error.error);
            }
        }

        return throwError(
            'Something bad happened; please try again later.');
    }
}
