import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {v4 as uuid} from 'uuid';

@Component({
    selector: 'app-pdf-preview-component',
    templateUrl: './pdf.preview.component.html',
    styleUrls: ['../../../System/system.component.css']
})
export class PdfPreviewComponent implements OnInit {
  @Input() path: string;

  constructor(
      public translate: TranslateService
  ) {
      translate.addLangs(['en', 'de']);
      translate.setDefaultLang('de');

      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
  }

  ngOnInit() {
      this.path = this.path + this.getChecksum();
  }

  getChecksum() {
      return '?' + uuid();
  }

  handleEnterKeyPress(event) {
      const tagName = event.target.tagName.toLowerCase();
      if (tagName !== 'textarea') {
          return false;
      }
  }
}
