<div [formGroup]="form">
    <div class="row">
        <div class="col-md-12 column--padding">
            <h3 class="description--headline">{{ placeholder | translate }}</h3>
        </div>
        <div class="col-md-6 pln">
            <mat-form-field *ngIf="isSearchSelect === false">
                <mat-select placeholder="{{ 'PLEASE_SELECT' | translate }}"
                            formControlName="{{controlName}}"
                            (selectionChange)="setSelection($event)">
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let value of selections" [value]="value">
                        <span *ngIf="undefined === value.completeName">{{value.name}}</span>
                        <span *ngIf="undefined !== value.completeName">{{value.completeName}}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <app-search-select *ngIf="isSearchSelect === true"
                    [dataChangeService]="dataChangeService"
                    (afterSelect)="setSelection($event)"
                    [config]="config">
            </app-search-select>
        </div>
        <div class="col-md-6">
            <div class="row list--row">
                <div class="active--selection-item"
                     *ngFor="let value of getData()">
                    <div (click)="setSelection(value)">
                        <p *ngIf="undefined !== value.completeName">{{value.completeName}} <i class="material-icons">clear</i></p>
                        <p *ngIf="undefined !== value.label">{{value.label}} <i class="material-icons">clear</i></p>
                        <p *ngIf="undefined === value.completeName && undefined === value.label && undefined !== value.name">{{value.name}} <i class="material-icons">clear</i></p>
                        <p *ngIf="undefined === value.completeName && undefined === value.label && undefined === value.name">{{value[controlName].name}} <i class="material-icons">clear</i></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
