<div *ngIf="undefined !== getModel(); else loading">
    <h3 class="description--headline" *ngIf="undefined !== config.headline && config.headline !== ''">{{ config.headline | translate }}</h3>

    <table class="table table-hover mtxl" ng-table="tableParams">
        <thead class="table--head">
            <tr>
                <td *ngFor="let head of config.headers" class="table--head--cell">
                    {{ head | translate }}
                </td>
                <td class="table--head--cell" *ngIf="undefined !== config.actions && config.actions.length > 0">
                </td>
            </tr>
        </thead>
        <tr *ngFor="let row of getModel(); let rowIndex = index; trackBy: trackByFunction">
            <td class="table--row--cell" *ngFor="let col of config.rows[0].cols;">
                <app-field
                        *ngIf="showBaseField(col.type, col, row)"
                        [placeHolder]="col.placeholder"
                        (onChange)="onFieldValueChange($event, col.identifier, rowIndex)"
                        [model]="fieldValue(col, rowIndex)"
                        [controlName]="col.controlName + rowIndex"
                        [autoComplete]="col.autoComplete"
                        [value]="fieldValue(col, rowIndex)"
                        [selections]="col.selections"
                        [config]="col.config"
                        [type]="col.type"
                        [dataChangeService]="dataChangeService"
                        [fileArray]="col.fileArrayIdentifier"
                ></app-field>

                <span *ngIf="col.type === 'output' && undefined === col.subIdentifier">
                    {{getOutputValue(rowIndex, col.identifier)}}
                </span>

                <span *ngIf="col.type === 'output' && undefined !== col.subIdentifier">
                    {{getOutputValue(rowIndex, col.identifier, col.subIdentifier)}}
                </span>

                <div *ngIf="col.type === 'conditional'">
                    <div *ngFor="let subcol of col.cases">
                        <div *ngIf="subcol.conditionType === 'isDefined'">
                            <span *ngIf="
                                subcol.type === 'output' &&
                                undefined === subcol.subIdentifier &&
                                undefined !== row[subcol.identifier]">
                                {{getOutputValue(rowIndex, subcol.identifier)}}
                           </span>

                            <span *ngIf="
                                subcol.type === 'output' &&
                                undefined !== subcol.subIdentifier &&
                                undefined !== row[subcol.identifier]
                            ">
                                {{getOutputValue(rowIndex, subcol.identifier, subcol.subIdentifier)}}
                         </span>

                            <app-field
                                    *ngIf="(subcol.type === 'text' || subcol.type === 'number') && undefined !== row[subcol.identifier]"
                                    [placeHolder]="subcol.placeholder"
                                    [model]="row[subcol.identifier]"
                                    [controlName]="subcol.controlName + rowIndex"
                                    [autoComplete]="subcol.autoComplete"
                                    [value]="row[subcol.identifier]"
                                    [selections]="subcol.selections"
                                    [config]="subcol.config"
                                    [dataChangeService]="dataChangeService"
                                    (onChange)="onFieldValueChange($event, subcol.identifier, rowIndex)"
                                    [type]="subcol.type"
                                    [fileArray]="subcol.fileArrayIdentifier"
                            ></app-field>
                        </div>
                        <div *ngIf="subcol.conditionType === 'editAllowed'">
                            <span *ngIf="
                                subcol.type === 'output' &&
                                checkBool(subcol.allowedIdentifier, subcol.expected)">
                                {{getOutputValue(rowIndex, subcol.identifier)}}
                            </span>

                            <app-field
                                    *ngIf="(subcol.type === 'text' || subcol.type === 'number') && checkBool(subcol.allowedIdentifier, subcol.expected)"
                                    [placeHolder]="subcol.placeholder"
                                    [model]="row[subcol.identifier]"
                                    [controlName]="subcol.controlName + rowIndex"
                                    [autoComplete]="subcol.autoComplete"
                                    [value]="row[subcol.identifier]"
                                    [selections]="subcol.selections"
                                    [config]="subcol.config"
                                    [dataChangeService]="dataChangeService"
                                    (onChange)="onFieldValueChange($event, subcol.identifier, rowIndex)"
                                    [type]="subcol.type"
                                    [fileArray]="subcol.fileArrayIdentifier"
                            ></app-field>
                        </div>
                        <div *ngIf="subcol.conditionType === 'isUndefined'">
                            <span *ngIf="subcol.type === 'output' && undefined === subcol.subIdentifier && undefined === row[subcol.identifier]">
                                {{getOutputValue(rowIndex, subcol.identifier)}}
                            </span>

                            <span *ngIf="subcol.type === 'output' && undefined !== subcol.subIdentifier && undefined === row[subcol.identifier]">
                                {{getOutputValue(rowIndex, subcol.identifier, subcol.subIdentifier)}}
                            </span>

                            <app-field
                                    *ngIf="subcol.type === 'text' && undefined === row[subcol.identifier]"
                                    [placeHolder]="subcol.placeholder"
                                    [model]="row[subcol.identifier]"
                                    [controlName]="col.controlName + rowIndex"
                                    [autoComplete]="subcol.autoComplete"
                                    (onChange)="onFieldValueChange($event, subcol.identifier, rowIndex)"
                                    [value]="row[subcol.identifier]"
                                    [selections]="subcol.selections"
                                    [config]="subcol.config"
                                    [type]="subcol.type"
                                    [dataChangeService]="dataChangeService"
                                    [fileArray]="subcol.fileArrayIdentifier"
                            ></app-field>
                        </div>
                    </div>
                </div>
            </td>
            <td class="table--row--cell action--cell" *ngIf="undefined !== config.actions">
                <div class="list--action-row">
                    <div class='list--button text-center action--row-button' *ngFor="let action of config.actions">
                        <span *ngIf="action.type === 'modalOpen'">
                            <i (click)="openModal(action, row)" class="{{action.icon}}"
                               aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </td>
        </tr>
    </table>
    <div class="button--row">
        <span *ngFor="let button of config.buttonRow">
            <div *ngIf="button.type === 'add'" (click)="addRow()" class="mat-raised-button">
                <i class="{{button.iconClass}}"></i> {{ button.text | translate }}
            </div>
        </span>
    </div>
</div>

<ng-template #loading>
    <app-loader-component></app-loader-component>
</ng-template>
