<div [formGroup]="searchSelectForm">
    <div>
        <!-- https://medium.com/@nacimidjakirene/angular-search-autosuggest-with-observables-6f42987f80e6 -->
        <div class="filter-wrapper">
            <div class="keyword-wrapper">
                <mat-form-field>
                    <input matInput
                           autocomplete="off"
                           (focus)="toggleSearch()"
                           placeholder="{{ config.placeholder | translate }}"
                           [formControl]="searchField">
                </mat-form-field>
            </div>
            <ul class="filter-select" *ngIf="showFilterSearch == true">
                <li *ngFor="let date of data" class="filter-select-list" (click)="setResult(date)">
                    <p class="search--entry">
                        {{date.firstName}} {{date.lastName}} {{date.title}} {{date.subTitle}}
                    </p>
                </li>
            </ul>
        </div>
    </div>
    <app-description-text
        *ngIf="checkShowOutput()"
        [dataChangeService]="dataChangeService"
        [config]="config">
    </app-description-text>
</div>
