import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ControlContainer, FormControl, FormGroup, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

export class PasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
    selector: 'app-change-password',
    templateUrl: './change.password.component.html',
    styleUrls: ['../../../System/system.component.css']
})
export class ChangePasswordComponent implements OnInit {
    @Input() model: any;
    public form: FormGroup;
    hidePassword: boolean;

    matcher = new PasswordErrorStateMatcher();

    constructor(
        private controlContainer: ControlContainer,
        public translate: TranslateService
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.createForm();
    }

    ngOnInit(): void {
        this.hidePassword = true;
        this.model.oldPassword = '';
        this.model.newPassword = '';
        this.model.confirmPassword = '';
    }

  handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }

    createForm() {
        this.form = <FormGroup>this.controlContainer.control;
        this.form.addControl('oldPassword', new FormControl(['', Validators.required]));
        this.form.addControl('newPassword', new FormControl(['', Validators.required]));
        this.form.addControl('confirmPassword', new FormControl(['', Validators.required]));
        this.form.validator = this.checkPasswords;
    }

    checkPasswords(group: FormGroup) { // here we have the 'passwords' group
        const pass = group.controls.newPassword.value;
        const confirmPass = group.controls.confirmPassword.value;

        return pass === confirmPass ? null : {notSame: true};
    }
}
