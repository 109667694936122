import {ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {SystemService} from '../../Service/system.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DataChangeService} from '../../Service/data.change.service';
import {Observable, Subject} from 'rxjs';
import {v4 as uuid} from 'uuid';
import {BatchDeleteComponent} from '../../../BaseComponents/Table/_dialogs/batch.delete.component';
import {ManipulationService} from '../../Service/manipulation.service';

export interface ModalDialogData {
    activeRouteId: any;
    config: any;
    model: any;
    parentModel: any;
}

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['../../system.component.css']
})
export class ModalComponent implements OnInit {
    private baseFields = [
        'text',
        'number',
        'hourInput',
        'time',
        'select',
        'password',
        'checkbox',
        'rangeSlider',
        'editor',
        'code',
        'color',
        'datepicker'
    ];
    @Input() settings: any;
    config: any;
    models: any;
    singleModel: any[];
    showEdit: boolean;
    showForm: boolean;
    selections: any[];
    form: FormGroup;
    currentId: any;
    public dataChangeService: DataChangeService;
    formControls: FormControl[];
    changingValue: Subject<any> = new Subject();

    constructor(
        private fb: FormBuilder,
        private systemService: SystemService,
        private manipulationService: ManipulationService,
        public translate: TranslateService,
        private cd: ChangeDetectorRef,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ModalDialogData
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.createForm();
    }

    ngOnInit() {
        this.dataChangeService = new DataChangeService();
        this.form.reset();
        this.currentId = localStorage.getItem('currentId');
        this.selections = [];
        this.formControls = [];
        this.showForm = false;
        this.singleModel = this.data.model;

        this.systemService.getDetailUrl('/be/api/template/get', {
            'template': this.data.config.template,
            'mode': 'dialog'
        }).subscribe(conf => {
            this.config = conf;
            if (typeof this.config.showWithoutSetup !== 'undefined' && this.config.showWithoutSetup === true) {
                this.showForm = true;
            }

            // for example see projectToTemplate.json
            if (typeof this.config.useParentAsModel !== 'undefined' && this.config.useParentAsModel === true) {
                this.models = this.data.parentModel;

                if (typeof this.config.clearModelValues !== 'undefined') {
                    let clearCount = 0;

                    for (clearCount; clearCount <= this.config.clearModelValues.length - 1; clearCount++) {
                        const clearValue = this.config.clearModelValues[clearCount];

                        if (clearValue.type === 'standard') {
                            this.models[clearValue.key] = clearValue.value;
                        } else if (clearValue.type === 'delete') {
                            delete this.models[clearValue.key];
                        }
                    }
                }
            }

            if (typeof this.config.zone === 'undefined') {
                this.showEdit = true;
            } else {
                this.showEdit = JSON.parse(localStorage.getItem('user')).userGroup.settings['edit_' + this.config.zone].active;
            }

            if (
                this.showEdit &&
                (
                    this.config.type !== 'stepper' ||
                    (this.config.type === 'stepper' && this.config.preFill.length > 0)
                ) &&
                (
                    typeof this.config.showWithoutSetup === 'undefined' ||
                    this.config.showWithoutSetup === false
                ) &&
                (
                    typeof this.config.useParentAsModel === 'undefined' ||
                    this.config.useParentAsModel === false
                )
            ) {
                this.setExtraData().subscribe(extraDataResult => {
                    this.setPrefillData().subscribe(preFillDataResult => {
                        this.setFormControls().subscribe(result => {
                            this.setAdditionalSelections().subscribe(additionalResult => {
                                this.setFieldData().then(res => {
                                    if (typeof this.data.parentModel !== 'undefined' &&
                                        this.data.parentModel !== null &&
                                        typeof this.config.saveBeforeReturn !== 'undefined'
                                    ) {
                                        this.dataChangeService.changeValue(
                                            this.config.saveBeforeReturn.parentModelName,
                                            this.data.parentModel
                                        );
                                    }
                                    this.setDataChangeValues().subscribe(dataChangeResult => {
                                        if (this.config.selections.length > 0) {
                                            this.setSelections().then(selectionResult => {
                                                this.showForm = true;
                                                this.cd.detectChanges();
                                            });
                                        } else {
                                            this.showForm = true;
                                            this.cd.detectChanges();
                                        }
                                    });
                                });
                            });
                        });
                    });
                });
            } else {
                this.showForm = true;
                this.cd.detectChanges();
            }
        });
    }

    showBaseField(field: any): Boolean {
        return this.isBaseField(field.type) && this.checkUndefinedModel(field.undefinedModel);
    }

    isBaseField(type: string): Boolean {
        return (this.baseFields.indexOf(type) !== -1);
    }

    checkUndefinedModel(undefinedModel): Boolean {
        return (
            typeof undefinedModel === 'undefined' ||
            (
                typeof undefinedModel !== 'undefined' &&
                this.dataChangeService.getValue(undefinedModel, '') !== ''
            )
        );
    }

    onUploaded(config, event): void {
        const controlName = config.formControlName;
        let model = this.dataChangeService.getValue(controlName);

        if (typeof this.dataChangeService.getValue(controlName) !== 'undefined') {
            if (typeof config.multiple !== 'undefined' && config.multiple === true) {
                model = event;
            } else {
                if (typeof this.dataChangeService.getValue(controlName) !== 'string') {
                    model.push(event[0]);
                } else {
                    model = event[0].path;
                }
            }
            this.dataChangeService.changeValue(controlName, model);
            this.cd.detectChanges();
        }
    }

    deleteEntry(model: any, config: any): void {
        if (this.showEdit) {
            const dialogRef = this.dialog.open(BatchDeleteComponent, {
                width: '80rem',
                data: {
                    message: config.message,
                    data: model
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result !== false) {
                    this.systemService.putCall(result, config.url).subscribe(
                        res => {
                            if (typeof config.afterDelete !== 'undefined') {
                                switch (config.afterDelete.type) {
                                    case 'split': {
                                        break;
                                    }
                                    case 'refreshInComponent': {
                                        this.changingValue.next(true);
                                        break;
                                    }
                                    default: {
                                        break;
                                    }
                                }
                            }

                            this.refreshList();
                        }
                    );
                }
            });
        }
    }

    refreshList() {
        this.systemService.getData(this.config.getCall).subscribe(result => {
            this.data = result;
            this.cd.detectChanges();
        });
    }

    simpleAfterSelect(event, controlName, config): void {
        if (typeof config !== 'undefined' && typeof config.formControls !== 'undefined') {
            let controlCount = 0;
            const controlNameSuffix = this.data[controlName].length - 1;

            for (controlCount; controlCount <= config.formControls.length - 1; controlCount++) {
                if (config.formControls[controlCount].setDefaultValue === true) {
                    this.data[controlName][controlNameSuffix][config.formControls[controlCount].valueName] =
                        config.formControls[controlCount].value;
                }

                if (config.formControls[controlCount].type === 'standard') {
                    this.setObserver(
                        config.formControls[controlCount].name,
                        '',
                        {
                            'model': controlName,
                            'arrayPosition': controlNameSuffix,
                            'subModel': config.formControls[controlCount].valueName
                        }
                    );
                }
                if (config.formControls[controlCount].type === 'addIdentifier') {
                    this.setObserver(
                        config.formControls[controlCount].name,
                        controlNameSuffix,
                        {
                            'model': controlName,
                            'arrayPosition': controlNameSuffix,
                            'subModel': config.formControls[controlCount].valueName
                        }
                    );
                }
            }
        }
    }

    getSelections(field: any): any {
        if (field.type !== 'select' || (typeof field.config === 'undefined' || field.config.useExistingSelections === true)) {
            if (
                typeof field.subSelectionIdentifier === 'undefined' &&
                typeof this.selections[field.selectionIdentifier] !== 'undefined'
            ) {
                return this.selections[field.selectionIdentifier];
            } else {
                const data = this.dataChangeService.getValue(field.selectionIdentifier, []);
                if (
                    typeof field.subSelectionIdentifier === 'undefined'
                ) {
                    return data;
                } else {
                    return data[field.subSelectionIdentifier];
                }
            }
        }
    }

    fieldValue(field: any, fallback: any = ''): any {
        if (typeof field === 'undefined') {
            return fallback;
        }
        if (typeof field.subIdentifier === 'undefined') {
            return this.dataChangeService.getValue(field.identifier, fallback);
        } else {
            if (typeof field.subSubIdentifier === 'undefined') {
                const value = this.dataChangeService.values[field.identifier].getValue();
                return value[field.subIdentifier];
            } else {
                const value = this.dataChangeService.values[field.identifier].getValue();
                const subFieldValue = value.getValue();
                return subFieldValue[field.subSubIdentifier];
            }
        }
    }

    fileArray(field: any, fallback: any = ''): any {
        return this.dataChangeService.getValue(field.fileArrayIdentifier, fallback);
    }

    contactBase(field: any, fallback: any = ''): any {
        if (field.config.contactBaseModel.includes('|')) {
            const props = field.config.contactBaseModel.split('|'),
                parent = this.dataChangeService.getValue(props[0], fallback);
            return parent[props[1]];
        } else {
            return this.dataChangeService.getValue(field.config.contactBaseModel, fallback);
        }
    }

    setFormControls(): Observable<boolean> {
        return new Observable(observer => {
            if (this.config.formType === 'singleForm') {
                let i = 0;
                if (this.config.formControls.length === 0) {
                  observer.next(true);
                }
                for (i; i <= this.config.formControls.length - 1; i++) {
                    if (this.config.formControls[i]['type'] === 'standard') {
                        this.form.addControl(this.config.formControls[i].name, new FormControl(''));
                    } else if (this.config.formControls[i].type === 'date') {
                        if (this.singleModel[this.config.formControls[i].name] !== null) {
                            const date = this.singleModel[this.config.formControls[i].name].date;
                            this.form.addControl(this.config.formControls[i].name, new FormControl(new Date(date)));
                        } else {
                            this.form.addControl(this.config.formControls[i].name, new FormControl(''));
                        }
                    }  else if (this.config.formControls[i].type === 'subArray') {
                        this.form.addControl(this.config.formControls[i].name, new FormControl(''));
                    } else if (this.config.formControls[i].type === 'multidimensional') {
                        let j = 0;
                        if (this.config.mode === 'edit') {
                            this.getFieldByIdentifier(this.config.formControls[i].modelName).subscribe(fieldResult => {
                                let modelItemsCount = 0;

                                for (modelItemsCount;
                                     modelItemsCount <= this.singleModel[this.config.formControls[i].modelName].length - 1;
                                     modelItemsCount++
                                ) {
                                    const cols = [];
                                    let fieldFormControlCount = 0;

                                    for (fieldFormControlCount;
                                         fieldFormControlCount <= fieldResult.config.formControls.length - 1;
                                         fieldFormControlCount++
                                    ) {
                                        const subResult = {...fieldResult.config.rows[0].cols[fieldFormControlCount]};
                                        subResult.formControlName =
                                            fieldResult.config.formControls[fieldFormControlCount].name + modelItemsCount;
                                        cols.push(subResult);
                                        this.form.addControl(
                                            fieldResult.config.formControls[fieldFormControlCount].name + modelItemsCount, new FormControl('')
                                        );
                                    }
                                    fieldResult.config.rows.push({
                                        'cols': cols
                                    });
                                }
                            });
                        } else {
                            for (j; j <= this.config.formControls[i].values.length - 1; j++) {
                              this.form.addControl(this.config.formControls[i].values[j].name, new FormControl(''));
                            }
                        }
                    } else if (this.config.formControls[i].type === 'loop') {
                      let k = 0;

                      for (k; k <= this.singleModel[this.config.formControls[i].modelName].length - 1; k++) {
                        let j = 0;
                        for (j; j <= this.config.formControls[i].values.length - 1; j++) {
                            this.form.addControl(this.config.formControls[i].values[j].name + '-' + k, new FormControl(''));
                        }
                      }
                    }

                    if (i === this.config.formControls.length - 1) {
                        observer.next(true);
                    }
                }
            }
        });
    }

    setDataChangeValues(): Observable<boolean> {
        return new Observable(observer => {
            if (this.config.formType === 'singleForm') {
                let i = 0;
                if (this.config.formControls.length === 0) {
                  observer.next(true);
                }
                let data = (this.config.mode === 'add') ? this.config.baseModel : this.models;

                if (typeof data === 'undefined') {
                    if (this.data.model) {
                        data = this.data.model;
                    }
                }
                for (i; i <= this.config.formControls.length - 1; i++) {
                    const controlName = this.config.formControls[i].name;
                    if (this.config.formControls[i]['type'] === 'standard' || this.config.formControls[i]['type'] === 'date') {
                        this.dataChangeService.setValue(
                            controlName,
                            data[controlName]
                        );

                        this.dataChangeService.values[controlName].subscribe(result => {
                            data[controlName] = result;
                        });
                    } else if (this.config.formControls[i].type === 'subArray') {
                        const changeName = this.config.formControls[i].name,
                        arrayIdentifier = this.config.formControls[i].arrayIdentifier;

                        this.dataChangeService.setValue(
                            changeName,
                            data[arrayIdentifier][changeName]
                        );

                        this.dataChangeService.values[changeName].subscribe(result => {
                            data[arrayIdentifier][changeName] = result;
                        });
                    } else if (this.config.formControls[i].type === 'loop') {
                        const changeName = this.config.formControls[i].modelName;

                        this.dataChangeService.setValue(
                            changeName,
                            data[changeName]
                        );
                    }

                    if (i === this.config.formControls.length - 1) {
                        observer.next(true);
                    }
                }
            }
        });
    }

    openLink(config: any): void {
        window.open(this.dataChangeService.getValue(config['urlIdentifier'], ''));
    }

    pushToModel(config: any): void {
        if (this.showEdit) {
            const data = this.dataChangeService.getValue(config.modelName, []);
            const id = data.length,
                newModel = {
                  'id': uuid()
                };
            let controlCount = 0,
              dataChangeCount = 0;

            for (controlCount; controlCount <= config.formControls.length - 1; controlCount++) {
                this.form.addControl(config.formControls[controlCount].name + '-' + id, new FormControl(''));
                newModel[config.formControls[controlCount].name] = '';

                if (controlCount === config.formControls.length - 1) {
                    data.push(newModel);

                    for (dataChangeCount; dataChangeCount <= config.formControls.length - 1; dataChangeCount++) {
                        const controlName = config.formControls[dataChangeCount].name;
                        this.dataChangeService.setValue(
                            config.formControls[dataChangeCount].name + '-' + id,
                            data[data.length - 1][controlName]
                        );
                        this.dataChangeService.values[config.formControls[dataChangeCount].name + '-' + id].subscribe(subscriptionResult => {
                            data[data.length - 1][controlName] = subscriptionResult;
                        });

                        if (dataChangeCount === config.formControls.length - 1) {
                            this.dataChangeService.changeValue(config.modelName, data);
                            this.cd.detectChanges();
                        }
                    }
                }
            }
        }
    }

    getFieldByIdentifier(identifier: string): Observable<any> {
        return new Observable(observer => {
            let rowCount = 0;

            for (rowCount; rowCount <= this.config.rows.length - 1; rowCount++) {
                const row = this.config.rows[rowCount];
                let colCount = 0;

                for (colCount; colCount <= row.cols.length - 1; colCount++) {
                    const col = row.cols[colCount];
                    let fieldCount = 0;

                    for (fieldCount; fieldCount <= col.fields.length - 1; fieldCount++) {
                        if (col.fields[fieldCount].identifier === identifier) {
                            observer.next(col.fields[fieldCount]);
                        }
                    }
                }
            }
        });
    }

    async setSelections(): Promise<any> {
        return Promise.resolve((() => {
            this.config.selections.forEach(async (setting, index) => {
                if (typeof setting.getCallParameters === 'undefined') {
                    this.systemService.getWithUrl(setting.getCall).subscribe(result => {
                        this.selections[setting.modelName] = result;
                        if (index === this.config.selections.length - 1) {
                            this.showForm = true;
                            this.cd.detectChanges();
                        }
                    });
                } else {
                    let parameterCount = 0,
                        urlParams = '';

                    for (parameterCount; parameterCount <= setting.getCallParameters.length - 1; parameterCount++) {
                        if (setting.getCallParameters[parameterCount].identifier === 'id') {
                            urlParams += +this.data.parentModel['id'];
                        }

                        if (parameterCount === setting.getCallParameters.length - 1) {
                            this.systemService.getWithUrl(setting.getCall + urlParams).subscribe(result => {
                                this.selections[setting.modelName] = result;
                                this.cd.detectChanges();
                            });
                        }
                    }
                }
            });

            return true;
        })());
    }

    async setFieldData(): Promise<any> {
        return Promise.resolve((() => {
            let rowCount = 0;

            if (this.config.rows.length === 0) {
                return true;
            }

            for (rowCount; rowCount <= this.config.rows.length - 1; rowCount++) {
                let colCount = 0;

                for (colCount; colCount <= this.config.rows[rowCount].cols.length - 1; colCount++) {
                    let fieldCount = 0;

                    for (fieldCount; fieldCount <= this.config.rows[rowCount].cols[colCount].fields.length - 1; fieldCount++) {
                        if (this.config.rows[rowCount].cols[colCount].fields[fieldCount].type === 'paginatedTable') {
                            if (
                                typeof this.data.config.setPreselectTableFilter !== 'undefined' &&
                                this.data.config.setPreselectTableFilter === true
                            ) {
                                if (
                                    typeof this.config.rows[rowCount].cols[colCount].fields[fieldCount].preselectedFilter !== 'undefined'
                                ) {
                                    this.config.rows[rowCount].cols[colCount].fields[fieldCount].preselectedFilter.filterData.parameters[0].value =
                                        this.data.config.preSelectFilter;
                                    this.config.rows[rowCount].cols[colCount].fields[fieldCount].filters.push(
                                        this.config.rows[rowCount].cols[colCount].fields[fieldCount].preselectedFilter
                                    );
                                }
                            }
                        }

                        if (
                            colCount === this.config.rows.length - 1
                            && fieldCount === this.config.rows[rowCount].cols[colCount].fields.length - 1
                        ) {
                            return true;
                        }
                    }
                }
            }
        })());
    }

    save(): void {
        if (typeof this.config.saveBeforeReturn !== 'undefined') {
            if (typeof this.config.saveBeforeReturn.overrideComplete !== 'undefined' &&
                this.config.saveBeforeReturn.overrideComplete === true
            ) {
                this.models = this.data.model;
            } else if (typeof this.config.saveBeforeReturn.modelName !== 'undefined') {
                this.dataChangeService.setValue(
                    this.config.saveBeforeReturn.modelName,
                    this.data.model
                );
            } else if (typeof this.config.saveBeforeReturn.parentModelName !== 'undefined') {
                this.dataChangeService.setValue(
                    this.config.saveBeforeReturn.parentModelName,
                    this.data.parentModel
                );
            }

            this.manipulationService.getDataFromChangeService(this.dataChangeService).subscribe(data => {
                if (typeof this.data.model !== 'undefined' && typeof this.data.model.id !== 'undefined' && this.config.mode !== 'add') {
                    data.id = this.data.model.id;
                }
                this.systemService.putCall(data, this.config.saveBeforeReturn.saveCall).subscribe(
                    response => {
                        if (typeof this.config.processEvent !== 'undefined') {
                            this.systemService.putCall(
                                {
                                    'model': data,
                                    'sector': this.config.zone,
                                    'event': this.config.processEvent
                                },
                                '/be/api/process/run'
                            ).subscribe(processResult => {});
                        }

                        if (
                            typeof this.config.saveBeforeReturn.returnResponse === 'undefined' ||
                            this.config.saveBeforeReturn.returnResponse === false
                        ) {
                            this.onNoClick(data);
                        } else {
                            if (this.config.saveBeforeReturn.returnResponse === true) {
                                this.onNoClick(response);
                            } else {
                                this.onNoClick();
                            }
                        }
                    }
                );
            });
        } else {
            this.manipulationService.getDataFromChangeService(this.dataChangeService).subscribe(data => {
                this.onNoClick(data);
            });
        }
    }

    openModal(config: any, data: any = null): void {
        if (this.showEdit) {
            let model = this.data;
            let parent = null;
            if (typeof config.useParentModel !== 'undefined') {
                parent = this.data;
            }

            if (data !== null) {
                model = data;
            }

            this.checkModalConfig(config).then(result => {
                const dialogRef = this.dialog.open(ModalComponent, {
                    width: '80rem',
                    data: {
                        config: result,
                        model: model,
                        parentModel: parent
                    }
                });

                dialogRef.afterClosed().subscribe(res => {
                    if (typeof res !== 'undefined') {
                        if (typeof config.refreshAfterClose !== 'undefined' && config.refreshAfterClose === true) {
                            if (config.refreshAfterCloseType === 'selections') {
                                this.setAdditionalSelections().subscribe(valuesSet => {
                                    this.cd.detectChanges();
                                });
                            }
                        }
                    }
                });
            });
        }
    }

    async checkModalConfig(config: any): Promise<any> {
        return Promise.resolve((() => {
            if (config.multiModel === false) {
                if (typeof config.setPreselectTableFilter !== 'undefined' && config.setPreselectTableFilter === true) {
                    config.preSelectFilter = this.data[config.key][config.identifier];
                }
            }

            return config;
        })());
    }

    setAdditionalSelections(): Observable<boolean> {
        return new Observable(observer => {
            if (typeof this.config.additionalSelections === 'undefined' || this.config.additionalSelections.length === 0) {
                observer.next(true);
            } else {
                this.config.additionalSelections.forEach(async (call, index) => {
                    let parameterCount = 0,
                        urlParams = '';

                    for (parameterCount; parameterCount <= call.getUrlParameters.length - 1; parameterCount++) {
                        if (call.getUrlParameters[parameterCount].identifier === 'id') {
                            urlParams += +this.data.model['id'];
                        }
                        if (call.getUrlParameters[parameterCount].identifier === 'fromSubModel') {
                            urlParams += +this.data.model[call.getUrlParameters[parameterCount]['subModel']][call.getUrlParameters[parameterCount]['subModelIdentifier']];
                        }
                        if (call.getUrlParameters[parameterCount].identifier === false) {
                            urlParams += '/' + this.config.getUrlParameters[parameterCount].value;
                        }

                        if (parameterCount === call.getUrlParameters.length - 1) {
                            this.systemService.getWithUrl(call.getCall + urlParams).subscribe(result => {
                                this.selections[call.modelName] = result;
                                if (index === this.config.additionalSelections.length - 1) {
                                    observer.next(true);
                                }
                            });
                        }
                    }
                });
            }
        });
    }

    setExtraData(): Observable<boolean> {
        return new Observable(observer => {
            if (typeof this.config.additionalGetCalls === 'undefined' || this.config.additionalGetCalls.length === 0) {
                observer.next(true);
            } else {
                this.config.additionalGetCalls.forEach(async (call, index) => {
                    let parameterCount = 0,
                        urlParams = '';

                    for (parameterCount; parameterCount <= call.getUrlParameters.length - 1; parameterCount++) {
                        if (call.getUrlParameters[parameterCount].identifier === 'id') {
                            urlParams += +this.data.model['id'];
                        }
                        if (call.getUrlParameters[parameterCount].identifier === false) {
                            urlParams += '/' + this.config.getUrlParameters[parameterCount].value;
                        }

                        if (parameterCount === call.getUrlParameters.length - 1) {
                            this.systemService.getWithUrl(call.getCall + urlParams).subscribe(result => {
                                if (typeof call.modelName !== 'undefined') {
                                    this.data.model[call.modelName] = result;
                                } else if (typeof call.mapModel !== 'undefined') {
                                    let mapModelCount = 0;

                                    for (mapModelCount; mapModelCount <= call.mapModel.length - 1; mapModelCount++) {
                                        const mapModel = call.mapModel[mapModelCount];

                                        this.data.model[mapModel.to] = result[mapModel.from];
                                    }
                                }

                                if (index === this.config.additionalGetCalls.length - 1) {
                                    observer.next(true);
                                }
                            });
                        }
                    }
                });
            }
        });
    }

    setPrefillData(): Observable<boolean> {
        return new Observable(observer => {
            if (typeof this.config.preFill === 'undefined' || this.config.preFill.length === 0) {
                observer.next(true);
            } else {
                let i = 0;

                for (i; i <= this.config.preFill.length - 1; i++) {
                    if (this.config.preFill[i].type === 'standard') {
                        this.data.model[this.config.preFill[i].name] = this.config.preFill[i].value;
                    } else if (this.config.preFill[i].type === 'multidimensional') {
                        let j = 0;
                        const prefill = {};

                        for (j; j <= this.config.preFill[i].values.length - 1; j++) {
                            prefill[this.config.preFill[i].values[j].name] = this.config.preFill[i].values[j].value;

                            if (j === this.config.preFill[i].values.length - 1) {
                                this.data.model[this.config.preFill[i].name] = [prefill];
                            }
                        }
                    } else if (this.config.preFill[i].type === 'fillFromSelection') {
                        let j = 0;
                        const config = this.config.preFill[i].config,
                            selections = this.selections[config.selectionName];

                        for (j; j <= selections.length - 1; j++) {
                            let k = 0;
                            const selection = selections[j];
                            let object = {};

                            this.data.model[config.setModelName][j] = [];

                            for (k; k <= config.fields.length - 1; k++) {
                                const field = config.fields[k];

                                if (field.name === 'parentIdentifier') {
                                    object['identifier'] = selection[config.selectionIdentifier];
                                } else {
                                    object[field.name] = field.value;
                                }

                                if (k === config.fields.length - 1) {
                                    this.data.model[config.setModelName][j].push(object);
                                    object = {};
                                }
                            }
                        }
                    }

                    if (i === this.config.preFill.length - 1) {
                        observer.next(true);
                    }
                }
            }
        });
    }

    clearFormArray(): Observable<boolean> {
        return new Observable(observer => {
            if (this.models === null || typeof this.models === 'undefined') {
                observer.next(true);
            } else {
                this.manipulationService.resetModel(this.dataChangeService, this.models).subscribe(res => {
                    observer.next(true);
                });
            }
        });
    }

    variantSelection(event: any, config: any): void {
        this.dataChangeService.changeValue(config.modelName, event);
    }

    paginatedTableSelectionClick(selectionEvent): void {
        const model = this.dataChangeService.getValue('model', []);

        if (model.length === 0) {
            model.push(selectionEvent);
        } else {
            let i = 0,
                inArray = false;
            for (i; i <= this.models.length - 1; i++) {
                if (selectionEvent[this.config.selectionClickIdentifier] === this.models[i][this.config.selectionClickIdentifier]) {
                    inArray = true;
                    model.splice(i, 1);
                }
            }

            if (inArray === false) {
                model.push(selectionEvent);
            }
        }

        this.dataChangeService.setValue('model', model);
    }

    onValueChange(event, config): void {
        if (typeof config !== 'undefined' && typeof config.afterSelectConfig !== 'undefined') {
            let actionCount = 0;

            for (actionCount; actionCount <= config.afterSelectConfig.actions.length - 1; actionCount++) {
                const action = config.afterSelectConfig.actions[actionCount];

                if (action.type === 'setValues') {
                    let valueCount = 0;

                    for (valueCount; valueCount <= action.values.length - 1; valueCount++) {
                        const value = action.values[valueCount];

                        if (typeof value['subSelectionIdentifier'] !== 'undefined') {
                            this.data[value.identifier] = event[value['selectionIdentifier']][value['subSelectionIdentifier']];
                        } else {
                            this.data[value.identifier] = event[value['selectionIdentifier']];
                        }
                    }
                } else if (action.type === 'setSelections') {
                    let getCall = action.config.getCall;

                    if (typeof action.config.additionalGetParameters !== 'undefined' && action.config.additionalGetParameters.length > 0) {
                        let parameterCount = 0;

                        for (parameterCount; parameterCount <= action.config.additionalGetParameters.length - 1; parameterCount++) {
                            const parameter = action.config.additionalGetParameters[parameterCount];
                            if (parameter.type === 'standard') {
                                getCall += '/' + event[parameter.parameterIdentifier];
                            }

                            if (parameterCount === action.config.additionalGetParameters.length - 1) {
                                this.systemService.getData(getCall).subscribe(result => {
                                    this.selections[action.config.selectionIdentifier] = result;
                                    this.cd.detectChanges();
                                });
                            }
                        }
                    } else {
                        this.systemService.getData(getCall).subscribe(result => {
                            this.selections[action.config.selectionIdentifier] = result;
                            this.cd.detectChanges();
                        });
                    }
                }
            }
        }
    }

    onBlur(event, model, config = null): void {
    }

    onCompleteSelection(model: any, config: any, event: any): void {
    }

    addRow(model, config): void {
        if (this.showEdit) {
            let i = 0,
              j = 0;
            const id = model.length,
              cols = [];

            if (model[id - 1]['to'] === 99999) {
                model[id - 1]['to'] = +model[id - 1]['from'] + 1;
            }

            for (i; i <= config.formControls.length - 1; i++) {
                const subResult = {...config.rows[0].cols[i]};
                subResult.formControlName = config.formControls[i].name + id;
                cols.push(subResult);
                this.form.addControl(config.formControls[i].name + id, new FormControl(''));
            }
            config.rows.push({
                'cols': cols
            });

            const newRow = {
                'id': id,
                'from': +model[id - 1]['to'] + 1,
                'to': 99999,
                'costs': parseFloat(model[id - 1]['costs']).toFixed(2)
            };

            model.push(newRow);

            for (j; j <= config.formControls.length - 1; j++) {
                const key = config.formControls[j].name + id,
                  modelName = config.formControls[j].modelName;
                this.dataChangeService.setValue(key, model[id][modelName]);
                this.dataChangeService.values[key].subscribe(result => {
                    model[id][modelName] = result;
                });
                if (j === config.formControls.length - 1) {
                    this.cd.detectChanges();
                }
            }
        }
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }

    setObserver(controlName, controlAddition, model, existingModel = null): void {
        this.form.addControl(controlName + controlAddition, new FormControl(''));
        if (typeof existingModel === null) {
            this.dataChangeService.setValue(controlName + controlAddition, this.data[model.model][model.arrayPosition][model.subModel]);
            this.dataChangeService.values[controlName + controlAddition].subscribe(subscriptionResult => {
                this.data[model.model][model.arrayPosition][model.subModel] = subscriptionResult;
            });
        } else {
            this.dataChangeService.setValue(controlName + controlAddition, existingModel);
            this.dataChangeService.values[controlName + controlAddition].subscribe(subscriptionResult => {
                existingModel = subscriptionResult;
            });
        }
    }

    closeDialog(): void {
        this.onNoClick();
    }

    deleteWithUrl(url: string): void {
        this.manipulationService.getDataFromChangeService(this.dataChangeService).subscribe(data => {
            this.systemService.putCall(data, url).subscribe(result => {
                this.closeDialog();
            });
        });
    }

    onNoClick(data: any = null): void {
        let backupData = data;

        if (data !== null) {
            backupData = {...data};
        }

        this.clearFormArray().subscribe(res => {
            this.form.reset();
            this.dialogRef.close(backupData);
        });
    }

    createForm() {
        this.form = this.fb.group({});
    }
}
