<div class="alert warning">
    <strong>{{ 'CAUTION' | translate }}</strong>
</div>

<p>
    {{ message | translate }}
</p>

<div class="button-row">
    <div (click)="closeDialog()" class="mat-raised-button">
        <i class="fas fa-arrow-left"></i> {{ 'BACK' | translate }}
    </div>
    <button type="submit" (click)="confirmClicked()" class="mat-raised-button delete--button">
        <i class="fas fa-save"></i> {{ 'DELETE' | translate }}
    </button>
</div>
