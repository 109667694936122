<section *ngIf="undefined !== comments">
    <div class="button-row">
        <div (click)="addClicked()" class="mat-raised-button">
            <i class="fas fa-comment"></i> {{ 'NEW_COMMENT' | translate }}
        </div>
    </div>

    <ng-template #recursiveList let-comments>

        <div *ngFor="let comment of comments">
            <mat-card
                    class="comment-card"
                    id="{{comment.id}}"
                    [ngClass]="{'active--comment': comment.id === activeComment}">

                <mat-card-header>
                    <mat-card-title class="description--headline">
                        <i *ngIf="comment.isImportant === true" class="fas fa-star important--comment"></i>

                        {{comment.author.name}} - ({{comment.author.email}})

                        <div class="on--hover display-inline">
                            <div (click)="addClicked(comment)" class="mat-raised-button">
                                <i class="fas fa-comment"></i> {{ 'REPLY' | translate }}
                            </div>
                            <div *ngIf="comment.author.id === currentUser.id" (click)="editClicked(comment)" class="mat-raised-button mat-primary">
                                <i class="fas fa-pencil-alt"></i> {{ 'EDIT' | translate }}
                            </div>
                            <div (click)="deleteClicked(comment)" class="mat-raised-button delete--button">
                                <i class="far fa-trash-alt"></i> {{ 'DELETE' | translate }}
                            </div>
                        </div>
                    </mat-card-title>
                    <mat-card-subtitle>{{comment.insertDate}}</mat-card-subtitle>
                </mat-card-header>

                <mat-card-content>
                    <div *ngIf="comment.parent">
                        <a pageScroll
                           (click)="afterScroll(comment.parent)"
                           pageScrollTarget="#{{comment.parent}}">
                            {{ 'COMMENT_PARENT_LINK_TEXT' | translate }}
                        </a>
                    </div>

                    <div *ngIf="comment.comment"
                         innerHTML="{{comment.comment}}">
                    </div>

                    <div *ngIf="comment.message"
                         innerHTML="{{comment.message}}">
                    </div>

                    <div class="row" *ngIf="undefined !== comment.attachments">
                        <span class="mat-raised-button file--button" *ngFor="let attachment of comment.attachments;"
                              (click)="openFileModal(attachment)">
                            <i class="fas fa-file"></i> {{attachment.media.file}}
                        </span>
                    </div>
                </mat-card-content>
            </mat-card>

            <ul *ngIf="undefined !== comment.children">
                <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: comment.children }"></ng-container>
            </ul>
        </div>
    </ng-template>

    <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: comments }"></ng-container>

</section>
