<div [formGroup]="form">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let zone of model.zones">
      <mat-expansion-panel-header>
        {{zone.name}}
      </mat-expansion-panel-header>

      <mat-list role="list">
        <mat-checkbox
          formControlName="selectAllItems-{{model.id}}-{{zone.name}}"
          (change)="switchUserGroupZone(zone)">
          {{ 'ALL' | translate }}
        </mat-checkbox>

        <mat-list-item role="listitem"
                       *ngFor="let setting of zone.setting">
          <div class="row list--row">
            <mat-checkbox
              formControlName="setting-{{setting.setting.name}}-active"
              [(ngModel)]="setting.active">
              {{setting.setting.name | translate}}
            </mat-checkbox>
          </div>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>
</div>
