<div class="button-row" *ngIf="undefined === config.showAdd || config.showAdd == true && showEdit == true">
    <div *ngIf="undefined === config.routeToAdd || config.routeToAdd == true">
        <div class="mat-raised-button" routerLink="{{config.addLink}}">
            <i class="fas fa-save"></i> {{ 'ADD' | translate }}
        </div>
    </div>
    <div *ngIf="config.routeToAdd == false">
        <div (click)="addClicked()" class="mat-raised-button">
            <i class="fas fa-save"></i> {{ 'ADD' | translate }}
        </div>
    </div>
</div>
<section class="table--section">
    <table class="table table-hover mtxl" ng-table="tableParams">
        <thead class="table--head">
            <tr>
                <td class="table--head--cell" *ngIf="config.showLogo === true">
                </td>
                <td class="table--head--cell" *ngFor="let header of headers; trackBy: trackByFunction">
                    {{header | translate}}
                </td>
                <td class="table--head--cell">
                </td>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let date of getData(); trackBy: trackByFunction" class="table--row"
                [class.is--active]="undefined !== config.activeCompare && config.activeCompare.indexOf(date.id) > -1">
                <td class="table--row--cell image--cell" *ngIf="config.showLogo === true">
                    <img class="list--item-image" src='{{date.logo}}'>
                </td>
                <td class="table--row--cell">
                    <strong *ngIf="undefined === config.translateName">{{date.name}}</strong>
                    <strong *ngIf="undefined !== config.translateName && config.translateName === true">{{date.name | translate}}</strong>
                </td>
                <td class="table--row--cell" *ngFor="let attribute of attributes; trackBy: trackByFunction">
                    <span *ngIf="attribute === 'description' && undefined !== date[attribute]"
                          innerHTML="{{ (date[attribute].length > 25) ? (date[attribute] | slice:0:25) + '..' : (date[attribute]) }}">
                    </span>

                    <span *ngIf="attribute !== 'description' && isString(date[attribute])">
                        {{date[attribute] | translate}}
                    </span>

                    <span *ngIf="attribute !== 'description' && !isString(date[attribute])">
                        {{date[attribute]}}
                    </span>
                </td>
                <td class="table--row--cell action--cell">
                    <div class="list--action-row">
                        <div class='list--button text-center action--row-button'
                             *ngIf="undefined === config.showEdit || config.showEdit === true">
                            <div *ngIf="undefined === config.routeToEdit || config.routeToEdit == true">
                                <a routerLink="{{config.editLink}}{{date.id}}">
                                    <i class="fas fa-pen-square" aria-hidden="true"></i>
                                </a>
                            </div>
                            <div *ngIf="config.routeToEdit == false">
                                <i *ngIf="undefined === config.routeAfterEdit" (click)="editClicked(date)" class="fas fa-pen-square" aria-hidden="true"></i>
                                <i *ngIf="undefined !== config.routeAfterEdit" (click)="editRouteClicked(date, config.editLink + date.id)" class="fas fa-pen-square" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class='list--button text-center action--row-button'
                             *ngIf="config.showComment">
                            <a routerLink="{{config.commentLink}}{{date.id}}">
                                <i class="fas fa-comment-dots" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div class='list--button text-center action--row-button'
                             *ngIf="config.showDelete && showEdit == true">
                            <div *ngIf="undefined === config.routeToDelete || config.routeToDelete == true">
                                <a routerLink="{{config.deleteLink}}{{date.id}}">
                                    <i class="fas fa-minus" aria-hidden="true"></i>
                                </a>
                            </div>
                            <div *ngIf="config.routeToDelete == false">
                                <i (click)="deleteClicked(date)" class="fas fa-minus" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</section>

<ng-template #loading>
    <app-loader-component></app-loader-component>
</ng-template>
