import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter, Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Location} from '@angular/common';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SystemService} from '../../../System/Service/system.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface CommentAddComponentData {
  parent: any;
  config: any;
  companies: any;
  model: any;
}

@Component({
    selector: 'app-add-comment',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './comment.add.component.html',
    styleUrls: ['../../../System/system.component.css']
})
export class CommentAddComponent implements OnInit {
    @Input() uploadConfig: any;
    @Output() addClick = new EventEmitter<any>();
    @Output() noClick = new EventEmitter<boolean>();
    newCommentForm: FormGroup;
    companies: any;
    locationString: string;
    newComment: any;
    attachments: any;

    constructor(
        private fb: FormBuilder,
        private cd: ChangeDetectorRef,
        public translate: TranslateService,
        public systemService: SystemService,
        public dialogRef: MatDialogRef<CommentAddComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CommentAddComponentData,
        private location: Location
    ) {
        translate.addLangs(['en', 'de']);
        translate.setDefaultLang('de');

        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|de/) ? browserLang : 'de');
        this.locationString = this.location.path();

        this.newComment = {
            message: '',
            company: '',
            isImportant: false
        };
        this.companies = this.data.companies;

        if (this.companies.length === 1) {
            this.newComment.company = this.companies[0];
        }

        this.createForm();
    }

    ngOnInit() {
        this.attachments = [];

        this.newCommentForm.valueChanges.subscribe(result => this.newComment = result);

        if (typeof this.uploadConfig === 'undefined') {
            this.uploadConfig = this.data.config.uploadConfig;
        }

        this.uploadConfig.baseModel = this.data.model;
    }

    addClicked() {
        this.newComment.attachments = this.attachments;
        this.newComment.model = {
            'id': this.data.model.id,
            'name': this.data.model.name
        };

        this.newComment.parent = this.data.parent;

        if (typeof this.data.parent !== 'undefined' && this.data.parent !== null) {
            this.newComment.parent = this.data.parent.id;
        }

        this.systemService.putCall(this.newComment, this.data.config.addCall).subscribe(added => {
          this.noClicked();
        });
    }

    compareObjects(o1: any, o2: any): boolean {
        return o1.name === o2.name && o1.id === o2.id;
    }

    onFinishUpload(uploadedFiles): void {
        this.attachments = uploadedFiles;
    }

    noClicked() {
        this.noClick.emit(true);
        this.dialogRef.close();
    }

    createForm() {
        if (typeof this.companies === 'undefined') {
            this.newCommentForm = this.fb.group({
                message: ['', Validators.required],
                isImportant: false
            });
        } else {
            if (this.companies.length < 2) {
                this.newComment.company = this.companies[0];
                this.newCommentForm = this.fb.group({
                    message: ['', Validators.required],
                    company: [this.companies[0], Validators.required],
                    isImportant: false
                });
            } else {
                this.newCommentForm = this.fb.group({
                    message: ['', Validators.required],
                    company: ['', Validators.required],
                    isImportant: false
                });
            }
        }
    }

    handleEnterKeyPress(event) {
        const tagName = event.target.tagName.toLowerCase();
        if (tagName !== 'textarea') {
            return false;
        }
    }
}
